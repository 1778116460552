import Constants from './constants';

export const fetchWorkouts = (pageNo: any, limit: any, filterType: string, searchValue: string, workoutStageId: string) => ({
  filterType,
  limit,
  pageNo,
  searchValue,
  type: Constants.FETCH_WORKOUTS,
  workoutStageId
});

export const fetchWorkoutsSuccess = (response: any) => ({
  response,
  type: Constants.FETCH_WORKOUTS_SUCCESS
});

export const fetchWorkoutsFail = (error: any) => ({
  error,
  type: Constants.FETCH_WORKOUTS_FAIL
});

export const searchWorkouts = (pageNo: any, limit: any, searchValue: string) => ({
  limit,
  pageNo,
  searchValue,
  type: Constants.SEARCH_WORKOUTS
});

export const searchWorkoutsSuccess = (response: any) => ({
  response,
  type: Constants.SEARCH_WORKOUTS_SUCCESS
});

export const searchWorkoutsFail = (error: any) => ({
  error,
  type: Constants.SEARCH_WORKOUTS_FAIL
});

export const createWorkout = (name: string, sequenceNo: any, sequenceOrder: string, jsonContent: object, workoutStageId: string, status: number, workoutFor: string) => ({
  jsonContent,
  name,
  sequenceNo,
  sequenceOrder,
  status,
  type: Constants.CREATE_WORKOUT,
  workoutStageId,
  workoutFor
});

export const createWorkoutSuccess = (response: any) => ({
  response,
  type: Constants.CREATE_WORKOUT_SUCCESS
});

export const createWorkoutFail = (error: any) => ({
  error,
  type: Constants.CREATE_WORKOUT_FAIL
});

export const deleteWorkout = (id: string, workouts: [], workoutStages: [], totalCount: any) => ({
  id,
  totalCount,
  type: Constants.DELETE_WORKOUT,
  workoutStages,
  workouts
});

export const deleteWorkoutSuccess = (response: any) => ({
  response,
  type: Constants.DELETE_WORKOUT_SUCCESS
});

export const deleteWorkoutFail = (error: any) => ({
  error,
  type: Constants.DELETE_WORKOUT_FAIL
});

export const editWorkout = (id: string, name: string, sequenceNo: any, sequenceOrder: string, jsonContent: object, workoutStageId: string, status: number, workoutFor: string) => ({
  id,
  jsonContent,
  name,
  sequenceNo,
  sequenceOrder,
  status,
  type: Constants.EDIT_WORKOUT,
  workoutStageId,
  workoutFor
});

export const editWorkoutSuccess = (response: any) => ({
  response,
  type: Constants.EDIT_WORKOUT_SUCCESS
});

export const editWorkoutFail = (error: any) => ({
  error,
  type: Constants.EDIT_WORKOUT_FAIL
});

export const getWorkout = (id: string) => ({
  id,
  type: Constants.GET_WORKOUT
});

export const getWorkoutSuccess = (response: any) => ({
  response,
  type: Constants.GET_WORKOUT_SUCCESS
});

export const getWorkoutFail = (error: any) => ({
  error,
  type: Constants.GET_WORKOUT_FAIL
});

export const clearWorkout = () => ({
  type: Constants.CLEAR_WORKOUT
});

export const updateWorkoutStatus = (id: string, workouts: any, status: string) => ({
  id,
  status,
  type: Constants.UPDATE_WORKOUT,
  workouts
});

export const updateWorkoutStatusSuccess = (response: any) => ({
  response,
  type: Constants.UPDATE_WORKOUT_SUCCESS
});

export const updateWorkoutStatusFail = (error: any) => ({
  error,
  type: Constants.UPDATE_WORKOUT_FAIL
});

export const bulkWorkoutsDelete = (data: any) => ({
  data,
  type: Constants.BULK_WORKOUTS_DELETE
});

export const bulkWorkoutsDeleteSuccess = (response: any) => ({
  response,
  type: Constants.BULK_WORKOUTS_DELETE_SUCCESS
});

export const bulkWorkoutsDeleteFail = (error: any) => ({
  error,
  type: Constants.BULK_WORKOUTS_DELETE_FAIL
});
