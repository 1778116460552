import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import {
  bulkWorkoutsDeleteFail,
  bulkWorkoutsDeleteSuccess,
  createWorkoutFail,
  createWorkoutSuccess,
  deleteWorkoutFail,
  deleteWorkoutSuccess,
  editWorkoutFail,
  editWorkoutSuccess,
  fetchWorkoutsFail,
  fetchWorkoutsSuccess,
  getWorkoutFail,
  getWorkoutSuccess,
  updateWorkoutStatusFail,
  updateWorkoutStatusSuccess,
  searchWorkoutsSuccess,
  searchWorkoutsFail
} from '../actions/workouts';
import { fetchWorkoutStagesSuccess } from '../actions/workoutStages';
import message from '../constants/notificationConstants';
import { search, bulkDelete, create, edit, get, remove, update , view } from '../services/workouts';

function* fetchWorkouts(action: any) {
  const { pageNo, limit, filterType, searchValue, workoutStageId } = action;
  try {
    const response = yield call(view, pageNo, limit, filterType, searchValue, workoutStageId);
    yield put(fetchWorkoutsSuccess(response.data));
  } catch (error) {
    toast.error(error.message);
    yield put(fetchWorkoutsFail(error));
  }
}

function* createWorkout(action: any) {
  const { name, sequenceNo, sequenceOrder, jsonContent, workoutStageId, status, workoutFor } = action;
  try {
    const response = yield call(create, name, sequenceNo, status, sequenceOrder, jsonContent, workoutStageId, workoutFor);
    yield put(createWorkoutSuccess(response));
    yield put(push('/workouts'));
    toast.success(message.WORKOUT_CREATE);
  } catch (error) {
    toast.error(error.message);
    yield put(createWorkoutFail(error));
  }
}

function* deleteWorkout(action: any) {
  const { id, workouts, workoutStages, totalCount } = action;
  try {
    yield call(remove, id);
    const index =  workouts.map((workout: any) => workout.id).indexOf(id);
    const workoutStageId = workouts[index].workout_stage_id;
    workouts.splice(index, 1);
    const workoutStageIndex =  workoutStages.map((workoutStage: any) => workoutStage.id).indexOf(workoutStageId);
    workoutStages[workoutStageIndex].workout.splice(0, 1);
    yield put(fetchWorkoutStagesSuccess(workoutStages));
    const count = totalCount - 1;
    yield put(deleteWorkoutSuccess({ workouts, count}));
    toast.success('Workout deleted successfully');
  } catch (error) {
    toast.error(error.message);
    yield put(deleteWorkoutFail(error));
  }
}

function* getWorkout(action: any) {
  const { id } = action;
  try {
    const response = yield call(get, id);
    yield call(get, id);
    yield put(getWorkoutSuccess(response.data));
  } catch (error) {
    toast.error(error.message);
    yield put(getWorkoutFail(error));
  }
}

function* editWorkout(action: any) {
  const { id, name, sequenceNo, sequenceOrder, jsonContent, workoutStageId, status, workoutFor } = action;
  try {
    const response = yield call(edit, id, name, sequenceNo, sequenceOrder, status, jsonContent, workoutStageId, workoutFor);
    yield put(editWorkoutSuccess(response));
    yield put(push('/workouts'));
  } catch (error) {
    toast.error(error.message);
    yield put(editWorkoutFail(error));
  }
}

function* updateWorkout(action: any) {
  const { id, workouts, status } = action;
  try {
    const index =  workouts.map((workout: any) => workout.id).indexOf(id);
    const updateResponse = yield call(update, id, status,  workouts[index].workout_stage_id);
    // workouts.splice(index, 1, response.data);
    const workoutResponse = yield call(view, 1, 10, 'all', '', workouts[index].workout_stage_id);
    yield put(updateWorkoutStatusSuccess(updateResponse));
    yield put(fetchWorkoutsSuccess(workoutResponse.data));
    toast.success('Workout updated successfully');
  } catch (error) {
    toast.error(error.message);
    yield put(updateWorkoutStatusFail(error));
  }
}

function* bulkWorkoutsDelete(action: any) {
  const { data } = action;
  try {
    const response = yield call(bulkDelete, data.deleteIds);
    yield put(bulkWorkoutsDeleteSuccess(response.data));
    const listResponse = yield call(view, 1, 10, 'all', '', data.activeWorkoutStage);
    yield put(fetchWorkoutsSuccess(listResponse.data));
    toast.success('Files deleted successfully');
  } catch (error) {
    yield put(bulkWorkoutsDeleteFail(error));
  }
}

function* searchWorkouts(action: any) {
  const { pageNo, limit, searchValue } = action;
  try {
    const response = yield call(search, pageNo, limit, searchValue);
    yield put(searchWorkoutsSuccess(response.data));
  } catch (error) {
    toast.error(error.message);
    yield put(searchWorkoutsFail(error));
  }
}

export { createWorkout, deleteWorkout, fetchWorkouts, searchWorkouts, editWorkout, getWorkout, updateWorkout, bulkWorkoutsDelete };
