import axios from 'axios';

export const upload = (data: any) =>
  axios({
    data,
    method: 'post',
    url: '/api/TextLibraries/upload'
  });

export const view = (data: any) =>
  axios({
    data,
    method: 'post',
    url: '/api/TextLibraries/list'
  });

  export const remove = (fileId: string) =>
  axios({
    method: 'delete',
    url: `/api/TextLibraries/${fileId}`
  });

  export const get = (fileId: string) =>
  axios({
    method: 'get',
    url: `/api/TextLibraries/${fileId}`
  });

  export const bulkDelete = (data: string[]) =>
  axios({
    data,
    method: 'delete',
    url: '/api/TextLibraries/bulk'
  });
