import Constants from './constants';

export const fetchLogoStages = () => ({
  type: Constants.LIST_LOGO_STAGES
});

export const fetchLogoStagesSuccess = (response: any) => ({
  response,
  type: Constants.LIST_LOGO_STAGES_SUCCESS
});

export const fetchLogoStagesFail = (error: any) => ({
  error,
  type: Constants.LIST_LOGO_STAGES_FAIL
});
