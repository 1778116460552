import axios from 'axios';

export const uploadWorkoutFrames = (data: any) =>
  axios({
    data,
    method: 'post',
    url: '/api/WorkoutFrames/upload'
  });

export const listWorkoutFrames = (data: any) =>
  axios({
    data,
    method: 'post',
    url: '/api/WorkoutFrames/list'
  });

export const deleteWorkoutFrames = (fileId: string) =>
  axios({
    method: 'delete',
    url: `/api/WorkoutFrames/${fileId}`
  });

export const bulkDelete = (data: any) =>
  axios({
    data: data.deleteIds,
    method: 'delete',
    url: '/api/WorkoutFrames/bulk'
  });
