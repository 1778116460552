import axios from 'axios';

export const upload = (data: any) =>
  axios({
    data,
    method: 'post',
    url: '/api/SoundLibraries/upload'
  });

export const view = (data: any) =>
  axios({
    data,
    method: 'post',
    url: '/api/SoundLibraries/list'
  });

export const remove = (fileId: string) =>
  axios({
    method: 'delete',
    url: `/api/SoundLibraries/${fileId}`
  });

export const bulkDelete = (data: any) =>
  axios({
    data: data.deleteIds,
    method: 'delete',
    url: '/api/SoundLibraries/bulk'
  });
