import keyMirror from 'key-mirror';
export default keyMirror({
  BULK_LOGO_LIBRARY_DELETE: null,
  BULK_LOGO_LIBRARY_DELETE_FAIL: null,
  BULK_LOGO_LIBRARY_DELETE_SUCCESS: null,
  DELETE_LOGO: null,
  DELETE_LOGO_FAIL: null,
  DELETE_LOGO_SUCCESS: null,
  LIST_LOGO: null,
  LIST_LOGO_FAIL: null,
  LIST_LOGO_SUCCESS: null,
  TOGGLE_CONFIRM_MODAL: null,
  UPLOAD_LOGO: null,
  UPLOAD_LOGO_FAIL: null,
  UPLOAD_LOGO_SUCCESS: null
});
