import * as React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getUserDetail, updateUserDetail } from '../../../actions/user';
import logo from '../../../assets/img/boy.svg';
import loader from '../../../assets/img/loader.gif';
import LoaderComponent from '../../../components/Loader/index';
import { IState } from '../../../reducers';
import './editProfile.css';

interface IEditProfileState {
    address: string;
    email: string;
    first_name: string;
    last_name: string;
    mobile_no: string;
    proPic: any;
    id: string;
    password: any;
    imageObj: any;
    imageChange: boolean;
    isValid: boolean;
    newPassword: string,
    confirmPassword: string
}

interface IEditProfileProps {
    loading: boolean;
    getUserDetail: any;
    match: any;
    user: any;
    updateUserDetail: any;
}

class EditProfile extends React.PureComponent<IEditProfileProps & RouteComponentProps, IEditProfileState> {

  public constructor(props: IEditProfileProps & RouteComponentProps) {
    super(props);
    this.state = {
        address: '',
        confirmPassword: '',
        email: '',
        first_name: '',
        id: '',
        imageChange: false,
        imageObj: {},
        isValid: false,
        last_name: '',
        mobile_no: '',
        newPassword: '',
        password: '',
        proPic: null
    };
  }

  public componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
        this.setState({
            id
        });
        this.props.getUserDetail(id);
    }
  }

  public handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const reader = new FileReader();
    if (file) {
          reader.readAsDataURL(file);
          reader.onload = (upload: any) => {
            const targetFile = upload.currentTarget.result;
            this.setState({
                imageChange: true,
                imageObj: file,
                proPic: targetFile
            });
          };
    }
  }

  public handleFieldChange = (e: any, key: any) => {
      const data = {};
      data[key] = e.target.value;
      this.setState(data);
  }

  public handleSaveUser = (event: any) => {
    const { id } = this.state;
    const userObj: any = {
        id,
        is_active: true
    };
    const inputFields = ['address', 'email', 'first_name', 'last_name', 'mobile_no', 'old_password', 'password'];
    inputFields.forEach(field => {
        if (this.state[field] !== '') {
            userObj[field] = this.state[field];
        }
    });
    const formData = new FormData();
    if (this.state.proPic !== '') {
      formData.append('file', this.state.imageObj);
    } else {
      userObj.profile_image = null;
    }
    formData.append('userObj', JSON.stringify(userObj));
    this.props.updateUserDetail(formData);
  }

  public cancel = () => {
    this.props.history.push('/myAccount');
  }

  public confirmPassword = (e: any) => {
    this.setState({
      confirmPassword: e.target.value
    }, () => {
      if (this.state.newPassword !== this.state.confirmPassword) {
        this.setState({
            isValid: !this.state.isValid
        });
      }
    });
  }

  public removeProfilePicture = () => {
    this.setState({ proPic: '', imageChange: true });
  }

  public render() {
    const { proPic } = this.state;
    const { imageChange, isValid } = this.state;
    const { user, loading } = this.props;
    if (!imageChange && user && user.profile_image !== null) {
        const userObj: any = {};
        Object.assign(userObj, user);
        this.setState({ proPic: userObj.profile_image });
    }
    return (
        <div className="container-fluid">
        { user &&
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="panel panel-default my-acc-hdr">
                        <div className="panel-heading workout-header-sec">
                            <div className="workout-header">
                                <h4 onClick={this.cancel}>MY ACCOUNT >&nbsp;</h4>
                                <span>EDIT PROFILE</span>
                            </div>
                            <div className="workout-btn-actions text-right remv-padd">
                                {/* <button onClick={this.cancel} className="btn cancel-btn">Cancel</button> */}
                                <button
                                    className="btn btn-success"
                                    onClick={this.handleSaveUser}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>

                    { loading ?
                        <LoaderComponent
                        loading={loading}
                        imageSrc={loader}
                        imageStyle={{marginTop: '10%', height: '100px'}}
                        />
                        :
                        <React.Fragment>
                          <div className="panel-body my-acc-body">
                            <div className="col-md-3 col-sm-4 col-xs-12 change-pic-sec">
                                <img src={proPic || logo} className="profile-pic" />
                                <div className="change-pro-pic">
                                    <input
                                        accept="image/*"
                                        id="profilePic"
                                        onChange={this.handleImageUpload}
                                        type="file"
                                    />
                                    <a>Change Profile Picture</a>
                                </div>
                                <a onClick={this.removeProfilePicture}>Remove Picture</a>
                            </div>
                            <div className="col-md-9 col-sm-8 col-xs-12">
                                <form className="account-signin">
                                    <div className="col-md-6 form-label-group">
                                        <label>First name</label>
                                        <input
                                            id="firstName"
                                            name="firstName"
                                            defaultValue={user.first_name}
                                            autoComplete="firstName"
                                            type="text"
                                            className="form-control"
                                            placeholder="First Name"
                                            required={true}
                                            onChange={(e) => this.handleFieldChange(e, 'first_name')}
                                        />
                                    </div>
                                    <div className="col-md-6 form-label-group">
                                        <label>Last Name</label>
                                        <input
                                            id="lastName"
                                            name="lastName"
                                            defaultValue={user.last_name}
                                            autoComplete="lastName"
                                            type="text"
                                            className="form-control"
                                            placeholder="Last Name"
                                            required={true}
                                            onChange={(e) => this.handleFieldChange(e, 'last_name')}
                                        />
                                    </div>
                                    <div className="col-md-6 form-label-group">
                                        <label>Email Address</label>
                                        <input
                                            id="email"
                                            name="email"
                                            value={user.email}
                                            autoComplete="email"
                                            type="email"
                                            className="form-control"
                                            placeholder="Email"
                                            required={true}
                                            onChange={(e) => this.handleFieldChange(e, 'email')}
                                        />
                                    </div>
                                    <div className="col-md-6 form-label-group">
                                        <label>Phone</label>
                                        <input
                                            id="phone"
                                            name="phone"
                                            defaultValue={user.mobile_no}
                                            autoComplete="phone"
                                            type="number"
                                            className="form-control"
                                            placeholder="phone"
                                            required={true}
                                            onChange={(e) => this.handleFieldChange(e, 'mobile_no')}
                                        />
                                    </div>
                                    <div className="col-md-12 form-label-group">
                                        <label>Address</label>
                                        <textarea
                                            className="form-control"
                                            rows={4}
                                            cols={50}
                                            onChange={(e) => this.handleFieldChange(e, 'address')}
                                            defaultValue={ user.address ? user.address : 'NA' }
                                        />
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="col-xs-12 password-sec">
                                            <h4>Password</h4>
                                            <div className="col-md-6 p-l-0 form-label-group">
                                                <label>Old Password</label>
                                                <input
                                                    id="oldPassword"
                                                    name="oldPassword"
                                                    autoComplete="oldPassword"
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Old Password"
                                                    required={true}
                                                    onChange={(e) => this.handleFieldChange(e, 'old_password')}
                                                />
                                            </div>
                                            <div className="col-xs-12 remv-padd">
                                                <div className="col-md-6 p-l-0 form-label-group">
                                                    <label>New Password</label>
                                                    <input
                                                        id="newPassword"
                                                        name="newPassword"
                                                        autoComplete="newPassword"
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="New Password"
                                                        required={true}
                                                        onChange={(e) => this.handleFieldChange(e, 'password')}
                                                    />
                                                </div>
                                                <div className="col-md-6 p-r-0 form-label-group">
                                                    <label>Confirm Password</label>
                                                    <input
                                                        id="confirmPassword"
                                                        name="confirmPassword"
                                                        autoComplete="confirmPassword"
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Confirm Password"
                                                        required={true}
                                                        onChange={(e) => this.confirmPassword(e)}
                                                    />
                                                    <p className={`error-text ${isValid ? 'enable-div' : 'disable-div'}`}>Password Doesn't match</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                          </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        }
        </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  loading: state.user.loading,
  user: state.user.user
});

const mapDispatchToProps = ({
    getUserDetail,
    updateUserDetail
});

interface IStateProps {
  loading: boolean;
  user: any
}

interface IDispatchProps {
    getUserDetail: any;
    updateUserDetail: any;
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLocalize
)(EditProfile);
