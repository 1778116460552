import Constants from '../actions/user/constants';

const initialState = {
  emailStatus: false,
  loading: false,
  pinStatus: false,
  totalCount: 0,
  user: null,
  userObj: null,
  users: []
};

import { IActionProps } from './index';

export interface IuserReducer {
  emailStatus: boolean,
  pinStatus: boolean,
  loading: boolean;
  totalCount: number;
  user: any;
  userObj: any;
  users: any[];
}

export const userReducer = (
  state: IuserReducer = initialState,
  action: IActionProps
) => {
  switch (action.type) {
    case Constants.FETCH_USERS:
      return {
        ...state,
        loading: true
      };
    case Constants.FETCH_USERS_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalCount: action.response.users.totalCount,
        users: action.response.users.users
      };
    case Constants.LOGIN_USER:
      return {
        ...state,
        loading: true
      };
    case Constants.LOGIN_USER_SUCCESS:
      if (action.response.user.profile_image &&
        action.response.user.profile_image.includes('.')) {
        action.response.user.profile_image = `${action.response.s3Url}${action.response.user.profile_image}`;
      }
      return {
        ...state,
        loading: false,
        user: action.response.user,
        userObj: action.response.user
      };
    case Constants.LOGIN_USER_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.LOGOUT_USER:
      return {
        ...state,
        loading: true
      };
    case Constants.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null
      };
    case Constants.LOGOUT_USER_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.GET_USER:
      return {
        ...state,
        loading: true
      };
    case Constants.GET_USER_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.GET_USER_SUCCESS:
      if (action.response.profile_image &&
          action.response.profile_image.includes('.')) {
            action.response.profile_image = `${action.response.s3Url}${action.response.profile_image}`;
      }
      return {
        ...state,
        loading: false,
        user: action.response
      };
    case Constants.VERIFY_USER:
      return {
        ...state,
        loading: true
      };
    case Constants.VERIFY_USER_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.VERIFY_USER_SUCCESS:
      return {
        ...state,
        emailStatus: action.response.email,
        loading: false,
        pinStatus: action.response.pin
      };
    case Constants.RESET_PASSWORD:
      return {
        ...state,
        loading: true
      };
    case Constants.RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case Constants.GET_USER_DETAIL:
      return {
        ...state,
        loading: true
      };
    case Constants.GET_USER_DETAIL_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.GET_USER_DETAIL_SUCCESS:
      if (action.response.user.profile_image &&
        action.response.user.profile_image.includes('.')) {
        action.response.user.profile_image = `${action.response.s3Url}${action.response.user.profile_image}`;
      }
      return {
        ...state,
        loading: false,
        userObj: action.response.user
      };
    case Constants.CLEAR_USER:
      return {
        ...state,
        user: {}
      };
    case Constants.DELETE_USER:
      return {
        ...state,
        loading: true
      };
    case Constants.DELETE_USER_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case Constants.CREATE_USER:
      return {
        ...state,
        loading: true
      };
    case Constants.CREATE_USER_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
