import Constants from './constants';

export const deleteText = (data: any) => ({
  data,
  type: Constants.DELETE_TEXT
});

export const deleteTextSuccess = (response: any) => ({
  response,
  type: Constants.DELETE_TEXT_SUCCESS
});

export const deleteTextFail = (error: any) => ({
  error,
  type: Constants.DELETE_TEXT_FAIL
});

export const uploadText = (data: any) => ({
  data,
  type: Constants.UPLOAD_TEXT
});

export const uploadTextSuccess = (response: any) => ({
  response,
  type: Constants.UPLOAD_TEXT_SUCCESS
});

export const uploadTextFail = (error: any) => ({
  error,
  type: Constants.UPLOAD_TEXT_FAIL
});

export const fetchTextList = (data: any) => ({
  data,
  type: Constants.FETCH_TEXT
});

export const fetchTextListSuccess = (response: any) => ({
  response,
  type: Constants.FETCH_TEXT_SUCCESS
});

export const fetchTextListFail = (error: any) => ({
  error,
  type: Constants.FETCH_TEXT_FAIL
});

export const getText = (id: string) => ({
  id,
  type: Constants.GET_TEXT
});

export const getTextSuccess = (response: any) => ({
  response,
  type: Constants.GET_TEXT_SUCCESS
});

export const getTextFail = (error: any) => ({
  error,
  type: Constants.GET_TEXT_FAIL
});

export const clearText = () => ({
  type: Constants.CLEAR_TEXT
});

export const bulkDelete = (data: string[]) => ({
  data,
  type: Constants.BULK_DELETE
});

export const bulkDeleteSuccess = (response: any) => ({
  response,
  type: Constants.BULK_DELETE_SUCCESS
});

export const bulkDeleteFail = (error: any) => ({
  error,
  type: Constants.BULK_DELETE_FAIL
});

export const toggleConfirmModal = (status: boolean) => ({
  status,
  type: Constants.TOGGLE_CONFIRM_MODAL
});
