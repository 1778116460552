import Constants from '../actions/workoutFrames/constants';

const initialState = {
  deleteResponse: null,
  duplicateFiles: [],
  imageUpload: null,
  imageUrl: null,
  loading: false,
  showReplaceModal: false,
  totalCount: 0,
  workoutFrames: []
};

import { IActionProps } from './index';

export interface IworkoutFrameReducer {
  deleteResponse: any;
  duplicateFiles: string[];
  imageUpload: any;
  imageUrl: any;
  loading: boolean;
  totalCount: any;
  workoutFrames: any[];
  showReplaceModal: boolean;
}

export const workoutFrameReducer = (
  state: IworkoutFrameReducer = initialState,
  action: IActionProps
) => {
  switch (action.type) {
    case Constants.DELETE_WORKOUT_FRAMES:
      return {
        ...state,
        loading: true
      };
    case Constants.DELETE_WORKOUT_FRAMES_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.DELETE_WORKOUT_FRAMES_SUCCESS:
      return {
        ...state,
        deleteResponse: action.response,
        loading: false
      };
    case Constants.UPLOAD_WORKOUT_FRAMES:
      return {
        ...state,
        loading: true
      };
    case Constants.UPLOAD_WORKOUT_FRAMES_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.UPLOAD_WORKOUT_FRAMES_SUCCESS:
      return {
        ...state,
        duplicateFiles: action.response.duplicateFiles,
        loading: false,
        showReplaceModal: action.response.status
      };
    case Constants.LIST_WORKOUT_FRAMES:
      return {
        ...state,
        loading: true
      };
    case Constants.LIST_WORKOUT_FRAMES_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.LIST_WORKOUT_FRAMES_SUCCESS:
      return {
        ...state,
        imageUrl: action.response.images.s3Url,
        loading: false,
        totalCount: action.response.images.totalCount,
        workoutFrames: action.response.images.images
      };
    case Constants.TOGGLE_CONFIRM_MODAL:
      return {
        ...state,
        showReplaceModal: false
      };
    case Constants.BULK_WORKOUT_FRAMES_DELETE:
      return {
        ...state,
        loading: true
      };
    case Constants.BULK_WORKOUT_FRAMES_DELETE_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.BULK_WORKOUT_FRAMES_DELETE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
