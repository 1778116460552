import * as React from 'react';
import 'react-image-lightbox/style.css';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { AppProperties } from '../../constants/application.properties';
import { searchWorkouts } from '../../actions/workouts';
import loader from '../../assets/img/loader.gif';
import { Link } from 'react-router-dom';
import LoaderComponent from '../../components/Loader/index';
import { IState } from '../../reducers';
import './WorkoutSearch.css';

interface IWorkoutSearchState {
  limit: any,
  pageNo: any,
  searchString: string
}

interface IWorkoutSearchProps {
  searchWorkouts: any,
  searchedWorkouts: any,
  loading: boolean
}

class WorkoutSearch extends React.PureComponent<IWorkoutSearchProps & RouteComponentProps, IWorkoutSearchState> {

  public state: IWorkoutSearchState;

  constructor(props: IWorkoutSearchProps & RouteComponentProps) {
    super(props);
    this.state = {
      limit: 30,
      pageNo: 1,
      searchString: ''
    };
  }

  public componentWillUnmount() {
    const { pageNo, limit } = this.state;
    this.props.searchWorkouts(pageNo, limit, '');
  }

  public handleOnSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchStr = event.target.value;
    this.setState({
      searchString: searchStr
    }, () => {
      if (searchStr.length > 2 || searchStr.length === 0) {
        const { pageNo, limit, searchString } = this.state;
        this.props.searchWorkouts(pageNo, limit, searchString);
      }
    });
  }

  public render() {
    const { searchString } = this.state;
    const { searchedWorkouts, loading } = this.props;
    const totalCount = searchedWorkouts.length;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="panel panel-default">
              <div className="panel-heading frames-heading">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-3 col-xs-12">
                    <h4><b>Search Workouts</b></h4>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-9 col-xs-12">
                    <div className="search-box pull-right">
                      <span className="icon-search">
                        <i className="glyphicon glyphicon-search" />
                      </span>
                      <input
                        placeholder="Search Workouts"
                        type="text"
                        value={searchString}
                        onChange={(e) => this.handleOnSearch(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-heading frames-heading">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-3 col-xs-12">
                    <h4><b>{totalCount} Workouts</b></h4>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 workout-table remv-padd">
                    <div className="col-xs-12 remv-padd table-content">
                      <div className="col-xs-12 table-header remv-padd">
                        <div className="col-xs-3 col-md-3 remv-padd">
                          <h5>WORKOUTS</h5>
                        </div>
                        <div className="col-xs-3 col-md-4 remv-padd">
                          <h5>STAGE</h5>
                        </div>
                        <div className="col-xs-2 col-md-2 remv-padd">
                          <h5>STATUS</h5>
                        </div>
                        <div className="col-xs-3 col-md-3 remv-padd">
                          <h5>ACTIONS</h5>
                        </div>
                      </div>
                      {loading ?
                        <LoaderComponent
                          loading={true}
                          imageSrc={loader}
                          imageStyle={{ marginTop: '20px', height: '100px' }}
                        />
                        :
                        searchedWorkouts && searchedWorkouts.length > 0 &&
                        searchedWorkouts.map((data: any) => (
                          <div className={`col-xs-12 table-row remv-padd`} title={data.workoutname}>
                            <div className="col-xs-3 col-md-3 remv-padd">
                              <p>{data.workoutname}</p>
                            </div>
                            <div className="col-xs-3 col-md-4 remv-padd">
                              <p>{data.name}</p>
                            </div>
                            <div className="col-xs-2 col-md-2 remv-padd">
                              <p className={AppProperties.STATUS[data.status]}>
                                {(AppProperties.STATUS[data.status]).charAt(0).toUpperCase() + (AppProperties.STATUS[data.status]).slice(1)}
                              </p>
                            </div>
                            <div className="col-xs-3 col-md-3 remv-padd">
                              <p>
                              <Link className="forgot-password" to={`/workouts/${data.id}/edit`} target="_blank">
                                <i className="glyphicon glyphicon-pencil ws-edit-icon" title="Edit" />
                              </Link>
                              </p>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  searchedWorkouts: state.workout.searchedWorkouts,
  loading: state.workout.loading
});

const mapDispatchToProps = ({
  searchWorkouts
});

interface IStateProps {
  searchedWorkouts: any[];
  loading: boolean;
}

interface IDispatchProps {
  searchWorkouts: any
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps, mapDispatchToProps
  ),
  withLocalize
)(WorkoutSearch);
