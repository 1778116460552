import Ajv from 'ajv';
import * as React from 'react';
import { Button, DropdownButton, MenuItem, Radio } from 'react-bootstrap';
import ReactJSONEditor from 'react-json-editor-ajrm';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { clearWorkout, createWorkout, editWorkout, getWorkout } from '../../../actions/workouts';
import { fetchWorkoutStages } from '../../../actions/workoutStages';
import loader from '../../../assets/img/loader.gif';
import LoaderComponent from '../../../components/Loader/index';
import schema from '../../../constants/jsonSchema';
import { IState } from '../../../reducers';

import './jsoneditor.scss';

interface IJSONEditorProps extends IDispatchProps {
  baseProps: {
    placeholder?: object,
    viewOnly?: boolean,
    onChange?: (value: object) => void,
    onKeyPressUpdate?: boolean,
    confirmGood?: boolean,
    height?: number,
    width?: string,
    theme?: string,
    colors?: object,
    style?: object,
    reset?: boolean,
    waitAfterKeyPress?: number,
    modifyErrorText?: (customMsg: string) => string
  },
  match: any,
  categories: any[],
  fetchWorkoutStages: () => void,
  editWorkout: any,
  loading: boolean
}

interface ICustomJSONEditorState {
  json: any,
  view: boolean,
  name: string,
  orderSequences: any[],
  activeCategory: any,
  activeSequence: number,
  sequenceOrder: string,
  workoutFor: string,
  category: any,
  id: any,
  isBlocking: boolean,
  workoutLoaded: boolean,
  error: any[],
  saveJson: boolean,
  checkJson: boolean,
  saveDraft: boolean,
  isEdit: boolean }

class JSONEditor extends React.Component<IJSONEditorProps & RouteComponentProps, ICustomJSONEditorState> {

  public static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if ((nextProps.workout && Boolean(Object.keys(nextProps.workout).length))) {
      if (nextProps.categories && nextProps.categories.length !== 0 && !prevState.workoutLoaded) {
        const index =  nextProps.categories.map((data: any) => data.id).indexOf(nextProps.workout.workout_stage_id);
        const category = nextProps.categories[index];
        const series = Array.apply(null, {length: category.workout.length}).map(Number.call , Number);
        return {
          activeCategory: category.name,
          activeSequence: nextProps.workout.sequence_no,
          categories: nextProps.categories,
          category,
          id: nextProps.workout.id,
          isEdit: true,
          json: nextProps.workout.json_content,
          name: nextProps.workout.name,
          workoutFor: nextProps.workout.workout_for,
          orderSequences: series,
          saveDraft: false,
          workoutLoaded: true
        };
      }
    } else if (nextProps.categories && nextProps.categories.length !== 0 && nextProps.match.params.workoutStageId && !prevState.workoutLoaded) {
      const index =  nextProps.categories.map((data: any) => data.id).indexOf(nextProps.match.params.workoutStageId);
      const category = nextProps.categories[index];
      const series = Array.apply(null, {length: category.workout.length}).map(Number.call , Number);
      return {
        activeCategory: category.name,
        activeSequence: series[series.length - 1],
        category,
        orderSequences: series,
        workoutLoaded: true
      };
    }
    return null;
  }
  public ajv = new Ajv({ $data: true, allErrors: true });

  public constructor(props: IJSONEditorProps & RouteComponentProps) {
    super(props);
    this.state = {
      activeCategory: null,
      activeSequence: 0,
      category: null,
      checkJson: true,
      error: [],
      id: '',
      isBlocking: false,
      isEdit: false,
      json: {},
      name: '',
      orderSequences: [],
      saveDraft: true,
      saveJson: true,
      sequenceOrder: 'After',
      view: false,
      workoutLoaded: false,
      workoutFor: 'Male'
    };
  }

  public clearEditor = () => {
    this.setState({
      json: {}
    });
  }

  public loadJsonFromSelectedFile = (event: any) => {
    const isValid = this.ajv.validate(schema, JSON.parse(event.target.result));
    if (isValid) {
      // this.setState({
      //   json: JSON.parse(event.target.result)
      // }, () => {
      //   if (this.props.location.pathname.split('/')[3] === 'create') {
      //     this.setState({ isBlocking: Boolean(Object.keys(this.state.json).length)});
      //   }
      // });
      this.setState({
        json: JSON.parse(event.target.result)
      });
    } else {
      this.setState({
        error: this.ajv.errors,
        json: JSON.parse(event.target.result)
      });
    }
  }

  public handleSelect = (event: any) => {
    const fileReader = new FileReader();
    fileReader.onload = this.loadJsonFromSelectedFile;
    fileReader.readAsText(event.target.files[0]);
    this.setState({
      checkJson: false
    });
  }

  public cancel = () => {
    this.props.history.push('/workouts');
  }

  public onChangeJson = (value: any) => {
    if (value.jsObject !== undefined) {
      this.setState({
        checkJson: false,
        error: [],
        json: value.jsObject
      });
    } else if (value.jsObject === undefined) {
      const errors = []; // tslint:disable-next-line
      typeof value.error === 'object' ? errors.push({message: value.error.reason}) : '';
      this.setState({
        error: errors
      });
    }
  }

  public checkJsonError = () => {
    const { id, json, name, sequenceOrder, activeSequence, error } = this.state;
    let isValid = this.ajv.validate(schema, json);
    if (!isValid) {
      this.setState({
        error: this.ajv.errors
      });
      toast.error('Error in json');
    } else {
      this.setState({
        error: []
      });
    }
    if (name === '') {
      isValid = false;
      toast.error('Name cant be blank');
    } else if (id === '' && sequenceOrder === '' && activeSequence !== 0) {
      isValid = false;
      toast.error('Order sequence not selected');
    } else if (error.length > 0) {
      isValid = false;
      toast.error('Error in json');
    }
    return isValid;
  }

  public onCategory = (index: any) => {
    const { categories } = this.props;
    const series = Array.apply(null, {length: categories[index].workout.length}).map(Number.call , Number);
      this.setState({
        activeCategory: categories[index].name,
        category: categories[index],
        orderSequences: series
      });
  }

  public onSequence = (index: any) => {
    this.setState({
      activeSequence: index
    });
  }

  public action = (e: any) => {
    this.setState({
      sequenceOrder: e.target.value === 'Before' ? 'Before' : 'After'
    });
  }

  public workoutForaction = (e: any) => {
    this.setState({
      workoutFor: e.target.value === 'Female' ? 'Female' : 'Male'
    });
  }

  public componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchWorkoutStages();
    if (id) {
      this.props.getWorkout(id);
    }
  }

  public componentWillUnmount() {
    this.props.clearWorkout();
  }

  public submit(action: number) {
    const { id, name, activeSequence, category, json, sequenceOrder, error, workoutFor } = this.state;
    const isValid = this.checkJsonError();
    if (isValid && id === '' && activeSequence === 0 && error.length === 0) {
      this.props.createWorkout(name, activeSequence, 'After', json, category.id, action, workoutFor);
      // this.setState({ isBlocking: false});
    } else if (isValid && id === '' && activeSequence !== 0 && error.length === 0) {
      this.props.createWorkout(name, activeSequence ? activeSequence : 0 , sequenceOrder, json, category.id, action, workoutFor);
      // this.setState({ isBlocking: false});
    } else if (isValid && id !== '' && error.length === 0) {
      this.props.editWorkout(id, name, activeSequence, sequenceOrder, json, category.id, action, workoutFor);
      // this.setState({ isBlocking: false});
    }
  }

  public setName(e: any) {
    this.setState({
      name: e.target.value
    });
  }

  public render() {
    const { baseProps, categories, loading } = this.props;
    const { json,
            view,
            orderSequences,
            activeCategory,
            activeSequence,
            sequenceOrder,
            workoutFor,
            name,
            id,
            // isBlocking,
            isEdit,
            error } = this.state;
            console.log(workoutFor);
    return (
      <React.Fragment>
      {/* <Prompt
          when={isBlocking}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
      /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default">
              <div className="panel-heading workout-header-sec">
                <div className="workout-header">
                  <h4 onClick={this.cancel}>WORKOUTS >&nbsp;</h4>
                  <span>
                  {// tslint:disable-next-line
                    id == 0 ? 'NEW' : 'EDIT'
                  } WORKOUT</span>
                </div>
                <div className="workout-btn-actions text-right remv-padd">
                  {/* <Button bsStyle="danger" onClick={() => this.cancel()}>Cancel</Button> */}
                  <Button bsStyle="success" onClick={() => this.submit(0)}>Save as Draft</Button>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-md-4">
                  <label>Name</label>
                  <input type="text"
                    placeholder="name"
                    className="form-control"
                    value={name}
                    onChange={(e) => this.setName(e)}
                  />
                </div>
                <div className="col-md-3">
                  <label>Category</label>
                  <DropdownButton
                      title={activeCategory ? activeCategory : 'Categories'}
                      key="column-toggle"
                      id="column-toggle"
                      onSelect={this.onCategory}
                      disabled={isEdit}
                  >
                    { categories && categories.map((category, index) => (
                      <MenuItem key={category.id} eventKey={index}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </DropdownButton>
                </div>
                <div className="col-md-5 flex">
                  <label>Order sequence:</label>
                  <div>
                    <Radio
                      name="radioGroup"
                      inline={true}
                      checked={sequenceOrder === 'Before' ? true : false}
                      disabled={activeSequence === 0 || (orderSequences && orderSequences.length === 0) ? true : false}
                      value="Before"
                      onChange={(e) => this.action(e)}
                      >
                      Before
                    </Radio>{' '}
                    <Radio
                      name="radioGroup"
                      inline={true}
                      checked={activeSequence === 0 || sequenceOrder === 'After'  || (orderSequences && orderSequences.length === 0) ? true : false}
                      value="After"
                      onChange={(e) => this.action(e)}
                      >
                      After
                    </Radio>{' '}
                  </div>
                  <DropdownButton
                      title={activeSequence ? activeSequence : '0'}
                      key="column-toggle"
                      id="column-toggle"
                      disabled={orderSequences && orderSequences.length === 0 ? true : false}
                      onSelect={this.onSequence}
                  >
                    { orderSequences && orderSequences.map((orderSequence, index) => (
                      <MenuItem key={index} eventKey={orderSequence}>
                        {orderSequence}
                      </MenuItem>
                    ))}
                  </DropdownButton>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-md-4">
                 <label>Workout for:</label>
                 <Radio
                      name="radioGroupGender"
                      inline={true}
                      checked={workoutFor === 'Male' ? true : false}
                      value="Male"
                      onChange={(e) => this.workoutForaction(e)}
                      >
                      Male
                    </Radio>{' '}
                    <Radio
                      name="radioGroupGender"
                      inline={true}
                      checked={workoutFor === 'Female' ? true : false}
                      value="Female"
                      onChange={(e) => this.workoutForaction(e)}
                      >
                      Female
                    </Radio>{' '}
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4>Json Editor</h4>
                <div className="json-editor-container">
                  <div className="tools">
                    <div className="upload-btn pull-right">
                      <input
                      type="file"
                      accept=".json"
                      onChange={this.handleSelect}
                      />
                      <Button bsStyle="success">
                        <i className="glyphicon glyphicon-upload p-r-5" />
                        Upload Json
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              { loading ?
                <LoaderComponent
                  loading={loading}
                  imageSrc={loader}
                  imageStyle={{marginTop: '10%', height: '100px'}}
                />
                :
                <React.Fragment>
              <ReactJSONEditor
                { ...{ ...baseProps,
                  colors: { 'keys': '#000000', 'default': '#000000', 'number': '#FF4B39', 'string': '#2B942A'},
                  onChange: (value: any) => { this.onChangeJson(value); },
                  placeholder: json,
                  style: { 'body': { 'backgroundColor': 'white' }, 'outerBox': { 'height': '300px'}, 'container': { 'height': '300px'}},
                  viewOnly: view,
                  width: '100%'
                } }
              />
              <div className="panel-footer">
                <div className="float-right">
                  <div className="d-flex">
                    <button className="btn no-border" onClick={this.clearEditor}>Clear Editor</button>
                    <Button bsStyle="success" onClick={() => this.submit(0)}>Save as Draft</Button>
                  </div>
                </div>
              </div>
              </React.Fragment>
              }
            </div>
            { error && error.length > 0 &&
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4>Error: <span>{error.length}</span></h4>
                </div>
                <div className="error-logs">
                  { error.map( err => (
                    <p>{err.dataPath} <span>{err.message}</span></p>
                  ))}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IState, route: any) => ({
  categories: state.workoutStages.workoutStages,
  loading: state.workout.loading,
  workout: state.workout.workout
});

const mapDispatchToProps = ({
  clearWorkout,
  createWorkout,
  editWorkout,
  fetchWorkoutStages,
  getWorkout
});

interface IStateProps {
  categories: any;
  loading: any;
}

interface IDispatchProps {
  createWorkout: any;
  fetchWorkoutStages: any;
  getWorkout: any;
  editWorkout: any;
  clearWorkout: any;
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(JSONEditor);
