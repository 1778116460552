import Constants from '../actions/soundLibrary/constants';

const initialState = {
  deleteResponse: null,
  duplicateFiles: [],
  imageUpload: null,
  soundUrl: null,
  instructionUrl: null,
  loading: false,
  showReplaceModal: false,
  soundList: [],
  totalCount: 0
};

import { IActionProps } from './index';

export interface IsoundReducer {
  deleteResponse: any;
  duplicateFiles: string[];
  imageUpload: any;
  soundUrl: any;
  instructionUrl: any;
  loading: boolean;
  totalCount: any;
  soundList: any[];
  showReplaceModal: boolean;
}

export const soundReducer = (
  state: IsoundReducer = initialState,
  action: IActionProps
) => {
  switch (action.type) {
    case Constants.DELETE_SOUND:
      return {
        ...state,
        loading: true
      };
    case Constants.DELETE_SOUND_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.DELETE_SOUND_SUCCESS:
      return {
        ...state,
        deleteResponse: action.response,
        loading: false
      };
    case Constants.UPLOAD_SOUND:
      return {
        ...state,
        loading: true
      };
    case Constants.UPLOAD_SOUND_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.UPLOAD_SOUND_SUCCESS:
      return {
        ...state,
        duplicateFiles: action.response.duplicateFiles,
        loading: false,
        showReplaceModal: action.response.status
      };
    case Constants.LIST_SOUND:
      return {
        ...state,
        loading: true
      };
    case Constants.LIST_SOUND_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.LIST_SOUND_SUCCESS:
      return {
        ...state,
        soundUrl: action.response.sound_libraries.s3Url,
        instructionUrl: action.response.sound_libraries.s3AudioUrl,
        loading: false,
        soundList: action.response.sound_libraries.audio,
        totalCount: action.response.sound_libraries.totalCount
      };
    case Constants.BULK_SOUND_LIBRARY_DELETE:
      return {
        ...state,
        loading: true
      };
    case Constants.BULK_SOUND_LIBRARY_DELETE_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.BULK_SOUND_LIBRARY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case Constants.TOGGLE_CONFIRM_MODAL:
      return {
        ...state,
        showReplaceModal: false
      };
    default:
      return state;
  }
};
