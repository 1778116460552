import * as React from 'react';
import { Fragment } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { compose } from 'redux';
import { updateLoggedInStatus } from '../../actions/global';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import { languages } from '../../global/languages';
import { IState } from '../../reducers';
import { LoggedInRoutes,
  persistantRoutes as PersistantRoutes } from '../../routes';
import defaultLanguage from '../../translations/en.welcome.json';

import './style.css';

interface IAppWrapperProps extends LocalizeContextProps {
  loading: boolean;
  isUserLoggedIn: boolean;
}

interface IAppWrapperState {
  status: string;
}

class AppWrapper extends React.Component<IAppWrapperProps, IAppWrapperState> {
  constructor(props: IAppWrapperProps) {
    super(props);
    this.state = {
      status: ''
    };
    this.props.initialize({
      languages,
      options: { renderToStaticMarkup },
      translation: defaultLanguage
    });
  }

  public render() {
    const { isUserLoggedIn } = this.props;
    return (
      <Fragment>
        {
          isUserLoggedIn && (
            <Fragment>
              <NavBar key="nav"/>
              <div className="container-fluid">
                <SideBar/>
                <div key="mainContainer" className="main">
                  <LoggedInRoutes key="logged-in-routes"/>
                </div>
              </div>
             </Fragment>
          ) ||
            <PersistantRoutes/>
        }
        <ToastContainer
         autoClose={5000}
         closeOnClick={true}
         draggable={true}
         hideProgressBar={false}
         pauseOnHover={true}
         position="top-right"
         />
      </Fragment>
    );
  }

  public componentDidUpdate(prevProps: any) {
    const hasActiveLanguageChanged =
      prevProps.activeLanguage &&  prevProps.activeLanguage !== this.props.activeLanguage;
    if (hasActiveLanguageChanged) {
      this.addTranslationsForActiveLanguage(this.props.activeLanguage);
    }
  }

  private addTranslationsForActiveLanguage(activeLanguage: any) {
    this.props.addTranslationForLanguage(require(`../../translations/${activeLanguage.code}.welcome.json`), activeLanguage.code);
  }

}

const mapStateToProps = (state: IState) => ({
  isUserLoggedIn: state.global.userStatus.loggedIn,
  loading: state.global.loading
});

const mapDispatchToProps = (dispatch: any) => ({
  updateLoggedInStatus: (userStatus: any) =>
    dispatch(updateLoggedInStatus(userStatus))
});

interface IStateProps {
  isUserLoggedIn: boolean;
  loading: boolean;
}

interface IDispatchProps {
  updateLoggedInStatus: (userStatus: any) => void;
}

export default compose(
  withRouter,
  withLocalize,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(AppWrapper);
