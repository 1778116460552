import { call, put } from 'redux-saga/effects';
import { AppProperties } from 'src/constants/application.properties';
import { view } from 'src/services/workouts';
import { fetchWorkoutsFail, fetchWorkoutsSuccess } from '../actions/workouts';
import { fetchWorkoutStagesFail, fetchWorkoutStagesSuccess } from '../actions/workoutStages';
import { viewWorkoutStages } from '../services/workoutStages';
import storage from '../utils/storage';

function* fetchWorkoutStages() {
  try {
    const index = storage.getItem(AppProperties.INDEX) ? storage.getItem(AppProperties.INDEX) : 0;
    const response = yield call(viewWorkoutStages);
    const workouts = yield call(view, 1, 10, 'all', '', response.data[index].id);
    yield put(fetchWorkoutStagesSuccess(response.data));
    yield put(fetchWorkoutsSuccess(workouts.data));
  } catch (error) {
    yield put(fetchWorkoutStagesFail(error));
    yield put(fetchWorkoutsFail(error));
  }
}

export { fetchWorkoutStages };
