import * as React from 'react';
import { FormControl, FormGroup, Navbar } from 'react-bootstrap';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import appLogo from '../../../src/assets/img/logo.jpg';
import { getUserByAccessToken } from '../../actions/user';
import { getMobileApplications, mobileApplicationChange } from '../../actions/global';
import { fetchWorkoutStages } from '../../actions/workoutStages';
import logo from '../../assets/img/boy.svg';
import { AppProperties } from '../../constants/application.properties';
import { IState } from '../../reducers';
import { themes } from '../../themes';
import storage from '../../utils/storage';
import './NavBar.css';

interface INavState {
  availableThemes: any[];
  openSideNav: boolean;
}

interface INavProps extends LocalizeContextProps, IStateProps, IDispatchProps {
  classes: any;
  getUserByAccessToken: any,
  getMobileApplications: any,
  mobileApplicationChange: any,
  fetchWorkoutStages: any,
}

class NavBar extends React.Component<INavProps & RouteComponentProps, INavState> {

  constructor(props: INavProps & RouteComponentProps) {
    super(props);
    this.state = {
      availableThemes: Object.keys(themes).map(key => {
        themes[key].code = key;
        return themes[key];
      }),
      openSideNav: false
    };
  }

  public componentDidMount() {
    const accessToken = storage.getItem(AppProperties.ACCESS_TOKEN_KEY);
    if (accessToken) {
      this.props.getUserByAccessToken(accessToken);
    }
    this.props.getMobileApplications();
  }

  public changeMobileApplication = (event: any) => {
    storage.setItem('application_id', event.target.value);
    this.props.history.push('/Workouts');
    this.props.fetchWorkoutStages();
    this.props.mobileApplicationChange(true);
  }

  public render() {
    const { user, mobileApplications } = this.props;
    return (
      <React.Fragment>
        <Navbar collapseOnSelect={true} fixedTop={true} fluid={true}>
          <Navbar.Header>
            <div className="d-flex">
              <div className="user-sec">
                <img className="logo" src={user && user.profile_image !== null && user.profile_image !== '' ? user.profile_image : logo} />
                <div className="user-cnt">
                  <h4 className="font-medium">
                  {user ? user.first_name : ''} {user ? user.last_name : ''}
                  </h4>
                  <h5 className="font-medium">{user && user.user_role.name === 'ADMIN' ? 'ADMIN' : 'SUPER ADMIN'}</h5>
                </div>
              </div>
              <div className="logo-sec">
                <img className="app-logo"
                  src={appLogo} width="40" height="40" />
                <p>Power 20</p>
              </div>
              <div className="app-sec">
                <form>
                <FormGroup className="mb-0" controlId="formControlsSelect">
                  <FormControl componentClass="select" onChange={this.changeMobileApplication}>
                    { mobileApplications && mobileApplications.map((application: any, index: any) => (
                      <option key={index} value={application.id} selected={application.id === storage.getItem('application_id')}>{application.name}</option>
                    ))}
                  </FormControl>
                </FormGroup>
                </form>
              </div>
            </div>
          </Navbar.Header>
        </Navbar>
      </React.Fragment>
    );
  }
}

interface IStateProps {
  activeTheme: string;
  user: any;
  mobileApplications: any;
}

interface IDispatchProps {
  getUserByAccessToken: any,
  getMobileApplications: any,
  fetchWorkoutStages: any,
  mobileApplicationChange: any
}

const mapStateToProps = (state: IState) => ({
  activeTheme: state.theme.activeTheme,
  user: state.user.userObj,
  mobileApplications: state.global.applications.mobileApplications
});

const mapDispatchToProps = ({
  getUserByAccessToken,
  getMobileApplications,
  fetchWorkoutStages,
  mobileApplicationChange
});

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLocalize
)(NavBar);
