import axios from 'axios';

export const view = (data: any) =>
  axios({
    data,
    method: 'post',
    url: '/api/Users/list'
  });

export const loginUser = (email: string , password: string) =>
  axios({
    data: {
      email,
      password
    },
    method: 'post',
    url: '/api/Users/admin/login'
  });

export const logoutUser = (token: string) =>
  axios({
    method: 'post',
    url: `/api/Users/logout?access_token=${token}`
  });

export const emailVerify = (email: string) =>
  axios({
    data: {
      email
    },
    method: 'post',
    url: '/api/Users/verify'
  });

export const pinVerify = (email: string , pin: any) =>
  axios({
    data: {
      email,
      pin
    },
    method: 'post',
    url: '/api/Users/verify'
  });

export const reset = (email: string , password: string) =>
  axios({
    data: {
      email,
      password
    },
    method: 'post',
    url: '/api/Users/resetPassword'
  });

export const get = (id: string) =>
  axios({
    method: 'get',
    url: `/api/Users/${id}`
  });

export const update = (data: any) =>
  axios({
    data,
    method: 'post',
    url: 'api/Users/updateAdmin'
  });

export const create = (data: any) =>
  axios({
    data,
    method: 'post',
    url: 'api/Users/admin/create'
  });

  export const getUserByAccessToken = (token: string) =>
  axios({
    data : {
      token
    },
    method: 'post',
    url: 'api/Users/tokenVerify'
  });

  export const remove = (data: any) =>
  axios({
    data : {
      is_active: data.is_active
    },
    method: 'patch',
    url: `/api/Users/delete/${data.id}`
  });
