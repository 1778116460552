import Constants from './constants';

export const deleteWorkoutFrames = (data: any) => ({
  data,
  type: Constants.DELETE_WORKOUT_FRAMES
});

export const deleteWorkoutFramesSuccess = (response: any) => ({
  response,
  type: Constants.DELETE_WORKOUT_FRAMES_SUCCESS
});

export const deleteWorkoutFramesFail = (error: any) => ({
  error,
  type: Constants.DELETE_WORKOUT_FRAMES_FAIL
});

export const uploadWorkoutFrames = (data: any) => ({
  data,
  type: Constants.UPLOAD_WORKOUT_FRAMES
});

export const uploadWorkoutFramesSuccess = (response: any) => ({
  response,
  type: Constants.UPLOAD_WORKOUT_FRAMES_SUCCESS
});

export const uploadWorkoutFramesFail = (error: any) => ({
  error,
  type: Constants.UPLOAD_WORKOUT_FRAMES_FAIL
});

export const listWorkoutFrames = (data: any) => ({
  data,
  type: Constants.LIST_WORKOUT_FRAMES
});

export const listWorkoutFramesSuccess = (response: any) => ({
  response,
  type: Constants.LIST_WORKOUT_FRAMES_SUCCESS
});

export const listWorkoutFramesFail = (error: any) => ({
  error,
  type: Constants.LIST_WORKOUT_FRAMES_FAIL
});

export const bulkWorkoutFramesDelete = (data: any) => ({
  data,
  type: Constants.BULK_WORKOUT_FRAMES_DELETE
});

export const bulkWorkoutFramesDeleteSuccess = (response: any) => ({
  response,
  type: Constants.BULK_WORKOUT_FRAMES_DELETE_SUCCESS
});

export const bulkWorkoutFramesDeleteFail = (error: any) => ({
  error,
  type: Constants.BULK_WORKOUT_FRAMES_DELETE_FAIL
});

export const toggleConfirmModal = (status: boolean) => ({
  status,
  type: Constants.TOGGLE_CONFIRM_MODAL
});
