// import Ajv from 'ajv';
import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactJSONEditor from 'react-json-editor-ajrm';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { clearText, getText, toggleConfirmModal, uploadText } from '../../../actions/textLibrary';
import loader from '../../../assets/img/loader.gif';
import LoaderComponent from '../../../components/Loader/index';
// import schema from '../../../constants/jsonSchema';
import { IState } from '../../../reducers';

import './createTextLibrary.css';

interface ICreateTextLibraryProps extends IDispatchProps {
  baseProps: {
    placeholder?: object,
    viewOnly?: boolean,
    onChange?: (value: object) => void,
    onKeyPressUpdate?: boolean,
    confirmGood?: boolean,
    height?: number,
    width?: string,
    theme?: string,
    colors?: object,
    style?: object,
    reset?: boolean,
    waitAfterKeyPress?: number,
    modifyErrorText?: (customMsg: string) => string
  },
  match: any,
  editJson: any,
  getTextLibrary: any
  s3Url: string,
  getText: any,
  clearText: any,
  showReplaceModal: boolean,
  toggleConfirmModal: any,
  loading: boolean
}

interface ICreateTextLibraryState {
  json: any,
  view: boolean,
  fileName: string,
  id: any,
  error: any[],
  saveJson: boolean,
  checkJson: boolean,
  isEdit: boolean,
  title: string,
  jsonLoaded: boolean,
  isView: boolean,
  replace: boolean,
  showFileNameModal: boolean
}

class CreateTextLibrary extends React.Component<ICreateTextLibraryProps & RouteComponentProps, ICreateTextLibraryState> {

  public static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.textObj && Boolean(Object.keys(nextProps.textObj).length) && !prevState.jsonLoaded) {
      return {
        fileName: nextProps.textObj.textLibrary[0].file_name,
        json: JSON.parse(nextProps.textObj.jsonData),
        jsonLoaded: true,
        title: nextProps.textObj.textLibrary[0].file_name
      };
    }
    return null;
  }

  // public ajv = new Ajv({ $data: true, allErrors: true });

  public constructor(props: ICreateTextLibraryProps & RouteComponentProps) {
    super(props);
    this.state = {
      checkJson: true,
      error: [],
      fileName: '',
      id: '',
      isEdit: false,
      isView: false,
      json: {},
      jsonLoaded: false,
      replace: false,
      saveJson: true,
      showFileNameModal: false,
      title: '',
      view: false
    };
  }

  public clearEditor = () => {
    this.setState({
      json: {}
    });
  }

  public loadJsonFromSelectedFile = (event: any) => {
    // const isValid = this.ajv.validate(schema, JSON.parse(event.target.result));
    // if (isValid) {
    this.setState({
      json: JSON.parse(event.target.result)
    });
    // } else {
    //   this.setState({
    //     error: this.ajv.errors,
    //     json: JSON.parse(event.target.result)
    //   });
    // }
  }

  public handleSelect = (event: any) => {
    const fileReader = new FileReader();
    const name = event.target.files[0].name;
    fileReader.onload = this.loadJsonFromSelectedFile;
    fileReader.readAsText(event.target.files[0]);
    this.setState({
      fileName: name
    });
  }

  public cancel = () => {
    this.props.history.push('/textLibrary');
  }

  public onChangeJson = (value: any) => {
    if (value.jsObject !== undefined) {
      this.setState({
        checkJson: false,
        error: [],
        json: value.jsObject
      });
    } else if (value.jsObject === undefined) {
      const errors = []; // tslint:disable-next-line
      typeof value.error === 'object' ? errors.push({message: value.error.reason}) : '';
      this.setState({
        error: errors
      });
    }
  }

  // public checkJsonError = () => {
  //   const { json } = this.state;
  //   const isValid = this.ajv.validate(schema, json);
  //   if (!isValid) {
  //     this.setState({
  //       error: this.ajv.errors
  //     });
  //   }
  //   return isValid;
  // }

  public componentDidMount() {
    const { id } = this.props.match.params;
    const { pathname } = this.props.location;
    const name = pathname.split('/')[3];
    if (id) {
      this.setState({ id, isView: name === 'edit' ? false : true, view: name === 'edit' ? false : true });
      this.props.getText(id);
    }
  }

  public componentWillUnmount() {
    this.props.clearText();
  }

  public saveJson = () => {
    // const isValid = this.checkJsonError();
    const { id, json, fileName, error } = this.state;
    if (error.length === 0 && Boolean(Object.keys(json).length) && fileName !== '') {
      const stringifyData = JSON.stringify(json);
      const name = fileName.split('.')[0];
      const file = new File([stringifyData], `${name}.json`, {type: 'text/plain'});
      const formData = new FormData();
      formData.append('0', file);
      formData.append('forceUpdate', `false`);
      if (id === '') {
        this.props.uploadText(
          formData
        );
      } else {
        formData.append('id', id);
        this.props.uploadText(
          formData
        );
      }
      this.setState({ showFileNameModal: false});
    }
    if (fileName === '') {
      this.setState({ showFileNameModal: true});
    }
  }

  public toggleConfirmModal = (showConfirmModal: boolean) => {
    this.props.toggleConfirmModal(showConfirmModal);
  }

  public replaceJson = () => {
    const { json, fileName } = this.state;
    const stringifyData = JSON.stringify(json);
    const file = new File([stringifyData], fileName, {type: 'text/plain'});
    const formData = new FormData();
    formData.append('0', file);
    formData.append('forceUpdate', `true`);
    this.props.uploadText(formData);
  }

  public toggleFileModal = (showFileNameModal: boolean) => {
    this.setState({ showFileNameModal, fileName: '' });
  }

  public handleFileNameChange = (e: any) => {
    const fileName = e.target.value.split('.')[0];
    this.setState({ fileName });
  }

  public render() {
    const { baseProps } = this.props;
    const { id,
            json,
            title,
            view,
            isView,
            showFileNameModal,
            error } = this.state;
    const { loading } = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default">
              <div className="panel-heading workout-header-sec">
                <div className="workout-header">
                  <h4 onClick={this.cancel}>TEXT LIBRARY >&nbsp;</h4>
                  <span>
                  {// tslint:disable-next-line
                    id == 0 ? 'NEW TEXT LIBRARY' : title
                  } </span>
                </div>
                <div className="json-editor-container">
                  <div className="tools">
                    <div className={// tslint:disable-next-line
                      isView ? "display-none" : "upload-btn pull-right"}>
                      <input
                        accept=".json"
                        type="file"
                        multiple={true}
                        id="textLibrary"
                        onChange={(e) => this.handleSelect(e)}
                      />
                      <Button bsStyle="success">
                        <i className="glyphicon glyphicon-upload p-r-5" />
                        Upload Json
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              { loading ?
                <LoaderComponent
                  loading={loading}
                  imageSrc={loader}
                  imageStyle={{marginTop: '10%', height: '100px'}}
                />
                :
                <React.Fragment>
                  <ReactJSONEditor
                    { ...{ ...baseProps,
                      colors: { 'keys': '#000000', 'default': '#000000', 'number': '#FF4B39', 'string': '#2B942A'},
                      onChange: (value: any) => { this.onChangeJson(value); },
                      placeholder: json,
                      style: { 'body': { 'backgroundColor': 'white' }, 'outerBox': { 'height': '300px'}, 'container': { 'height': '300px'}},
                      viewOnly: view,
                      width: '100%'
                    } }
                  />
                  <div className={ isView ? 'display-none' : 'panel-footer'} >
                    <div className="float-right">
                      <div className="d-flex">
                        <Button bsStyle="danger" onClick={this.clearEditor}>Clear Editor</Button>
                        <Button bsStyle="success" onClick={this.saveJson}>
                          {// tslint:disable-next-line
                          id == 0 ? 'Save Json' : 'Update Json'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              }
            </div>
            { error && error.length > 0 &&
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4>Error: <span>{error.length}</span></h4>
                </div>
                <div className="error-logs">
                  { error.map( err => (
                    <p>{err.dataPath} <span>{err.message}</span></p>
                  ))}
                </div>
              </div>
            }
          </div>
        </div>
        <Modal show={this.props.showReplaceModal} onHide={() => this.toggleConfirmModal(false)}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Replace Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to replace this file?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="danger m-0"
              onClick={() => this.toggleConfirmModal(false)}
            >
              Skip
            </Button>
            <Button
              bsStyle="success"
              onClick={this.replaceJson}
            >
              Replace
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showFileNameModal} onHide={() => this.toggleFileModal(false)}>
          <Modal.Header closeButton={true}>
            <Modal.Title>File name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Enter the file name</label>
            <input
                type="text"
                className="form-control"
                placeholder="File Name"
                required={true}
                onChange={(e) => this.handleFileNameChange(e)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="danger m-0"
              onClick={() => this.toggleFileModal(false)}
            >
              cancel
            </Button>
            <Button
              bsStyle="success"
              onClick={this.saveJson}
            >
              save & proceed
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  loading: state.text.loading,
  s3Url: state.text.s3Url,
  showReplaceModal: state.text.showReplaceModal,
  textObj: state.text.textObj
});

const mapDispatchToProps = ({
  clearText,
  getText,
  toggleConfirmModal,
  uploadText
});

interface IStateProps {
  loading: boolean,
  s3Url: string
  textObj: any;
  showReplaceModal: boolean
}

interface IDispatchProps {
  clearText: any;
  uploadText: any;
  getText: any;
  toggleConfirmModal: any;
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateTextLibrary);






