import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import ForgotPassword from '../pages/ForgotPassword';
import Login from '../pages/Login';
import LogoLibrary from '../pages/LogoLibrary';
import MyAccount from '../pages/MyAccount';
import EditProfile from '../pages/MyAccount/editProfile';
import ResetPassword from '../pages/ResetPassword';
import SoundLibrary from '../pages/soundLibrary';
import TextLibrary from '../pages/textLibrary';
import CreateTextLibrary from '../pages/textLibrary/create';
import Users from '../pages/Users';
import CreateUser from '../pages/Users/create';
import ViewUser from '../pages/Users/view';
import WorkoutFrames from '../pages/WorkoutFrames';
import Workouts from '../pages/Workouts';
import CreateWorkout from '../pages/Workouts/create';
import WorkoutSearch from '../pages/WorkoutSearch';
export class LoggedInRoutes extends React.Component {
  public render() {
    const routes = [
      {
        component: Workouts,
        exact: true,
        path: '/workouts'
      },
      {
        component: CreateWorkout,
        exact: true,
        path: '/workouts/:workoutStageId/create'
      },
      {
        component: CreateWorkout,
        exact: true,
        path: '/workouts/:id/edit'
      },
      {
        component: WorkoutFrames,
        exact: true,
        path: '/workoutFrames'
      },
      {
        component: LogoLibrary,
        exact: true,
        path: '/logoLibrary'
      },
      {
        component: Users,
        exact: true,
        path: '/users'
      },
      {
        component: CreateUser,
        exact: true,
        path: '/users/create'
      },
      {
        component: MyAccount,
        exact: true,
        path: '/myAccount'
      },
      {
        component: EditProfile,
        exact: false,
        path: '/MyAccount/:id/editProfile'
      },
      {
        component: SoundLibrary,
        exact: true,
        path: '/soundLibrary'
      },
      {
        component: TextLibrary,
        exact: true,
        path: '/textLibrary'
      },
      {
        component: CreateTextLibrary,
        exact: true,
        path: '/textLibrary/create'
      },
      {
        component: CreateTextLibrary,
        exact: true,
        path: '/textLibrary/:id/edit'
      },
      {
        component: CreateTextLibrary,
        exact: true,
        path: '/textLibrary/:id/view'
      },
      {
        component: ViewUser,
        exact: true,
        path: '/users/:id/view'
      },
      {
        component: WorkoutSearch,
        exact: true,
        path: '/WorkoutSearch'
      }
    ];
    return (
      <Switch>
        {routes.map((route, index) => {
          return (
            <Route key={index}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          );
        })}
         <Redirect from="*" to="/workouts" />
      </Switch>
    );
  }
}

export const persistantRoutes = () => (
  <Switch>
    <Route exact={true} path="/" component={Login} />
    <Route exact={true} path="/forgotPassword" component={ForgotPassword} />
    <Route exact={true} path="/resetPassword" component={ResetPassword} />
    <Redirect from="/" to="/" />
  </Switch>
);

