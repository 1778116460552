import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { resetPassword } from '../../actions/user';
import logo from '../../assets/img/logo.jpg';
import { IState } from '../../reducers';

import './resetPassword.css';

interface IResetPasswordProps {
  classes: any;
  resetPassword: any;
}

interface IResetPasswordState {
  isVerify: boolean,
  email: string,
  newPassword: string,
  confirmPassword: string,
  isError: boolean
}

class ResetPassword extends React.Component<IResetPasswordProps & RouteComponentProps, IResetPasswordState> {

  constructor(props: IResetPasswordProps & RouteComponentProps) {
    super(props);
    this.state = {
      confirmPassword: '',
      email: '',
      isError: false,
      isVerify: false,
      newPassword: ''
    };
  }

  public componentDidMount () {
    this.setState({
      email: this.props.location.state.email
    });
  }

  public newPassword = (e: any) => {
    this.setState({
      newPassword: e.target.value
    });
  }

  public confirmPassword = (e: any) => {
    this.setState({
      confirmPassword: e.target.value
    }, () => {
      if (this.state.newPassword !== this.state.confirmPassword) {
        this.setState({
          isError: !this.state.isError
        });
      }
    });
  }

  public sendPassword = () => {
    const { newPassword, confirmPassword, email, isError } = this.state;
    if (newPassword === '' || confirmPassword === '') {
      toast.error('Fields cannot be empty');
    }
    if ((newPassword !== '' || confirmPassword !== '') && newPassword === confirmPassword && !isError) {
      this.props.resetPassword(email, newPassword);
    }
  }

  public render() {
      const { isVerify, isError } = this.state;
      return (
        <div className="container-fluid login-container">
          <div className="shadow-container">
            <div className="content">
              <div className="card center-block card-signin">
                <div className="card-body">
                  <img src={logo} className="login-logo" />
                  <h3>Reset Password</h3>
                  <p>New Password</p>
                  <form className="form-signin">
                    <div className="form-label-group">
                      <input
                        id="newPassword"
                        name="newPassword"
                        autoComplete="newPassword"
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        required={true}
                        disabled={isVerify}
                        onChange={(e) => this.newPassword(e)}
                      />
                    </div>
                  </form>
                  <div className="verify-sec">
                      <p>Confirm Password</p>
                      <form className="form-signin">
                          <div className="form-label-group">
                            <input
                              id="confirmPassword"
                              name="confirmPassword"
                              autoComplete="confirmPassword"
                              type="password"
                              className="form-control"
                              placeholder="Confirm Password"
                              required={true}
                              onChange={(e) => this.confirmPassword(e)}
                            />
                            <p className={`error-text ${isError ? 'enable-div' : 'disable-div'}`}>Password Doesn't match</p>
                          </div>
                      </form>
                  </div>
                  <div className="submit-sec">
                      <button
                          className="btn btn-primary text-uppercase submit-btn"
                          type="submit"
                          onClick={this.sendPassword}
                      >
                          Submit
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }

}

interface IStateProps {
  loading: boolean;
}

const mapStateToProps = (state: IState) => ({
  loading: state.user.loading
});


const mapDispatchToProps = ({
  resetPassword
});

interface IDispatchProps {
  resetPassword: any;
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(ResetPassword);
