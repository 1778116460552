import keyMirror from 'key-mirror';
export default keyMirror({
  BULK_WORKOUT_FRAMES_DELETE: null,
  BULK_WORKOUT_FRAMES_DELETE_FAIL: null,
  BULK_WORKOUT_FRAMES_DELETE_SUCCESS: null,
  DELETE_WORKOUT_FRAMES: null,
  DELETE_WORKOUT_FRAMES_FAIL: null,
  DELETE_WORKOUT_FRAMES_SUCCESS: null,
  LIST_WORKOUT_FRAMES: null,
  LIST_WORKOUT_FRAMES_FAIL: null,
  LIST_WORKOUT_FRAMES_SUCCESS: null,
  TOGGLE_CONFIRM_MODAL: null,
  UPLOAD_WORKOUT_FRAMES: null,
  UPLOAD_WORKOUT_FRAMES_FAIL: null,
  UPLOAD_WORKOUT_FRAMES_SUCCESS: null
});
