import Constants from './constants';

export const deleteSoundFile = (data: any) => ({
  data,
  type: Constants.DELETE_SOUND
});

export const deleteSoundFileSuccess = (response: any) => ({
  response,
  type: Constants.DELETE_SOUND_SUCCESS
});

export const deleteSoundFileFail = (error: any) => ({
  error,
  type: Constants.DELETE_SOUND_FAIL
});

export const uploadSoundFile = (data: any) => ({
  data,
  type: Constants.UPLOAD_SOUND
});

export const uploadSoundFileSuccess = (response: any) => ({
  response,
  type: Constants.UPLOAD_SOUND_SUCCESS
});

export const uploadSoundFileFail = (error: any) => ({
  error,
  type: Constants.UPLOAD_SOUND_FAIL
});

export const fetchSoundLibrary = (data: any) => ({
  data,
  type: Constants.LIST_SOUND
});

export const fetchSoundLibrarySuccess = (response: any) => ({
  response,
  type: Constants.LIST_SOUND_SUCCESS
});

export const fetchSoundLibraryFail = (error: any) => ({
  error,
  type: Constants.LIST_SOUND_FAIL
});

export const toggleConfirmModal = (status: boolean) => ({
  status,
  type: Constants.TOGGLE_CONFIRM_MODAL
});

export const bulkSoundLibraryDelete = (data: any) => ({
  data,
  type: Constants.BULK_SOUND_LIBRARY_DELETE
});

export const bulkSoundLibraryDeleteSuccess = (response: any) => ({
  response,
  type: Constants.BULK_SOUND_LIBRARY_DELETE_SUCCESS
});

export const bulkSoundLibraryDeleteFail = (error: any) => ({
  error,
  type: Constants.BULK_SOUND_LIBRARY_DELETE_FAIL
});
