import Constants from './constants';

export const loginUser = (email: string, password: string) => ({
  email,
  password,
  type: Constants.LOGIN_USER
});

export const loginUserSuccess = (response: any) => ({
  response,
  type: Constants.LOGIN_USER_SUCCESS
});

export const loginUserFail = (error: any) => ({
  error,
  type: Constants.LOGIN_USER_FAIL
});

export const logoutUser = () => ({
  type: Constants.LOGOUT_USER
});

export const logoutUserSuccess = (response: any) => ({
  response,
  type: Constants.LOGOUT_USER_SUCCESS
});

export const logoutUserFail = (error: any) => ({
  error,
  type: Constants.LOGOUT_USER_FAIL
});

export const fetchUsers = (data: any) => ({
  data,
  type: Constants.FETCH_USERS
});

export const fetchUsersSuccess = (response: any) => ({
  response,
  type: Constants.FETCH_USERS_SUCCESS
});

export const fetchUsersFail = (error: any) => ({
  error,
  type: Constants.FETCH_USERS_FAIL
});

export const getUser = (id: string) => ({
  id,
  type: Constants.LOGIN_USER
});

export const getUserSuccess = (response: any) => ({
  response,
  type: Constants.LOGIN_USER_SUCCESS
});

export const getUserFail = (error: any) => ({
  error,
  type: Constants.LOGIN_USER_FAIL
});

export const createUser = (data: any) => ({
  data,
  type: Constants.CREATE_USER
});

export const createUserSuccess = (response: any) => ({
  response,
  type: Constants.CREATE_USER_SUCCESS
});

export const createUserFail = (error: any) => ({
  error,
  type: Constants.CREATE_USER_FAIL
});

export const editUser = (id: string, address: string, firstName: string, lastName: string, email: string, phoneNo: string, newPassword: string) => ({
  address,
  email,
  firstName,
  id,
  lastName,
  newPassword,
  phoneNo,
  type: Constants.EDIT_USER
});

export const editUserSuccess = (response: any) => ({
  response,
  type: Constants.EDIT_USER_SUCCESS
});

export const editUserFail = (error: any) => ({
  error,
  type: Constants.EDIT_USER_FAIL
});

export const verifyUser = (email: string, verifyPin: any) => ({
  email,
  type: Constants.VERIFY_USER,
  verifyPin
});

export const verifyUserSuccess = (response: any) => ({
  response,
  type: Constants.VERIFY_USER_SUCCESS
});

export const verifyUserFail = (error: any) => ({
  error,
  type: Constants.VERIFY_USER_FAIL
});

export const resetPassword = (email: string, newPassword: string) => ({
  email,
  newPassword,
  type: Constants.RESET_PASSWORD
});

export const resetPasswordSuccess = (response: any) => ({
  response,
  type: Constants.RESET_PASSWORD_SUCCESS
});

export const resetPasswordFail = (error: any) => ({
  error,
  type: Constants.RESET_PASSWORD_FAIL
});

export const getUserDetail = (id: string) => ({
  id,
  type: Constants.GET_USER
});

export const getUserDetailSuccess = (response: any) => ({
  response,
  type: Constants.GET_USER_SUCCESS
});

export const getUserDetailFail = (error: any) => ({
  error,
  type: Constants.GET_USER_FAIL
});

export const updateUserDetail = (data: any) => ({
  data,
  type: Constants.UPDATE_USER
});

export const updateUserDetailSuccess = (response: any) => ({
  response,
  type: Constants.UPDATE_USER_SUCCESS
});

export const updateUserDetailFail = (error: any) => ({
  error,
  type: Constants.UPDATE_USER_FAIL
});

export const getUserByAccessToken = (accessToken: string) => ({
  accessToken,
  type: Constants.GET_USER_DETAIL
});

export const getUserByAccessTokenSuccess = (response: any) => ({
  response,
  type: Constants.GET_USER_DETAIL_SUCCESS
});

export const getUserByAccessTokenFail = (error: any) => ({
  error,
  type: Constants.GET_USER_DETAIL_FAIL
});

export const clearUser = () => ({
  type: Constants.CLEAR_USER
});

export const deleteUser = (data: any) => ({
  data,
  type: Constants.DELETE_USER
});

export const deleteUserSuccess = (response: any) => ({
  response,
  type: Constants.DELETE_USER_SUCCESS
});

export const deleteUserFail = (error: any) => ({
  error,
  type: Constants.DELETE_USER_FAIL
});
