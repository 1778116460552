import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import {
  bulkWorkoutFramesDeleteFail,
  bulkWorkoutFramesDeleteSuccess,
  deleteWorkoutFramesFail,
  deleteWorkoutFramesSuccess,
  listWorkoutFramesFail,
  listWorkoutFramesSuccess,
  uploadWorkoutFramesFail,
  uploadWorkoutFramesSuccess
} from '../actions/workoutFrames';
import { bulkDelete, deleteWorkoutFrames, listWorkoutFrames, uploadWorkoutFrames } from '../services/workoutFrames';

function* deleteWorkoutImages(action: any) {
  const { data } = action;
  try {
    const response = yield call(deleteWorkoutFrames, data.fileId);
    yield put(deleteWorkoutFramesSuccess(response.data));
    const listResponse = yield call(listWorkoutFrames, data.list);
    yield put(listWorkoutFramesSuccess(listResponse.data));
    toast.success('Workout image deleted successfully');
  } catch (error) {
    toast.error(error.message);
    yield put(deleteWorkoutFramesFail(error));
    yield put(listWorkoutFramesFail(error));
  }
}

function* uploadWorkoutImages(action: any) {
  const { data } = action;
  try {
    const response = yield call(uploadWorkoutFrames, data.upload);
    yield put(uploadWorkoutFramesSuccess({ status: false}));
    const listResponse = yield call(listWorkoutFrames, data.list);
    yield put(listWorkoutFramesSuccess(listResponse.data));
    if (response.data.status && response.data.errorFiles.length === 0 && response.data.duplicateFiles.length === 0) {
      toast.success('Images uploaded successfully');
    } else if (response.data.errorFiles.length > 0) {
      toast.error(`Failed to upload ${response.data.errorFiles.join(', ')}`);
    } else if (response.data.duplicateFiles.length > 0) {
      yield put(uploadWorkoutFramesSuccess({ status: true, duplicateFiles: response.data.duplicateFiles }));
    }
  } catch (error) {
    toast.error(error.message);
    yield put(uploadWorkoutFramesFail(error));
    yield put(listWorkoutFramesFail(error));
  }
}

function* getWorkoutFrames(action: any) {
  const { data } = action;
  try {
    const response = yield call(listWorkoutFrames, data);
    yield put(listWorkoutFramesSuccess(response.data));
  } catch (error) {
    yield put(listWorkoutFramesFail(error));
  }
}

function* bulkWorkoutFramesDelete(action: any) {
  const { data } = action;
  try {
    const response = yield call(bulkDelete, data);
    yield put(bulkWorkoutFramesDeleteSuccess(response.data));
    const listResponse = yield call(listWorkoutFrames, data.list);
    yield put(listWorkoutFramesSuccess(listResponse.data));
    toast.success('Files deleted successfully');
  } catch (error) {
    yield put(bulkWorkoutFramesDeleteFail(error));
  }
}

export { deleteWorkoutImages, uploadWorkoutImages, getWorkoutFrames, bulkWorkoutFramesDelete };
