import * as React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { clearUser, getUserDetail } from '../../../actions/user';
import logo from '../../../assets/img/boy.svg';
import loader from '../../../assets/img/loader.gif';
import LoaderComponent from '../../../components/Loader/index';
import { IState } from '../../../reducers';

interface IViewUserState {
    proPic: any;
}

interface IViewUserProps {
    loading: boolean;
    getUserDetail: any;
    user: any;
    clearUser: any;
    match: any;
}

class ViewUser extends React.PureComponent<IViewUserProps & RouteComponentProps, IViewUserState> {

  constructor(props: IViewUserProps & RouteComponentProps) {
    super(props);
    this.state = {
        proPic: ''
    };
  }

  public cancel = () => {
    this.props.history.push('/users');
  }

  public componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.props.getUserDetail(id);
    }
  }

  public componentWillUnmount() {
    this.props.clearUser();
}

  public render() {
    const { user, loading } = this.props;

    return (
        <div className="container-fluid">
        { user &&
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="panel panel-default my-acc-hdr">
                        <div className="panel-heading workout-header-sec">
                            <div className="workout-header">
                                <h4 onClick={this.cancel}>MANAGE USER >&nbsp;</h4>
                                <span>{ `${user.first_name}'s profile`}</span>
                            </div>
                        </div>
                        <div className="panel-body">
                        { loading ?
                            <LoaderComponent
                            loading={loading}
                            imageSrc={loader}
                            imageStyle={{marginTop: '10%', height: '100px'}}
                            />
                            :
                            <React.Fragment>
                                <div className="col-md-3 col-sm-4 col-xs-12 change-pic-sec">
                                    <img src={user.profile_image ? user.profile_image : logo} className="profile-pic" />
                                </div>
                                <div className="col-md-9 col-sm-8 col-xs-12 prof-desc-sec">
                                    <div className="col-md-6 prof-cnt">
                                        <h5>First Name:</h5>
                                        <p>{ user.first_name }</p>
                                    </div>
                                    <div className="col-md-6 prof-cnt">
                                        <h5>Last Name:</h5>
                                        <p>{ user.last_name }</p>
                                    </div>
                                    <div className="col-md-6 prof-cnt">
                                        <h5>Email:</h5>
                                        <p>{ user.email }</p>
                                    </div>
                                    <div className="col-md-6 prof-cnt">
                                        <h5>Phone:</h5>
                                        <p>{ user.mobile_no }</p>
                                    </div>
                                    <div className="col-md-6 prof-cnt">
                                        <h5>Address:</h5>
                                        <p>{ user.address ? user.address : 'NA' }</p>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        </div>
                    </div>
                </div>
            </div>
        }
        </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  loading: state.user.loading,
  user: state.user.user
});

const mapDispatchToProps = ({
    clearUser,
    getUserDetail
});

interface IStateProps {
  loading: boolean;
  user: any
}
interface IDispatchProps {
    clearUser: any;
    getUserDetail: any;
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLocalize
)(ViewUser);
