import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import {
  bulkLogoLibraryDeleteFail,
  bulkLogoLibraryDeleteSuccess,
  deleteLogoFail,
  deleteLogoSuccess,
  listLogoFail,
  listLogoSuccess,
  uploadLogoFail,
  uploadLogoSuccess
} from '../actions/logoLibrary';
import { bulkDelete, deleteLogo, listLogo, uploadLogo } from '../services/logoLibrary';

function* deleteLogoImages(action: any) {
  const { data } = action;
  try {
    const response = yield call(deleteLogo, data.fileId);
    yield put(deleteLogoSuccess(response.data));
    const listResponse = yield call(listLogo, data.list);
    yield put(listLogoSuccess(listResponse.data));
    toast.success('Image deleted successfully');
  } catch (error) {
    toast.error(error.message);
    yield put(deleteLogoFail(error));
    yield put(listLogoFail(error));
  }
}

function* uploadLogoImages(action: any) {
  const { data } = action;
  try {
    const response = yield call(uploadLogo, data.upload);
    yield put(uploadLogoSuccess({ status: false }));
    const listResponse = yield call(listLogo, data.list);
    yield put(listLogoSuccess(listResponse.data));
    if (response.data.status && response.data.errorFiles.length === 0 && response.data.duplicateFiles.length === 0) {
      toast.success('Images uploaded successfully');
    } else if (response.data.errorFiles.length > 0) {
      toast.error(`Failed to upload ${response.data.errorFiles.join(', ')}`);
    } else if (response.data.duplicateFiles.length > 0) {
      yield put(uploadLogoSuccess({ status: true, duplicateFiles: response.data.duplicateFiles }));
    }
  } catch (error) {
    toast.error(error.message);
    yield put(uploadLogoFail(error));
    yield put(listLogoFail(error));
  }
}

function* getLogo(action: any) {
  const { data } = action;
  try {
    const response = yield call(listLogo, data);
    yield put(listLogoSuccess(response.data));
  } catch (error) {
    yield put(listLogoFail(error));
  }
}

function* bulkLogoLibraryDelete(action: any) {
  const { data } = action;
  try {
    const response = yield call(bulkDelete, data);
    yield put(bulkLogoLibraryDeleteSuccess(response.data));
    const listResponse = yield call(listLogo, data.list);
    yield put(listLogoSuccess(listResponse.data));
    toast.success('Files deleted successfully');
  } catch (error) {
    yield put(bulkLogoLibraryDeleteFail(error));
    toast.error(error.message);
  }
}

export { deleteLogoImages, uploadLogoImages, getLogo, bulkLogoLibraryDelete };
