import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { verifyUser } from '../../actions/user';
import logo from '../../assets/img/logo.jpg';
import { IState } from '../../reducers';
import { isEmailValid } from '../../utils/commonUtil';

import './forgotPassword.css';

interface IForgotPasswordProps {
  classes: any;
  verifyUser: any;
  emailStatus: boolean,
  pinStatus: boolean
}

interface IForgotPasswordState {
  email: string,
  isVerify: boolean,
  loading: boolean,
  verifyPin: any,
  pristine: boolean,
  emailValid: boolean,
  isResend: boolean
}

class ForgotPassword extends React.Component<IForgotPasswordProps & RouteComponentProps, IForgotPasswordState> {

  constructor(props: IForgotPasswordProps & RouteComponentProps) {
    super(props);
    this.state = {
      email: '',
      emailValid: false,
      isResend: true,
      isVerify: false,
      loading: false,
      pristine: true,
      verifyPin: null
    };
  }

  public changeVerifyPin = (e: any) => {
    this.setState({
      verifyPin: e.target.value
    });
  }

  public submitEmail = () => {
    const { email, verifyPin } = this.state;
    if (this.props.emailStatus) {
      this.setState({
        isResend: !this.state.isResend
      });
    }
    this.props.verifyUser(email, verifyPin);
  }

  public resendEmail = () => {
    const { email, verifyPin } = this.state;
    this.props.verifyUser(email, verifyPin);
  }

  public cancel = () => {
    this.props.history.push(`/`);
  }

  public render() {
      const { emailStatus } = this.props;
      return (
        <div className="container-fluid login-container">
          <div className="shadow-container">
            <div className="content">
              <div className="card center-block card-signin">
                <div className="card-body">
                  <img src={logo} className="login-logo" />
                  <h3>Forgot Password</h3>
                  <p>Email</p>
                  <form className="form-forgot">
                    <div className="form-label-group">
                      <input
                        id="email"
                        name="email"
                        autoComplete="email"
                        type="email"
                        className="form-control"
                        placeholder="E-mail"
                        required={true}
                        disabled={emailStatus}
                        onChange={event => {
                          this.setState({
                            email: event.target.value,
                            emailValid: isEmailValid(event.target.value),
                            pristine: false
                          });
                        }}
                      />
                    </div>
                  </form>
                  <div className={`verify-sec ${ emailStatus ? 'enable-div' : 'disable-div' }`}>
                      <p>Verification Code</p>
                      <form className="form-forgot">
                          <div className="form-label-group">
                              <input
                              id="verify"
                              name="number"
                              autoComplete="number"
                              type="number"
                              className="form-control"
                              placeholder="Verify Code"
                              required={true}
                              onChange={(e) => this.changeVerifyPin(e)}
                              />
                          </div>
                      </form>
                  </div>
                  <div className="submit-sec">
                      { !emailStatus ?
                        <button
                         className="btn btn-primary resend-btn"
                         type="submit"
                         onClick={this.cancel}
                        >
                        Cancel
                        </button> :
                        <button
                          className="btn btn-primary resend-btn"
                          type="submit"
                          onClick={this.resendEmail}
                        >
                        Resend
                        </button>
                      }
                      <button
                          className="btn btn-primary submit-btn"
                          type="submit"
                          onClick={this.submitEmail}
                          title={emailStatus ? 'Verify' : 'Submit'}
                      >
                          { emailStatus ? 'Verify' : 'Submit' }
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }

}

interface IStateProps {
  loading: boolean;
  emailStatus: boolean;
  pinStatus: boolean;
}

const mapStateToProps = (state: IState) => ({
  emailStatus: state.user.emailStatus,
  loading: state.user.loading,
  pinStatus: state.user.pinStatus
});


const mapDispatchToProps = ({
  verifyUser
});

interface IDispatchProps {
  verifyUser: any;
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(ForgotPassword);
