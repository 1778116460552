import keyMirror from 'key-mirror';
export default keyMirror({
  BULK_WORKOUTS_DELETE: null,
  BULK_WORKOUTS_DELETE_FAIL: null,
  BULK_WORKOUTS_DELETE_SUCCESS: null,
  CLEAR_WORKOUT: null,
  CREATE_WORKOUT: null,
  CREATE_WORKOUT_FAIL: null,
  CREATE_WORKOUT_SUCCESS: null,
  DELETE_WORKOUT: null,
  DELETE_WORKOUT_FAIL: null,
  DELETE_WORKOUT_SUCCESS: null,
  EDIT_WORKOUT: null,
  EDIT_WORKOUT_FAIL: null,
  EDIT_WORKOUT_SUCCESS: null,
  FETCH_WORKOUTS: null,
  FETCH_WORKOUTS_FAIL: null,
  FETCH_WORKOUTS_SUCCESS: null,
  GET_WORKOUT: null,
  GET_WORKOUT_FAIL: null,
  GET_WORKOUT_SUCCESS: null,
  UPDATE_WORKOUT: null,
  UPDATE_WORKOUT_FAIL: null,
  UPDATE_WORKOUT_SUCCESS: null,
  SEARCH_WORKOUTS: null,
  SEARCH_WORKOUTS_FAIL: null,
  SEARCH_WORKOUTS_SUCCESS: null
});
