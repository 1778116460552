import { combineReducers } from 'redux';
import Constants from '../actions/global/constants';
import { AppProperties } from '../constants/application.properties';
import storage from '../utils/storage';

export interface IUserStatuslReducer {
  loggedIn: boolean,
  resetPassword: boolean
}

export interface IMobileApplicationReducer {
  mobileApplications: object[],
  isMobileApplicationChanged: boolean
}

const userStatusInitialState = {
  loggedIn: Boolean(storage.getItem(AppProperties.ACCESS_TOKEN_KEY)),
  resetPassword: false
};

const mobileApplicationInitailState = {
  mobileApplications: [],
  isMobileApplicationChanged: false
};

const mobileApplicationReducer = (state = mobileApplicationInitailState , action: any)  => {
  switch (action.type) {
    case Constants.GET_MOBILE_APPLICATIONS:
        return {
          ...state,
          loading: true
        };
    case Constants.GET_MOBILE_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        mobileApplications: action.response
      };
    case Constants.GET_MOBILE_APPLICATIONS_FAIL:
      return {
        ...state,
        loading: false
      };
      case Constants.MOBILE_APPLICATION_CHANGE:
        return {
          ...state,
          isMobileApplicationChanged: action.isChanged
      };
    default:
      return state;
  }
};

const userStatusReducer = (state = userStatusInitialState, action: any) => {
  // tslint:disable
  switch (action.type) {
    case Constants.SET_LOGGED_IN_STATUS:
      return {
        ...state,
        ...action.userStatus
      };
    default:
      return state;
  }
};


/**
 * Setting default status on APP init for logged in user having access token
 * to be present in local storage.
 * User will be forced to logout if invalid accessToken is present
 * which will be validated on APP init.
 */
export interface IGlobalReducer {
  userStatus: IUserStatuslReducer,
  loading: boolean,
  applications: IMobileApplicationReducer
}

export const globalReducer = combineReducers({
  applications: mobileApplicationReducer,
  userStatus: userStatusReducer
});
