const schema = {
    'properties' : {
      'exercises' : {
        'items' : {
          'properties': {
            'count': {'type': 'number'},
            'exercise': {'type': 'string'},
            'interval': {'type': 'number'}
          },
          'type': 'object'
        },
        'type': 'array'
      }
    },
    'required': ['exercises'],
    'type': 'object'
  };

export default schema;
