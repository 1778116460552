export const AppProperties = {
  ACCESS_TOKEN_KEY: 'accessToken',
  APPLICATION_ID: 'application_id',
  BASE_URL: `${process.env.REACT_APP_API_HOST}`,
  FIRST_NAME: 'firstName',
  INDEX: 'index',
  LANGUAGES: [
    { name: 'English', code: 'en' },
    { name: 'French', code: 'fr' },
    { name: 'Spanish', code: 'es' }
  ],
  LAST_NAME: 'lastName',
  ROLE_NAME: 'role',
  STATUS: ['draft', 'Ready to Publish', 'live'],
  USER_ID: 'user_id',
  USER_SESSION: 'userSession'
};
