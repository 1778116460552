import Constants from './constants';

export const fetchWorkoutStages = () => ({
  type: Constants.FETCH_WORKOUT_STAGES
});

export const fetchWorkoutStagesSuccess = (response: any) => ({
  response,
  type: Constants.FETCH_WORKOUT_STAGES_SUCCESS
});

export const fetchWorkoutStagesFail = (error: any) => ({
  error,
  type: Constants.FETCH_WORKOUT_STAGES_FAIL
});
