import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { clearUser, createUser } from '../../../actions/user';
import logo from '../../../assets/img/boy.svg';
import { IState } from '../../../reducers';

interface ICreateUserState {
    address: string;
    email: string;
    first_name: string;
    last_name: string;
    mobile_no: string;
    proPic: any;
    password: any;
    imageObj: any;
    errorPassword: boolean;
    imageChange: boolean;
}

interface ICreateUserProps {
    loading: boolean;
    createUser: any;
    clearUser: any;
}

class CreateUser extends React.PureComponent<ICreateUserProps & RouteComponentProps, ICreateUserState> {

  public state: ICreateUserState;

  constructor(props: ICreateUserProps & RouteComponentProps) {
    super(props); {
      this.state = {
        address: '',
        email: '',
        errorPassword: false,
        first_name: '',
        imageChange: false,
        imageObj: {},
        last_name: '',
        mobile_no: '',
        password: '',
        proPic: ''
      };
    }
  }

  public handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    const reader = new FileReader();
    if (file) {
        reader.readAsDataURL(file);
        reader.onload = upload => {
            const targetFile = upload.currentTarget;
            this.setState({
                imageChange: true,
                imageObj: file,
                proPic: targetFile
            });
        };
    }
  }

  public handleFieldChange = (e: any, key: any) => {
    if (key !== 'confirmPassword') {
        const data = {};
        data[key] = e.target.value;
        this.setState(data);
    } else {
        if (this.state.password !== e.target.value) {
           this.setState({ errorPassword: true});
        } else if (this.state.password === e.target.value) {
            this.setState({ errorPassword: false});
        }
    }
  }

  public handleSaveUser = (event: any) => {
    const userObj = {};
    ['address', 'email', 'first_name', 'last_name', 'mobile_no', 'password'].forEach(field => {
        if (this.state[field] !== '') {
            userObj[field] = this.state[field];
        }
    });
    const formData = new FormData();
    formData.append('file', this.state.imageObj);
    formData.append('userObj', JSON.stringify(userObj));
    this.props.createUser(formData);
  }

  public cancel = () => {
    this.props.history.push('/users');
  }

  public componentWillUnmount() {
    this.props.clearUser();
  }

  public render() {
    const { proPic, first_name, last_name, address, email, mobile_no, errorPassword} = this.state;
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="panel panel-default my-acc-hdr">
                        <div className="panel-heading workout-header-sec">
                            <div className="workout-header">
                                <h4 onClick={this.cancel}>MANAGE USER >&nbsp;</h4>
                                <span>ADD NEW USER</span>
                            </div>
                            <div className="workout-btn-actions text-right remv-padd">
                                {/* <button onClick={this.cancel} className="btn cancel-btn">Cancel</button> */}
                                <button onClick={this.handleSaveUser} className="btn btn-success">Send Invite</button>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body my-acc-body">
                        <div className="col-md-3 col-sm-3 col-xs-12 change-pic-sec">
                            <img src={proPic ? proPic : logo} className="profile-pic" />
                            <div className="change-pro-pic">
                                <input
                                    accept="image/*"
                                    id="profilePic"
                                    onChange={this.handleImageUpload}
                                    type="file"
                                />
                                <a>Upload Profile Picture</a>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-9 col-xs-12">
                            <form className="account-signin">
                                <div className="col-md-6 form-label-group">
                                    <label>First name</label>
                                    <input
                                        id="firstName"
                                        name="firstName"
                                        autoComplete="firstName"
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        required={true}
                                        value={first_name}
                                        onChange={(e) => this.handleFieldChange(e, 'first_name')}
                                    />
                                </div>
                                <div className="col-md-6 form-label-group">
                                    <label>Last Name</label>
                                    <input
                                        id="lastName"
                                        name="lastName"
                                        autoComplete="lastName"
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        required={true}
                                        value={last_name}
                                        onChange={(e) => this.handleFieldChange(e, 'last_name')}
                                    />
                                </div>
                                <div className="col-md-6 form-label-group">
                                    <label>Email Address</label>
                                    <input
                                        id="email"
                                        name="email"
                                        autoComplete="email"
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        required={true}
                                        value={email}
                                        onChange={(e) => this.handleFieldChange(e, 'email')}
                                    />
                                </div>
                                <div className="col-md-6 form-label-group">
                                    <label>Phone</label>
                                    <input
                                        id="phone"
                                        name="phone"
                                        autoComplete="phone"
                                        type="number"
                                        className="form-control"
                                        placeholder="phone"
                                        required={true}
                                        value={mobile_no}
                                        onChange={(e) => this.handleFieldChange(e, 'mobile_no')}
                                    />
                                </div>
                                <div className="col-md-12 form-label-group">
                                    <label>Address</label>
                                    <textarea
                                        className="form-control"
                                        rows={4}
                                        cols={50}
                                        value={address}
                                        onChange={(e) => this.handleFieldChange(e, 'address')}
                                    />
                                </div>
                                <div className="col-xs-12">
                                    <div className="col-xs-12 password-sec">
                                        <h4>Password</h4>
                                        <div className="col-md-6 p-l-0 form-label-group">
                                            <label>New Password</label>
                                            <input
                                                id="newPassword"
                                                name="newPassword"
                                                autoComplete="newPassword"
                                                type="password"
                                                className="form-control"
                                                placeholder="New Password"
                                                required={true}
                                                onChange={(e) => this.handleFieldChange(e, 'password')}
                                            />
                                        </div>
                                        <div className="col-md-6 p-r-0 form-label-group">
                                            <label>Confirm Password</label>
                                            <input
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                autoComplete="confirmPassword"
                                                type="password"
                                                className="form-control"
                                                placeholder="Confirm Password"
                                                required={true}
                                                onChange={(e) => this.handleFieldChange(e, 'confirmPassword')}
                                            />
                                            <p className={!errorPassword ? 'display-none' : 'error-text'}> Password doesnt match</p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  loading: state.user.loading
});

const mapDispatchToProps = ({
    clearUser,
    createUser
});

interface IStateProps {
  loading: boolean;
}


interface IDispatchProps {
    createUser: any;
    clearUser: any;
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateUser);
