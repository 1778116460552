import keyMirror from 'key-mirror';
export default keyMirror({
  BULK_DELETE: null,
  BULK_DELETE_FAIL: null,
  BULK_DELETE_SUCCESS: null,
  CLEAR_TEXT: null,
  DELETE_TEXT: null,
  DELETE_TEXT_FAIL: null,
  DELETE_TEXT_SUCCESS: null,
  FETCH_TEXT: null,
  FETCH_TEXT_FAIL: null,
  FETCH_TEXT_SUCCESS: null,
  GET_TEXT: null,
  GET_TEXT_FAIL: null,
  GET_TEXT_SUCCESS: null,
  TOGGLE_CONFIRM_MODAL: null,
  UPLOAD_TEXT: null,
  UPLOAD_TEXT_FAIL: null,
  UPLOAD_TEXT_SUCCESS: null
});
