import * as React from 'react';
import { Button, FormControl, FormGroup, Modal } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import {
  bulkLogoLibraryDelete,
  deleteLogo,
  listLogo,
  toggleConfirmModal,
  uploadLogo
} from '../../actions/logoLibrary';
import { fetchLogoStages } from '../../actions/logoStages';
import loader from '../../assets/img/loader.gif';
import LoaderComponent from '../../components/Loader/index';
import Table, { ITableState } from '../../components/Table';
import Column from '../../components/Table/Column';
import { IState } from '../../reducers';
import './logoLibrary.css';

interface ILogoLibraryState {
  deleteFileId: string,
  imageSrc: any,
  limit: any,
  listView: number,
  orderBy: string,
  orderField: string,
  pageNo: any,
  searchString: string,
  showDeleteModal: boolean,
  showImageLightBox: boolean,
  sortByOptions: any[],
  selectedRow: number,
  activeLogoStage: string,
  title: string,
  deleteIds: string[],
  showDeleteButton: boolean
}

interface ILogoLibraryProps {
  deleteLogo: any,
  duplicateFiles: any,
  imageUrl: any,
  listLogo: any,
  uploadLogo: any,
  logos: any,
  totalCount: any,
  loading: boolean,
  fetchLogoStages: any,
  logoStages: any[],
  toggleConfirmModal: any,
  showReplaceModal: any
  bulkLogoLibraryDelete: any
}

class LogoLibrary extends React.PureComponent<ILogoLibraryProps & RouteComponentProps, ILogoLibraryState> {

  public static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.logoStages && nextProps.logoStages.length > 0) {
      // tslint:disable-next-line
      if (prevState.title == 0) {
        return {
          activeWorkoutStage: nextProps.logoStages[0].id,
          selectedRow: 0,
          title: nextProps.logoStages[0].name
        };
      }
    }
    return null;
  }

  public state: ILogoLibraryState;

  public columns: Column[];

  public files: any[];

  constructor(props: ILogoLibraryProps & RouteComponentProps) {
    super(props);
    this.columns = [
      (new Column()).withKey('file_name').withLabel('FILE NAME').withCellFormatter((cell: any, row: any) => (
        <div className="center">
          <span className="file-name" title={row.file_name.split('.')[0]}>
            {row.file_name}
          </span>
        </div>
      )),
      (new Column()).withKey('size').withLabel('SIZE').withCellFormatter((cell: any, row: any) => (
        <div className="center">
          {row.size} KB
        </div>
      )),
      (new Column()).withKey('created_at').withLabel('DATE UPLOADED').withCellFormatter((cell: any, row: any) => (
        <div className="center">
          {this.formatDate(row.created_at)}
        </div>
      )),
      (new Column()).withKey('action').withLabel('ACTIONS').withCellFormatter((cell: any, row: any) => (
        <div className={this.state.showDeleteButton ? 'display-none' : ''}>
          <i
          className="glyphicon glyphicon-eye-open view-icon"
          onClick={() => this.toggleImageModal(true, row.file_name)}
          />
          <i
            className="glyphicon glyphicon-trash delete-icon"
            onClick={() => this.toggleDeleteModal(true, row.id)}
            title="Delete"
          />
        </div>
      ))
    ],
    this.state = {
      activeLogoStage: '',
      deleteFileId: '',
      deleteIds: [],
      imageSrc: null,
      limit: 50,
      listView: 1,
      orderBy: 'desc',
      orderField: 'created_at',
      pageNo: 1,
      searchString: '',
      selectedRow: 0,
      showDeleteButton: false,
      showDeleteModal: false,
      showImageLightBox: false,
      sortByOptions: [
        'Recently Uploaded', 'File Name'
      ],
      title: ''
    };
  }

  public formatDate = (createdAt: string) => {
    const date: any = new Date(createdAt);
    const formattedDate = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const formattedMonth = date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth();
    return `${formattedDate}-${formattedMonth}-${date.getFullYear()}`;
  }

  public componentDidMount() {
    // this.props.fetchLogoStages();
    this.fetchLogo();
  }

  public handleListView = (listId: number) => {
    this.setState({ listView: listId });
  }

  public handleFileUpload = (event: any) => {
    const { pageNo, limit, orderBy, orderField, searchString } = this.state;
    const formData = new FormData();
    this.files = event.target.files;
    const fileName: string[] = [];
    if (this.files && this.files.length > 0) {
      Object.keys(this.files).forEach((fileId: any) => {
        const currentFile = this.files[fileId];
        if (currentFile.size < 100000) {
          formData.append(fileId, currentFile);
        } else {
          fileName.push(currentFile.name);
        }
      });
    }
    formData.append('forceUpdate', `false`);
    if (fileName.length > 0) {
      toast.error('Logo size should not be greater than 100kb');
    } else {
      this.props.uploadLogo({
        'list': {
          'limit': limit,
          'orderBy': orderBy,
          'orderField': orderField,
          'pageNo': pageNo,
          'searchValue': searchString
        },
        'upload': formData
      });
    }
  }

  public handleOnSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchStr = event.target.value;
    this.setState({
      pageNo: 1,
      searchString: searchStr
    }, () => {
      if (searchStr.length > 2 || searchStr.length === 0) {
        this.fetchLogo();
      }
    });
  }

  public handleOnDelete = (fileId: string) => {
    if (fileId) {
      const { pageNo, limit, searchString, orderBy, orderField } = this.state;
      this.setState({
        showDeleteModal: false
      });
      this.props.deleteLogo({
        'fileId': fileId,
        'list': {
          'limit': limit,
          'orderBy': orderBy,
          'orderField': orderField,
          'pageNo': pageNo,
          'searchValue': searchString
        }
      });
    }
  }

  public handleSortBy = (event: any) => {
    const index = event.target.value;
    let orderField = '';
    if (index === '0') {
      orderField = 'created_at';
    } else if (index === '1') {
      orderField = 'file_name';
    }
    this.setState({
      orderField
    }, () => {
      this.fetchLogo();
    });
  }

  public handleOrderBy = () => {
    const { orderBy } = this.state;
    let newOrderBy = '';
    if (orderBy === 'desc') {
      newOrderBy = 'asc';
    } else if (orderBy === 'asc') {
      newOrderBy = 'desc';
    }
    this.setState({
      'orderBy': newOrderBy
    }, () => {
      this.fetchLogo();
    });
  }

  public handlePagination = (paginate: any) => {
    const { pageNo, limit } = this.state;
    const { totalCount } = this.props;
    const maxPage = Math.ceil(totalCount / limit);
    let newPageNo = 1;
    if (paginate === 'previous') {
      newPageNo = pageNo !== 1 ? (pageNo - 1) : 1;
    } else if (paginate === 'next') {
      newPageNo = pageNo < maxPage ? (pageNo + 1) : maxPage;
    } else if (typeof paginate === 'number') {
      newPageNo = paginate;
    }
    this.setState({
      pageNo: newPageNo
    }, () => {
      this.fetchLogo();
    });
  }

  public getPageNumber = () => {
    const { limit, pageNo } = this.state;
    const { totalCount } = this.props;
    let pageNos = [];
    if (totalCount > 0) {
      const maxPage = Math.ceil(totalCount / limit);
      if (maxPage === 1) {
        pageNos = [1];
      } else if (maxPage === 2) {
        pageNos = [1, 2];
      } else if ((pageNo < maxPage) && (pageNo > 1)) {
        pageNos = [pageNo - 1, pageNo, pageNo + 1];
      } else if (maxPage === pageNo) {
        pageNos = [pageNo - 2, pageNo - 1, pageNo];
      }
    }
    return pageNos;
  }

  public toggleDeleteModal = (showDeleteModal: boolean, deleteFileId: string) => {
    this.setState({
      deleteFileId,
      showDeleteModal
    });
  }

  public toggleImageModal = (showImageLightBox: boolean, fileName: string) => {
    const { imageUrl } = this.props;
    this.setState({
      imageSrc: `${imageUrl}${fileName}`,
      showImageLightBox
    });
  }

  public fetchLogo = () => {
    const { pageNo, limit, searchString, orderBy, orderField } = this.state;
    this.props.listLogo({
      'limit': limit,
      'orderBy': orderBy,
      'orderField': orderField,
      'pageNo': pageNo,
      'searchValue': searchString
    });
  }

  public onRowClick = (e: any, index: number) => {
    // this.props.listLogo(1, 10, 'all', '', e.currentTarget.id);
    this.setState({
      activeLogoStage: e.currentTarget.id,
      searchString: '',
      selectedRow: index,
      title: e.currentTarget.title
    });
  }

  public toggleConfirmModal = (showConfirmModal: boolean) => {
    this.props.toggleConfirmModal(showConfirmModal);
  }

  public replaceImages = () => {
    const { pageNo, limit, orderBy, orderField, searchString } = this.state;
    const formData = new FormData();
    if (this.files && this.files.length > 0) {
      Object.keys(this.files).forEach((fileId: any) => {
        formData.append(fileId, this.files[fileId]);
      });
    }
    formData.append('forceUpdate', `true`);
    this.props.uploadLogo({
      'list': {
        'limit': limit,
        'orderBy': orderBy,
        'orderField': orderField,
        'pageNo': pageNo,
        'searchValue': searchString
      },
      'upload': formData
    });
  }

  public bulkDelete = () => {
    const { limit, searchString, orderBy, orderField, deleteIds } = this.state;
    const list = {
      'limit': limit,
      'orderBy': orderBy,
      'orderField': orderField,
      'pageNo': 1,
      'searchValue': searchString
    };
    if (deleteIds.length > 0) {
      this.props.bulkLogoLibraryDelete({deleteIds, list});
      this.setState({showDeleteButton: false, deleteIds: []});
    }
  }

  public handleOnSelect = (row: any, isSelect: any) => {
    const array = this.state.deleteIds;
    if (isSelect) {
      this.setState({ showDeleteButton: true, deleteIds: [...this.state.deleteIds, row.id] });
    } else if (!isSelect) {
      const index =  array.map((id: string) => id).indexOf(row.id);
      array.splice(index, 1);
      this.setState({ showDeleteButton: array.length === 0 ? false : true, deleteIds: array });
    }
  }

  public handleOnSelectAll = (isSelect: any, rows: any) => {
    const array: any = [];
    if (isSelect) {
      rows.map((row: any) => {
        array.push(row.id);
      });
      this.setState({ showDeleteButton: true, deleteIds: array });
    } else if (!isSelect) {
      this.setState({ showDeleteButton: false, deleteIds: array });
    }
  }

  public render() {
    const {
      listView,
      limit,
      pageNo,
      searchString,
      sortByOptions,
      orderBy,
      showDeleteModal,
      // selectedRow,
      showImageLightBox,
      imageSrc,
      deleteFileId,
      deleteIds,
      showDeleteButton
      // title
    } = this.state;
    const { logos, totalCount, loading, imageUrl, duplicateFiles } = this.props;
    const maxPage = Math.ceil(totalCount / limit);
    const pageNos = this.getPageNumber();
    return (
      <div className="container-fluid">
        <div className="row">
          {/* TODO: If design changes will implement this. */}
          {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <div className="panel panel-default stages-table">
              <div className="panel-heading">
                <h4 className="font-medium"><b>LOGO LIBRARY</b></h4>
              </div>
              <div className="col-md-12 col-sm-12 workout-table remv-padd">
                <div className="col-xs-12 remv-padd table-content">
                  <div className="col-xs-12 table-header remv-padd">
                    <div className="col-xs-6 remv-padd">
                      <h5>STAGE NAME</h5>
                    </div>
                    <div className="col-xs-6 remv-padd">
                      <h5 className="text-center remv-padd">NO. OF FILES</h5>
                    </div>
                  </div>
                  { logoStages && logoStages.length > 0 ?
                    logoStages.map((data: any, stageIndex: number) => (
                      <div
                        className={`col-xs-12 table-row remv-padd ${selectedRow === stageIndex ? 'active' : '' }`}
                        title={data.name}
                        id={data.id}
                        onClick={(e) => this.onRowClick(e, stageIndex)}
                      >
                        <div className="col-xs-6 remv-padd">
                          <p title={data.name} id={data.id}>{data.name}</p>
                        </div>
                        <div className="col-xs-6 remv-padd text-center">
                          <p className="cnt-value" id={data.id}>{data.name}</p>
                        </div>
                      </div>
                    )) : 'No data'
                  }
                </div>
              </div>
            </div>
          </div> */}

          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="panel panel-default">
              <div className="panel-heading frames-heading">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-3 col-xs-12 text-uppercase">
                    <h4><b>LOGO LIBRARY</b></h4>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-9 col-xs-12">
                    <div className="upload-btn pull-right">
                        {showDeleteButton ?
                          <Button bsStyle="danger m-0" onClick={() => this.bulkDelete()}>
                            Delete
                          </Button> :
                          <div>
                            <input
                              accept="image/*"
                              type="file"
                              multiple={true}
                              id="workoutFrames"
                              onChange={(e) => this.handleFileUpload(e)}
                            />
                            <Button bsStyle="success">
                              <i className="glyphicon glyphicon-upload p-r-5" />
                              Upload Image
                            </Button>
                          </div>
                        }
                    </div>
                    <div className="search-box pull-right">
                      <span className="icon-search">
                        <i className="glyphicon glyphicon-search" />
                      </span>
                      <input
                        placeholder="Search Logo"
                        type="text"
                        value={searchString}
                        onChange={(e) => this.handleOnSearch(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-heading frames-heading">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-3 col-xs-12">
                    <h4><b>{totalCount} Files</b></h4>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-9 col-xs-12">
                    <div className="pull-right list-view">
                      <i
                        className={listView === 0 ?
                          'glyphicon glyphicon-th-list m-r-5 active' :
                          'glyphicon glyphicon-th-list m-r-5'
                        }
                        onClick={() => this.handleListView(0)}
                      />
                      <i
                        className={listView === 1 ?
                          'glyphicon glyphicon-th active' :
                          'glyphicon glyphicon-th'
                        }
                        onClick={() => this.handleListView(1)}
                      />
                    </div>
                    <div className="pull-right list-view">
                      <i
                        className={orderBy === 'desc' ? 'glyphicon glyphicon-sort-by-alphabet-alt m-r-5' : 'glyphicon glyphicon glyphicon-sort-by-alphabet m-r-5'}
                        title={orderBy}
                        onClick={this.handleOrderBy}
                      />
                    </div>
                    <div className="sort-by pull-right">
                      <form>
                        <FormGroup controlId="formControlsSelect">
                          <FormControl componentClass="select" placeholder="select" onChange={this.handleSortBy}>
                            {sortByOptions && sortByOptions.map((sortBy, index) => (
                              <option key={index} value={index}>{sortBy}</option>
                            ))}
                          </FormControl>
                        </FormGroup>
                      </form>
                    </div>
                    <span className="sort-by-label pull-right">Sort By</span>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
              {/* { loading ?
                <LoaderComponent
                  loading={loading}
                  imageSrc={loader}
                  imageStyle={{marginTop: '10%', height: '100px'}}
                />
                : */}
                { listView === 1 ?
                  <div className="row">
                    {logos && logos.map((logo: any, index: string) => (
                      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 m-t-b-10" key={index}>
                        <div className="thumbnail">
                          <div className="img-border" onClick={() => this.toggleImageModal(true, logo.file_name)}>
                          { loading ?
                              <img
                              src={loader}
                              alt="Yoga"
                              width="110"
                              height="110"
                            /> :
                            <img
                              src={`${imageUrl}thumbnail/${logo.file_name}`}
                              alt="Yoga"
                              width="140"
                              height="110"
                            />
                          }
                          </div>
                          <div className="caption">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <span className="file-name logo-file-name" title={logo.file_name}>
                                  {logo.file_name}
                                </span>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <i
                                  className="pull-right glyphicon glyphicon-trash del-icon"
                                  onClick={() => this.toggleDeleteModal(true, logo.id)}
                                />
                              </div>
                              <div className="col-md-12">
                                <span className="file-size">{logo.size} KB</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div> :
                  loading ?
                    <LoaderComponent
                      loading={loading}
                      imageSrc={loader}
                      imageStyle={{marginTop: '10%', height: '100px'}}
                    />
                    :
                    <div className="row">
                      <Table
                        keyField="id"
                        data={logos}
                        columns={this.columns}
                        loading={loading}
                        length={limit}
                        currentPage={pageNo}
                        total={totalCount}
                        onUpdate={(nextState: ITableState) => {
                          const { page, sizePerPage } = nextState;
                          this.setState({
                            limit: sizePerPage,
                            pageNo: page
                          });
                        }}
                        selectable={true}
                        selectRow={{
                          onSelect: this.handleOnSelect,
                          onSelectAll: this.handleOnSelectAll,
                          selected: deleteIds
                        }}
                      />
                    </div>
                }
                { !loading && totalCount > 0 &&
                  <div className="row">
                    <div className="frames-pagination">
                      <nav aria-label="Page navigation example">
                        <ul className="pagination">
                          <li
                            className={pageNo === 1 ? 'page-item disabled' : 'page-item'}
                            onClick={() => this.handlePagination('previous')}
                          >
                            <a className="page-link" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                              <span className="sr-only">Previous</span>
                            </a>
                          </li>
                          {pageNos && pageNos.map((page: any, index: any) => (
                            <li
                              key={index}
                              onClick={() => this.handlePagination(page)}
                              className={pageNo === page ? 'page-item active' : 'page-item'}
                            >
                              <a className="page-link">{page}</a>
                            </li>
                          ))}
                          <li
                            className={pageNo === maxPage ? 'page-item disabled' : 'page-item'}
                            onClick={() => this.handlePagination('next')}
                          >
                            <a className="page-link" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                              <span className="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <Modal show={showDeleteModal} onHide={() => this.toggleDeleteModal(false, deleteFileId)}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to delete this file?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="danger m-0"
              onClick={() => this.toggleDeleteModal(false, deleteFileId)}
            >
              Close
            </Button>
            <Button
              bsStyle="success"
              onClick={() => this.handleOnDelete(deleteFileId)}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.props.showReplaceModal} onHide={() => this.toggleConfirmModal(false)}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Replace Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {duplicateFiles && duplicateFiles.length &&
                `Are you sure you want to replace ${duplicateFiles.join(', ')}?`
              }
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="danger m-0"
              onClick={() => this.toggleConfirmModal(false)}
            >
              Skip
            </Button>
            <Button
              bsStyle="success"
              onClick={this.replaceImages}
            >
              Replace
            </Button>
          </Modal.Footer>
        </Modal>
        {showImageLightBox && (
          <Lightbox
            mainSrc={imageSrc}
            onCloseRequest={() => this.toggleImageModal(false, '')}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  duplicateFiles: state.logo.duplicateFiles,
  imageUpload: state.logo.imageUpload,
  imageUrl: state.logo.imageUrl,
  loading: state.logo.loading,
  logoStages: state.logoStages.logoStages,
  logos: state.logo.logos,
  showReplaceModal: state.logo.showReplaceModal,
  totalCount: state.logo.totalCount
});

const mapDispatchToProps = ({
  bulkLogoLibraryDelete,
  deleteLogo,
  fetchLogoStages,
  listLogo,
  toggleConfirmModal,
  uploadLogo
});

interface IStateProps {
  duplicateFiles: any;
  imageUpload: any;
  imageUrl: any,
  logos: any[];
  loading: boolean;
  totalCount: any;
  logoStages: any[];
  showReplaceModal: any;
}

interface IDispatchProps {
  deleteLogo: any,
  fetchLogoStages: any,
  listLogo: any,
  uploadLogo: any,
  toggleConfirmModal: any,
  bulkLogoLibraryDelete: any
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLocalize
)(LogoLibrary);
