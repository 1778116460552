import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { compose } from 'redux';
import { logoutUser } from '../../actions/user';
import { IState } from '../../reducers';

import './SideBar.css';

interface ISideBarProps extends IDispatchProps {
  classes: any;
  user: any
}
class SideBar extends React.Component<ISideBarProps> {

  constructor(props: ISideBarProps) {
    super(props);
  }

  public render() {
    const { user } = this.props;
    return (
      <div key="sidebar" className="sidebar">
        <NavLink
          exact={false}
          to="/workouts"
          activeClassName="active"
          className="nav-link">
          Workouts
        </NavLink>
        <NavLink
          exact={false}
          to="/WorkoutSearch"
          activeClassName="active"
          className="nav-link">
          Workout Search
        </NavLink>
        <NavLink
          exact={true}
          to="/workoutFrames"
          activeClassName="active"
          className="nav-link">
          Workout Frames
        </NavLink>
        <NavLink
          exact={false}
          to="/textLibrary"
          activeClassName="active"
          className="nav-link">
          Text Library
        </NavLink>
        <NavLink
          exact={true}
          to="/soundLibrary"
          activeClassName="active"
          className="nav-link">
          Sound Library
        </NavLink>
        <NavLink
          exact={true}
          to="/logoLibrary"
          activeClassName="active"
          className="nav-link">
          Logo Library
        </NavLink>
        { user && user.user_role && user.user_role.name === 'SUPER_ADMIN' ? <NavLink
          exact={false}
          to="/users"
          activeClassName="active"
          className="nav-link">
          Manage Users
        </NavLink> : ''}
        <NavLink
          exact={false}
          to="/myAccount"
          activeClassName="active"
          className="nav-link">
          My Account
        </NavLink>
        <NavLink
          exact={true}
          to="/"
          onClick={this.logout}
          className="nav-link">
          Logout
        </NavLink>
      </div>
    );
  }

  private logout = () => {
    this.props.logoutUser();
  }
}

interface IMapStateProps {
  loading: boolean;
  user: any;
}

const mapStateToProps = (state: IState) => ({
  loading: state.user.loading,
  user: state.user.userObj
});

interface IDispatchProps {
  logoutUser(): void;
}

const mapDispatchToProps = (dispatch: any) => ({
  logoutUser: () => dispatch(logoutUser())
});

export default compose(
  withRouter,
  connect<IMapStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(SideBar);
