import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { AppProperties } from 'src/constants/application.properties';
import storage from 'src/utils/storage';
import { updateLoggedInStatus } from '../actions/global';
import {
  createUserFail,
  createUserSuccess,
  deleteUserFail,
  deleteUserSuccess,
  fetchUsersFail,
  fetchUsersSuccess,
  getUserByAccessTokenFail,
  getUserByAccessTokenSuccess,
  getUserDetailFail,
  getUserDetailSuccess,
  loginUserFail,
  loginUserSuccess,
  logoutUserFail,
  logoutUserSuccess,
  resetPasswordFail,
  resetPasswordSuccess,
  updateUserDetailFail,
  updateUserDetailSuccess,
  verifyUserFail,
  verifyUserSuccess
} from '../actions/user';
import {
  getAccessToken,
  persistAuthToken,
  removeAuthToken,
  setUser,
  updateUserSession
} from '../global/interceptors';
import {
  create,
  emailVerify,
  get,
  getUserByAccessToken,
  loginUser,
  logoutUser,
  pinVerify,
  remove,
  reset,
  update,
  view
} from '../services/user';

function* onLoginUser(action: any) {
  const { email, password } = action;
  try {
    const response = yield call(loginUser, email, password);
    persistAuthToken(response.data.accessToken.id);
    setUser(response.data.user);
    yield put(updateLoggedInStatus({ loggedIn: Boolean(response) }));
    yield put(loginUserSuccess(response.data));
    updateUserSession(true);
    yield put(push('/workouts'));
  } catch (error) {
    toast.error(error.message);
    yield put(loginUserFail(error));
  }
}

function* onLogoutUser() {
  try {
    const data = yield call(logoutUser, getAccessToken());
    updateUserSession(false);
    removeAuthToken();
    yield put(updateLoggedInStatus({ loggedIn: false }));
    yield put(logoutUserSuccess(data));
  } catch (error) {
    yield put(logoutUserFail(error));
  }
}

function* fetchUsers(action: any) {
  const { data } = action;
  try {
    const response = yield call(view, data);
    yield put(fetchUsersSuccess(response.data));
  } catch (error) {
    yield put(fetchUsersFail(error));
  }
}

function* verifyUser(action: any) {
  const { email, verifyPin } = action;
  let a = null;
  let response;
  try {
    if (verifyPin) {
      a = parseInt(verifyPin, 10);
      response = yield call(pinVerify, email, a);
      if (response.data.status) {
        yield put(verifyUserSuccess({email: false, pin: response.data.status}));
        yield put(push({ pathname: '/resetPassword', state: { email }}));
        toast.success(response.data.message);
      } else {
        yield put(verifyUserSuccess({email: true, pin: response.data.status}));
        toast.error(response.data.message);
      }
    } else {
      response = yield call(emailVerify, email);
      yield put(verifyUserSuccess({email: response.data.status, pin: false}));
    }
  } catch (error) {
    toast.error(error.message);
    yield put(verifyUserFail(error));
  }
}

function* resetPassword(action: any) {
  const { email, newPassword } = action;
  try {
    const response = yield call(reset, email, newPassword);
    yield put(resetPasswordSuccess(response));
    toast.success('Password changed successfully');
    yield put(push('/'));
  } catch (error) {
    toast.error(error.message);
    yield put(resetPasswordFail(error));
  }
}

function* getUser(action: any) {
  const { id } = action;
  try {
    const response = yield call(get, id);
    yield put(getUserDetailSuccess(response.data));
  } catch (error) {
    toast.error(error.message);
    yield put(getUserDetailFail(error));
  }
}

function* updateUser(action: any) {
  const { data } = action;
  try {
    const response = yield call(update, data);
    if (response.data.statusCode === 205) {
      updateUserSession(false);
      removeAuthToken();
      yield put(push({ pathname: '/'}));
      yield put(updateLoggedInStatus({ loggedIn: false }));
      toast.success('Password has been successfully changed.');
    } else {
      yield put(updateUserDetailSuccess(response.data));
      const tokenVerifyResponse = yield call(getUserByAccessToken,
        storage.getItem(AppProperties.ACCESS_TOKEN_KEY));
      yield put(getUserByAccessTokenSuccess(tokenVerifyResponse.data));
      yield put(push({ pathname: '/myAccount'}));
      toast.success('Profile updated successfully');
    }
  } catch (error) {
    yield put(updateUserDetailFail(error));
    toast.error(error.message);
  }
}

function* createUser(action: any) {
  const { data } = action;
  try {
    const response = yield call(create, data);
    yield put(createUserSuccess(response.data));
    yield put(push('/users'));
    toast.success('User created successfully');
  } catch (error) {
    toast.error(error.message);
    yield put(createUserFail(error));
  }
}

function* getUserDetail(action: any) {
  const { accessToken } = action;
  try {
    const response = yield call(getUserByAccessToken, accessToken);
    yield put(getUserByAccessTokenSuccess(response.data));
  } catch (error) {
    yield put(getUserByAccessTokenFail(error));
  }
}

function* updateUserStatus(action: any) {
  const { data } = action;
  try {
    const response = yield call(remove, data);
    yield put(deleteUserSuccess(response.data));
    const users = yield call(view, data.list);
    yield put(fetchUsersSuccess(users.data));
    toast.success('User deleted successfully');
  } catch (error) {
    toast.error(error.message);
    yield put(deleteUserFail(error));
  }
}

export {
  fetchUsers,
  onLoginUser,
  onLogoutUser,
  verifyUser,
  resetPassword,
  getUser,
  updateUser,
  createUser,
  getUserDetail,
  updateUserStatus
};
