import { call, put } from 'redux-saga/effects';
import {
  fetchLogoStagesFail,
  fetchLogoStagesSuccess
} from '../actions/logoStages';
import { view } from '../services/logoStages';

function* getLogoStages() {
  try {
    const response = yield call(view);
    yield put(fetchLogoStagesSuccess(response.data));
  } catch (error) {
    yield put(fetchLogoStagesFail(error));
  }
}

export { getLogoStages };
