import * as React from 'react';

import './loader.css';

interface ILoaderProps {
  imageSrc: string,
  imageStyle: any,
  loading: boolean
}

export default class Loader extends React.Component<ILoaderProps> {
    constructor(props: ILoaderProps) {
        super(props);
    }

    public render() {
        const {imageSrc, imageStyle, loading} = this.props;
        return (
            <div className={loading ? 'loader-main' : 'display-none'}
            >
                <img style={imageStyle} src={imageSrc} />
            </div >
        );
    }
}
