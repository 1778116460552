import axios from 'axios';

export const uploadLogo = (data: any) =>
  axios({
    data,
    method: 'post',
    url: '/api/LogoLibraries/upload'
  });

export const listLogo = (data: any) =>
  axios({
    data,
    method: 'post',
    url: '/api/LogoLibraries/list'
  });

export const deleteLogo = (fileId: string) =>
  axios({
    method: 'delete',
    url: `/api/LogoLibraries/${fileId}`
  });

export const bulkDelete = (data: any) =>
  axios({
    data: data.deleteIds,
    method: 'delete',
    url: '/api/LogoLibraries/bulk'
  });
