import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import {
  bulkSoundLibraryDeleteFail,
  bulkSoundLibraryDeleteSuccess,
  deleteSoundFileFail,
  deleteSoundFileSuccess,
  fetchSoundLibraryFail,
  fetchSoundLibrarySuccess,
  uploadSoundFileFail,
  uploadSoundFileSuccess
} from '../actions/soundLibrary';
import { bulkDelete, remove, upload, view } from '../services/soundLibrary';

function* deleteSound(action: any) {
  const { data } = action;
  try {
    const response = yield call(remove, data.fileId);
    yield put(deleteSoundFileSuccess(response.data));
    const listResponse = yield call(view, data.list);
    yield put(fetchSoundLibrarySuccess(listResponse.data));
    toast.success('Sound file deleted successfully');
  } catch (error) {
    yield put(deleteSoundFileFail(error));
    yield put(fetchSoundLibraryFail(error));
  }
}

function* uploadSound(action: any) {
  const { data } = action;
  try {
    const response = yield call(upload, data.upload);
    yield put(uploadSoundFileSuccess({ status: false }));
    const listResponse = yield call(view, data.list);
    yield put(fetchSoundLibrarySuccess(listResponse.data));
    if (response.data.status && response.data.errorFiles.length === 0 && response.data.duplicateFiles.length === 0) {
      toast.success('Audio uploaded successfully');
    } else if (response.data.errorFiles.length > 0) {
      toast.error(`Failed to upload ${response.data.errorFiles.join(', ')}`);
    } else if (response.data.duplicateFiles.length > 0) {
      yield put(uploadSoundFileSuccess({ status: true, duplicateFiles: response.data.duplicateFiles }));
    }
  } catch (error) {
    yield put(uploadSoundFileFail(error));
    yield put(fetchSoundLibraryFail(error));
  }
}

function* getSoundLibrary(action: any) {
  const { data } = action;
  try {
    const response = yield call(view, data);
    yield put(fetchSoundLibrarySuccess(response.data));
  } catch (error) {
    yield put(fetchSoundLibraryFail(error));
  }
}

function* bulkSoundLibraryDelete(action: any) {
  const { data } = action;
  try {
    const response = yield call(bulkDelete, data);
    yield put(bulkSoundLibraryDeleteSuccess(response.data));
    const listResponse = yield call(view, data.list);
    yield put(fetchSoundLibrarySuccess(listResponse.data));
    toast.success('Files deleted successfully');
  } catch (error) {
    yield put(bulkSoundLibraryDeleteFail(error));
    toast.error(error.message);
  }
}

export { deleteSound, uploadSound, getSoundLibrary, bulkSoundLibraryDelete };
