import * as React from 'react';
import { Button, FormControl, FormGroup, Modal } from 'react-bootstrap';
import 'react-image-lightbox/style.css';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import {
  bulkSoundLibraryDelete,
  deleteSoundFile,
  fetchSoundLibrary,
  toggleConfirmModal,
  uploadSoundFile
} from '../../actions/soundLibrary';
import loader from '../../assets/img/loader.gif';
import LoaderComponent from '../../components/Loader/index';
import Column from '../../components/Table/Column';
import { IState } from '../../reducers';
import './soundLibrary.css';

interface ISoundLibraryState {
  audioId: string,
  deleteFileId: string,
  limit: any,
  orderBy: string,
  orderField: string,
  pageNo: any,
  searchString: string,
  soundType: string,
  showAddModal: boolean,
  showDeleteModal: boolean,
  sortByOptions: any[],
  soundTypeOptions: any[],
  track: any,
  deleteIds: string[],
  showDeleteButton: boolean,
  isFile: boolean,
}

interface ISoundLibraryProps {
  duplicateFiles: any,
  deleteSoundFile: any,
  fetchSoundLibrary: any,
  uploadSoundFile: any,
  soundList: any,
  totalCount: any,
  loading: boolean,
  soundUrl: string,
  instructionUrl: string
  toggleConfirmModal: any,
  showReplaceModal: any,
  bulkSoundLibraryDelete: any
}

class SoundLibrary extends React.PureComponent<ISoundLibraryProps & RouteComponentProps, ISoundLibraryState> {

  public state: ISoundLibraryState;

  public columns: Column[];

  public files: any[];

  constructor(props: ISoundLibraryProps & RouteComponentProps) {
    super(props);
    this.state = {
      audioId: '',
      deleteFileId: '',
      deleteIds: [],
      limit: 10,
      orderBy: 'desc',
      orderField: 'created_at',
      pageNo: 1,
      searchString: '',
      soundType: 'Sound',
      showAddModal: false,
      showDeleteButton: false,
      showDeleteModal: false,
      sortByOptions: [
        'Recently Uploaded', 'File Name'
      ],
      soundTypeOptions: ['Sound', 'Instruction'],
      track: null,
      isFile: false
    };
  }

  public componentDidMount = () => {
    this.list();
  }

  public playAudio = (id: any, stageIndex: string) => {
    this.setState({
      audioId: id,
      track: this.state.track === stageIndex ? null : stageIndex
    });
    const audioPlayer: any = document.getElementById(id);
    if (this.state.audioId !== '') {
      const audioTrack: any = document.getElementById(this.state.audioId);
      audioTrack.pause();
      audioTrack.currentTime = 0;
    }
    if (this.state.track === stageIndex) {
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
    } else {
      audioPlayer.play();
    }
  }

  public endAudio = (id: any) => {
    this.setState({
      track: null
    });
    const audioTrack: any = document.getElementById(id);
    audioTrack.pause();
    audioTrack.currentTime = 0;
  }

  public handleFileUpload = (event: any) => {
    this.files = event.target.files;
    const fileName: string[] = [];
    if (this.files && this.files.length > 0) {
      Object.keys(this.files).forEach((fileId: any) => {
        const currentFile = this.files[fileId];
        if (currentFile.size > 400000) {
          fileName.push(currentFile.name);
        }
      });
      if (fileName.length > 0) {
        toast.error('Sound size should not be greater than 400kb');
      } else {
        this.setState({
          isFile: true
        });
      }
    }
  }

  public handleAddSound = () => {
    const { pageNo, limit, orderBy, orderField, searchString, soundType } = this.state;
    const formData = new FormData();
    if (this.files && this.files.length > 0) {
      Object.keys(this.files).forEach((fileId: any) => {
        const currentFile = this.files[fileId];
        formData.append(fileId, currentFile);
      });
    }
    formData.append('forceUpdate', `false`);
    formData.append('soundFor', soundType);
    formData.append('language', `en`);
    this.props.uploadSoundFile({
      'list': {
        'limit': limit,
        'orderBy': orderBy,
        'orderField': orderField,
        'pageNo': pageNo,
        'searchValue': searchString
      },
      'upload': formData
    });
    this.setState({
      showAddModal: false,
      isFile: true
    });
  }

  public handleOnSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchStr = event.target.value;
    this.setState({
      searchString: searchStr
    }, () => {
      if (searchStr.length > 2 || searchStr.length === 0) {
        this.list();
      }
    });
  }

  public handleOnDelete = (fileId: string) => {
    if (fileId) {
      const { pageNo, limit, searchString, orderBy, orderField } = this.state;
      this.setState({
        showDeleteModal: false
      });
      this.props.deleteSoundFile({
        'fileId': fileId,
        'list': {
          'limit': limit,
          'orderBy': orderBy,
          'orderField': orderField,
          'pageNo': pageNo,
          'searchValue': searchString
        }
      });
    }
  }

  public handleSortBy = (event: any) => {
    const index = event.target.value;
    let orderField = '';
    if (index === '0') {
      orderField = 'created_at';
    } else if (index === '1') {
      orderField = 'file_name';
    }
    this.setState({
      orderField
    }, () => {
      this.list();
    });
  }

  public handlesoundType = (event: any) => {
    const soundType = event.target.value;
    this.setState({
      soundType
    });
  }

  public handleOrderBy = () => {
    const { orderBy } = this.state;
    let newOrderBy = '';
    if (orderBy === 'desc') {
      newOrderBy = 'asc';
    } else if (orderBy === 'asc') {
      newOrderBy = 'desc';
    }
    this.setState({
      'orderBy': newOrderBy
    }, () => {
      this.list();
    });
  }

  public handlePagination = (paginate: any) => {
    const { pageNo, limit } = this.state;
    const { totalCount } = this.props;
    const maxPage = Math.ceil(totalCount / limit);
    let newPageNo = 1;
    if (paginate === 'previous') {
      newPageNo = pageNo !== 1 ? (pageNo - 1) : 1;
    } else if (paginate === 'next') {
      newPageNo = pageNo < maxPage ? (pageNo + 1) : maxPage;
    } else if (typeof paginate === 'number') {
      newPageNo = paginate;
    }
    this.setState({
      pageNo: newPageNo
    }, () => {
      this.list();
    });
  }

  public getPageNumber = () => {
    const { limit, pageNo } = this.state;
    const { totalCount } = this.props;
    let pageNos: any = [];
    if (totalCount > 0) {
      const maxPage = Math.ceil(totalCount / limit);
      if (maxPage === 1) {
        pageNos = [1];
      } else if (maxPage === 2) {
        pageNos = [1, 2];
      } else if ((pageNo < maxPage) && (pageNo > 1)) {
        pageNos = [pageNo - 1, pageNo, pageNo + 1];
      } else if (maxPage === pageNo) {
        pageNos = [pageNo - 2, pageNo - 1, pageNo];
      }
    }
    return pageNos;
  }

  public toggleAddModal = (showAddModal: boolean) => {
    this.setState({
      showAddModal
    });
  }

  public toggleDeleteModal = (showDeleteModal: boolean, deleteFileId: string) => {
    this.setState({
      deleteFileId,
      showDeleteModal
    });
  }

  public formatDate = (createdAt: string) => {
    const date: any = new Date(createdAt);
    const formattedDate = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const formattedMonth = date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth();
    return `${formattedDate}-${formattedMonth}-${date.getFullYear()}`;
  }

  public list = () => {
    const { pageNo, limit, searchString, orderBy, orderField } = this.state;
    this.props.fetchSoundLibrary({
      'limit': limit,
      'orderBy': orderBy,
      'orderField': orderField,
      'pageNo': pageNo,
      'searchValue': searchString
    });
  }

  public toggleConfirmModal = (showConfirmModal: boolean) => {
    this.props.toggleConfirmModal(showConfirmModal);
  }

  public replaceAudio = () => {
    const { pageNo, limit, orderBy, orderField, searchString } = this.state;
    const formData = new FormData();
    if (this.files && this.files.length > 0) {
      Object.keys(this.files).forEach((fileId: any) => {
        formData.append(fileId, this.files[fileId]);
      });
    }
    formData.append('forceUpdate', `true`);
    this.props.uploadSoundFile({
      'list': {
        'limit': limit,
        'orderBy': orderBy,
        'orderField': orderField,
        'pageNo': pageNo,
        'searchValue': searchString
      },
      'upload': formData
    });
  }

  public bulkDelete = () => {
    const { limit, searchString, orderBy, orderField, deleteIds } = this.state;
    const list = {
      'limit': limit,
      'orderBy': orderBy,
      'orderField': orderField,
      'pageNo': 1,
      'searchValue': searchString
    };
    if (deleteIds.length > 0) {
      this.props.bulkSoundLibraryDelete({ deleteIds, list });
      this.setState({ showDeleteButton: false, deleteIds: [] });
    }
  }

  public handleSelectAll = (e: any) => {
    const { soundList } = this.props;
    const target: any = document.getElementById(e.target.id);
    const value: any = target.checked;
    if (value) {
      const array: any = [];
      soundList.map((sound: any) => {
        array.push(sound.id);
      });
      this.setState({
        deleteIds: array,
        showDeleteButton: true
      });
    } else {
      this.setState({
        deleteIds: [],
        showDeleteButton: false
      });
    }
  }

  public handleSingleSelect = (e: any) => {
    // tslint:disable-next-line
    const target: any = document.getElementById(e.target.id);
    const value: any = target.checked;
    const array: any = this.state.deleteIds.slice(0);
    if (value) {
      this.setState({ showDeleteButton: true, deleteIds: [...this.state.deleteIds, e.target.value] });
    } else {
      const index = array.map((id: string) => id).indexOf(e.target.value);
      array.splice(index, 1);
      if (array.length === 0) {
        const targetChecked: any = document.getElementById('selectAll');
        // tslint:disable-next-line
        targetChecked.checked = false;
      }
      this.setState({ showDeleteButton: array.length === 0 ? false : true, deleteIds: array });
    }
  }

  public findIndex = (value: string) => {
    const index = this.state.deleteIds.map((id: string) => id).indexOf(value);
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  }

  public render() {
    const {
      limit,
      pageNo,
      searchString,
      sortByOptions,
      soundTypeOptions,
      showAddModal,
      showDeleteModal,
      showDeleteButton,
      track,
      deleteFileId,
      isFile
    } = this.state;
    const { soundList, totalCount, duplicateFiles, loading } = this.props;
    const maxPage = Math.ceil(totalCount / limit);
    const pageNos = this.getPageNumber();
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="panel panel-default">
              <div className="panel-heading frames-heading">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-3 col-xs-12">
                    <h4><b>SOUND LIBRARY</b></h4>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-9 col-xs-12">
                    <div className="upload-btn pull-right">
                      {showDeleteButton ?
                        <Button onClick={this.bulkDelete} bsStyle="danger m-0">
                          Delete
                        </Button>
                        :
                        <Button onClick={() => this.toggleAddModal(true)} bsStyle="success">
                          <i className="glyphicon glyphicon-plus p-r-5" />
                          Add Sound
                        </Button>
                      }
                    </div>
                    <div className="search-box pull-right">
                      <span className="icon-search">
                        <i className="glyphicon glyphicon-search" />
                      </span>
                      <input
                        placeholder="Search sound"
                        type="text"
                        value={searchString}
                        onChange={(e) => this.handleOnSearch(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-heading frames-heading">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-3 col-xs-12">
                    <h4><b>{totalCount} Files</b></h4>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-9 col-xs-12">
                    <div className="sort-by pull-right">
                      <form>
                        <FormGroup controlId="formControlsSelect">
                          <FormControl componentClass="select" placeholder="select" onChange={this.handleSortBy}>
                            {sortByOptions && sortByOptions.map((sortBy, index) => (
                              <option key={index} value={index}>{sortBy}</option>
                            ))}
                          </FormControl>
                        </FormGroup>
                      </form>
                    </div>
                    <span className="sort-by-label pull-right">Sort By</span>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 workout-table remv-padd">
                    <div className="col-xs-12 remv-padd table-content">
                      <div className="col-xs-12 table-header remv-padd">
                        <div className="col-xs-2 col-md-1">
                          <input
                            className="mt-10"
                            type="checkbox"
                            name="selectCheckAll"
                            onClick={this.handleSelectAll}
                            id="selectAll"
                          />
                        </div>
                        <div className="col-xs-2 col-md-3 remv-padd">
                          <h5>FILE NAME</h5>
                        </div>
                        <div className="col-xs-1 col-md-1 remv-padd">
                          <h5>TYPE</h5>
                        </div>
                        <div className="col-xs-2 col-md-2 remv-padd">
                          <h5>SIZE</h5>
                        </div>
                        <div className="col-xs-2 col-md-2 remv-padd">
                          <h5>DATE UPLOADED</h5>
                        </div>
                        <div className="col-xs-1 col-md-1 remv-padd">
                          <h5>ACTIONS</h5>
                        </div>
                        <div className="col-xs-2 col-md-2 remv-padd">
                          <h5>PLAY/STOP</h5>
                        </div>
                      </div>
                      {loading ?
                        <LoaderComponent
                          loading={true}
                          imageSrc={loader}
                          imageStyle={{ marginTop: '20px', height: '100px' }}
                        />
                        :
                        soundList && soundList.length > 0 &&
                        soundList.map((data: any, stageIndex: string) => (
                          <div
                            className={`col-xs-12 table-row remv-padd`}
                            title={data.file_name}
                          >
                            <div className="col-xs-2 col-md-1">
                              <input
                                className="mt-15"
                                checked={this.findIndex(data.id)}
                                type="checkbox"
                                name="selectCheck"
                                value={data.id}
                                onClick={(e) => this.handleSingleSelect(e)}
                                id={stageIndex}
                              />
                            </div>
                            <div className="col-xs-2 col-md-3 remv-padd">
                              <p title={data.name}>{data.file_name}</p>
                            </div>
                            <div className="col-xs-1 col-md-1 remv-padd">
                              <p>{data.is_instruction ? `Instruction` : `Sound`}</p>
                            </div>
                            <div className="col-xs-2 col-md-2 remv-padd">
                              <p>{data.size} KB</p>
                            </div>
                            <div className="col-xs-2 col-md-2 remv-padd">
                              <p>{this.formatDate(data.created_at)}</p>
                            </div>
                            <div className={showDeleteButton ? 'display-none' : 'col-xs-1 col-md-1 remv-padd'}>
                              <i
                                className="glyphicon glyphicon-trash remove-icon"
                                onClick={() => this.toggleDeleteModal(true, data.id)}
                                title="Delete"
                              />
                            </div>
                            <div className={showDeleteButton ? 'display-none' : 'col-xs-2 col-md-2 remv-padd'}
                              onClick={() => this.playAudio(data.id, stageIndex)}
                            >
                              <Button
                                bsStyle={`${track === stageIndex ? 'danger mt-5' : 'primary'} play-btn`}
                              >
                                <i className={track === stageIndex ? 'glyphicon glyphicon-stop p-r-5' : 'glyphicon glyphicon-play p-r-5'} />
                                {track === stageIndex ? 'Stop' : 'Play'}
                              </Button>
                              <audio id={data.id} preload="auto" onEnded={() => this.endAudio(data.id)}>
                                <source
                                  src={`${data.is_instruction ? this.props.instructionUrl : this.props.soundUrl}${data.file_name}`}
                                  type={`audio/${data.file_name.split('.')[1] === 'mp3' ? 'mpeg' : 'wav'}`}
                                />
                              </audio>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
                {!loading && totalCount > 0 &&
                  <div className="row">
                    <div className="frames-pagination">
                      <nav aria-label="Page navigation example">
                        <ul className="pagination">
                          <li
                            className={pageNo === 1 ? 'page-item disabled' : 'page-item'}
                            onClick={() => this.handlePagination('previous')}
                          >
                            <a className="page-link" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                              <span className="sr-only">Previous</span>
                            </a>
                          </li>
                          {pageNos && pageNos.map((page: any, index: any) => (
                            <li
                              key={index}
                              onClick={() => this.handlePagination(page)}
                              className={pageNo === page ? 'page-item active' : 'page-item'}
                            >
                              <a className="page-link">{page}</a>
                            </li>
                          ))}
                          <li
                            className={pageNo === maxPage ? 'page-item disabled' : 'page-item'}
                            onClick={() => this.handlePagination('next')}
                          >
                            <a className="page-link" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                              <span className="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <Modal show={showAddModal} onHide={() => this.toggleAddModal(false)}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Add Sound</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-xs-2 modal-label">
                <span>Type</span>
              </div>
              <div className="col-xs-4">
                <div>
                  <form>
                    <FormGroup controlId="formControlsSelect">
                      <FormControl componentClass="select" placeholder="select" onChange={this.handlesoundType}>
                        {soundTypeOptions && soundTypeOptions.map((soundType, index) => (
                          <option key={index} value={soundType}>{soundType}</option>
                        ))}
                      </FormControl>
                    </FormGroup>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-2 modal-label">
                <span>File</span>
              </div>
              <div className="col-xs-4 modal-upload-btn">
                <input
                  accept="audio/mp3, audio/wav"
                  type="file"
                  multiple={true}
                  id="soundUpload"
                  onChange={(e) => this.handleFileUpload(e)}
                />
                <label htmlFor="soundUpload" className="modal-label">
                  <i className="glyphicon glyphicon-upload p-r-5" />
                  Upload Sound
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="danger m-0"
              onClick={() => this.toggleAddModal(false)}
            >
              Close
            </Button>
            <Button
              bsStyle="success"
              disabled={!isFile}
              onClick={() => this.handleAddSound()}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDeleteModal} onHide={() => this.toggleDeleteModal(false, deleteFileId)}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to delete this file?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="danger m-0"
              onClick={() => this.toggleDeleteModal(false, deleteFileId)}
            >
              Close
            </Button>
            <Button
              bsStyle="success"
              onClick={() => this.handleOnDelete(deleteFileId)}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.props.showReplaceModal} onHide={() => this.toggleConfirmModal(false)}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Replace Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {duplicateFiles && duplicateFiles.length &&
                `Are you sure you want to replace ${duplicateFiles.join(', ')}?`
              }
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="danger m-0"
              onClick={() => this.toggleConfirmModal(false)}
            >
              Skip
            </Button>
            <Button
              bsStyle="success"
              onClick={this.replaceAudio}
            >
              Replace
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  duplicateFiles: state.sound.duplicateFiles,
  imageUpload: state.sound.imageUpload,
  soundUrl: state.sound.soundUrl,
  instructionUrl: state.sound.instructionUrl,
  loading: state.sound.loading,
  showReplaceModal: state.sound.showReplaceModal,
  soundList: state.sound.soundList,
  totalCount: state.sound.totalCount
});

const mapDispatchToProps = ({
  bulkSoundLibraryDelete,
  deleteSoundFile,
  fetchSoundLibrary,
  toggleConfirmModal,
  uploadSoundFile
});

interface IStateProps {
  duplicateFiles: any;
  imageUpload: any;
  soundUrl: any,
  instructionUrl: any,
  soundList: any[];
  loading: boolean;
  totalCount: any;
  showReplaceModal: any;
}

interface IDispatchProps {
  deleteSoundFile: any,
  fetchSoundLibrary: any,
  uploadSoundFile: any
  toggleConfirmModal: any,
  bulkSoundLibraryDelete: any
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLocalize
)(SoundLibrary);





























































