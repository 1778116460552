import Constants from '../actions/workouts/constants';

const initialState = {
  editWorkout: [],
  loading: false,
  totalCount: 0,
  workout: {},
  workouts: [],
  searchedWorkouts: []
};

import { IActionProps } from './index';

export interface IworkoutReducer {
  editWorkout: any[],
  loading: boolean;
  workout: any;
  workouts: any;
  searchedWorkouts: any;
  totalCount: any;
}

export const workoutReducer = (
  state: IworkoutReducer = initialState,
  action: IActionProps
) => {
  switch (action.type) {
    case Constants.FETCH_WORKOUTS:
      return {
        ...state,
        loading: true
      };
    case Constants.FETCH_WORKOUTS_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.FETCH_WORKOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalCount: action.response.totalCount,
        workouts: action.response.workouts
      };
    case Constants.CREATE_WORKOUT:
      return {
        ...state,
        loading: true
      };
    case Constants.CREATE_WORKOUT_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.CREATE_WORKOUT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case Constants.DELETE_WORKOUT:
      return {
        ...state,
        loading: true
      };
    case Constants.DELETE_WORKOUT_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.DELETE_WORKOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        totalCount: action.response.count,
        workouts: action.response.workouts
      };
    case Constants.EDIT_WORKOUT:
      return {
        ...state,
        loading: true
      };
    case Constants.EDIT_WORKOUT_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.EDIT_WORKOUT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case Constants.GET_WORKOUT:
      return {
        ...state,
        loading: true
      };
    case Constants.GET_WORKOUT_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.GET_WORKOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        workout: action.response
      };
    case Constants.UPDATE_WORKOUT:
      return {
        ...state,
        loading: true
      };
    case Constants.UPDATE_WORKOUT_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.UPDATE_WORKOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        workouts: action.response
      };
    case Constants.CLEAR_WORKOUT:
      return {
        ...state,
        workout: {}
      };
    case Constants.BULK_WORKOUTS_DELETE:
      return {
        ...state,
        loading: true
      };
    case Constants.BULK_WORKOUTS_DELETE_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.BULK_WORKOUTS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case Constants.SEARCH_WORKOUTS:
      return {
        ...state,
        loading: true
      };
    case Constants.SEARCH_WORKOUTS_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.SEARCH_WORKOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchedWorkouts: action.response.workouts
      };
    default:
      return state;
  }
};
