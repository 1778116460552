import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import {
  bulkDeleteFail,
  bulkDeleteSuccess,
  deleteTextFail,
  deleteTextSuccess,
  fetchTextListFail,
  fetchTextListSuccess,
  getTextFail,
  getTextSuccess,
  uploadTextFail,
  uploadTextSuccess
} from '../actions/textLibrary';
import { bulkDelete, get, remove, upload, view } from '../services/textLibrary';

function* deleteText(action: any) {
  const { data } = action;
  try {
    const response = yield call(remove, data.fileId);
    yield put(deleteTextSuccess(response.data));
    const listResponse = yield call(view, data.list);
    yield put(fetchTextListSuccess(listResponse.data));
    toast.success('File deleted successfully');
  } catch (error) {
    toast.error(error.message);
    yield put(deleteTextFail(error));
    yield put(fetchTextListFail(error));
  }
}

function* uploadText(action: any) {
  const { data } = action;
  try {
    const response = yield call(upload, data);
    if (response.data.statusCode === 409) {
      yield put(uploadTextSuccess({ status: true}));
    } else {
      yield put(uploadTextSuccess({ status: false}));
      yield put(push('/textLibrary'));
      toast.success('File uploaded successfully');
    }
  } catch (error) {
    toast.error(error.message);
    yield put(uploadTextFail(error));
    yield put(fetchTextListFail(error));
  }
}

function* getTextLibrary(action: any) {
  const { data } = action;
  try {
    const response = yield call(view, data);
    yield put(fetchTextListSuccess(response.data));
  } catch (error) {
    yield put(fetchTextListFail(error));
  }
}

function* getText(action: any) {
  const { id } = action;
  try {
    const response = yield call(get, id);
    yield put(getTextSuccess(response.data));
  } catch (error) {
    yield put(getTextFail(error));
  }
}

function* deleteMultipleFiles(action: any) {
  const { data } = action;
  try {
    const response = yield call(bulkDelete, data);
    yield put(bulkDeleteSuccess(response.data));
    const listResponse = yield call(view, data.list);
    yield put(fetchTextListSuccess(listResponse.data));
    toast.success('Files deleted successfully');
  } catch (error) {
    yield put(bulkDeleteFail(error));
  }
}

export { deleteText, uploadText, getTextLibrary, getText, deleteMultipleFiles };
