import { takeLatest } from 'redux-saga/effects';
import LogoLibraryConstants from '../actions/logoLibrary/constants';
import LogoStagesConstants from '../actions/logoStages/constants';
import SoundLibraryConstants from '../actions/soundLibrary/constants';
import TextLibraryConstants from '../actions/textLibrary/constants';
import UserConstants from '../actions/user/constants';
import WorkoutFrameConstants from '../actions/workoutFrames/constants';
import WorkoutConstants from '../actions/workouts/constants';
import WorkoutStageConstants from '../actions/workoutStages/constants';
import GlobalConstants from '../actions/global/constants';
import { fetchMobileApplications } from './global';
import { bulkLogoLibraryDelete, deleteLogoImages, getLogo, uploadLogoImages } from './logoLibrary';
import { getLogoStages } from './logoStages';
import { bulkSoundLibraryDelete, deleteSound, getSoundLibrary, uploadSound } from './soundLibrary';
import { deleteMultipleFiles, deleteText, getText, getTextLibrary, uploadText } from './textLibrary';
import { createUser, fetchUsers, getUser, getUserDetail, onLoginUser,
  onLogoutUser, resetPassword, updateUser, updateUserStatus, verifyUser } from './user';
import { bulkWorkoutFramesDelete, deleteWorkoutImages, getWorkoutFrames, uploadWorkoutImages } from './workoutFrames';
import { bulkWorkoutsDelete, createWorkout, deleteWorkout, editWorkout, fetchWorkouts, getWorkout, updateWorkout, searchWorkouts } from './workouts';
import { fetchWorkoutStages } from './workoutStages';

export default function* rootSaga() {
  yield takeLatest(UserConstants.LOGIN_USER, onLoginUser);
  yield takeLatest(UserConstants.LOGOUT_USER, onLogoutUser);
  yield takeLatest(WorkoutConstants.FETCH_WORKOUTS, fetchWorkouts);
  yield takeLatest(WorkoutConstants.SEARCH_WORKOUTS, searchWorkouts);
  yield takeLatest(WorkoutStageConstants.FETCH_WORKOUT_STAGES, fetchWorkoutStages);
  yield takeLatest(WorkoutConstants.CREATE_WORKOUT, createWorkout);
  yield takeLatest(WorkoutConstants.DELETE_WORKOUT, deleteWorkout);
  yield takeLatest(WorkoutConstants.GET_WORKOUT, getWorkout);
  yield takeLatest(WorkoutConstants.EDIT_WORKOUT, editWorkout);
  yield takeLatest(WorkoutFrameConstants.UPLOAD_WORKOUT_FRAMES, uploadWorkoutImages);
  yield takeLatest(WorkoutFrameConstants.LIST_WORKOUT_FRAMES, getWorkoutFrames);
  yield takeLatest(WorkoutFrameConstants.DELETE_WORKOUT_FRAMES, deleteWorkoutImages);
  yield takeLatest(LogoLibraryConstants.UPLOAD_LOGO, uploadLogoImages);
  yield takeLatest(LogoLibraryConstants.LIST_LOGO, getLogo);
  yield takeLatest(LogoLibraryConstants.DELETE_LOGO, deleteLogoImages);
  yield takeLatest(UserConstants.FETCH_USERS, fetchUsers);
  yield takeLatest(UserConstants.VERIFY_USER, verifyUser);
  yield takeLatest(UserConstants.RESET_PASSWORD, resetPassword);
  yield takeLatest(UserConstants.GET_USER, getUser);
  yield takeLatest(SoundLibraryConstants.UPLOAD_SOUND, uploadSound);
  yield takeLatest(SoundLibraryConstants.LIST_SOUND, getSoundLibrary);
  yield takeLatest(SoundLibraryConstants.DELETE_SOUND, deleteSound);
  yield takeLatest(TextLibraryConstants.UPLOAD_TEXT, uploadText);
  yield takeLatest(TextLibraryConstants.FETCH_TEXT, getTextLibrary);
  yield takeLatest(TextLibraryConstants.DELETE_TEXT, deleteText);
  yield takeLatest(TextLibraryConstants.GET_TEXT, getText);
  yield takeLatest(UserConstants.UPDATE_USER, updateUser);
  yield takeLatest(UserConstants.CREATE_USER, createUser);
  yield takeLatest(WorkoutConstants.UPDATE_WORKOUT, updateWorkout);
  yield takeLatest(UserConstants.GET_USER_DETAIL, getUserDetail);
  yield takeLatest(LogoStagesConstants.LIST_LOGO_STAGES, getLogoStages);
  yield takeLatest(UserConstants.DELETE_USER, updateUserStatus);
  yield takeLatest(TextLibraryConstants.BULK_DELETE, deleteMultipleFiles);
  yield takeLatest(WorkoutConstants.BULK_WORKOUTS_DELETE, bulkWorkoutsDelete);
  yield takeLatest(WorkoutFrameConstants.BULK_WORKOUT_FRAMES_DELETE, bulkWorkoutFramesDelete);
  yield takeLatest(LogoLibraryConstants.BULK_LOGO_LIBRARY_DELETE, bulkLogoLibraryDelete);
  yield takeLatest(SoundLibraryConstants.BULK_SOUND_LIBRARY_DELETE, bulkSoundLibraryDelete);
  yield takeLatest(GlobalConstants.GET_MOBILE_APPLICATIONS, fetchMobileApplications);
}
