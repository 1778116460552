import keyMirror from 'key-mirror';
export default keyMirror({
  CLEAR_USER: null,
  CREATE_USER: null,
  CREATE_USER_FAIL: null,
  CREATE_USER_SUCCESS: null,
  DELETE_USER: null,
  DELETE_USER_FAIL: null,
  DELETE_USER_SUCCESS: null,
  EDIT_USER: null,
  EDIT_USER_FAIL: null,
  EDIT_USER_SUCCESS: null,
  FETCH_USERS: null,
  FETCH_USERS_FAIL: null,
  FETCH_USERS_SUCCESS: null,
  GET_USER: null,
  GET_USER_DETAIL: null,
  GET_USER_DETAIL_FAIL: null,
  GET_USER_DETAIL_SUCCESS: null,
  GET_USER_FAIL: null,
  GET_USER_SUCCESS: null,
  LOGIN_USER: null,
  LOGIN_USER_FAIL: null,
  LOGIN_USER_SUCCESS: null,
  LOGOUT_USER: null,
  LOGOUT_USER_FAIL: null,
  LOGOUT_USER_SUCCESS: null,
  RESET_PASSWORD: null,
  RESET_PASSWORD_FAIL: null,
  RESET_PASSWORD_SUCCESS: null,
  UPDATE_USER: null,
  UPDATE_USER_FAIL: null,
  UPDATE_USER_SUCCESS: null,
  VERIFY_USER: null,
  VERIFY_USER_FAIL: null,
  VERIFY_USER_SUCCESS: null
});
