import Constants from '../actions/workoutStages/constants';

const initialState = {
  loading: false,
  totalCount: 0,
  workoutStage: null,
  workoutStages: []
};

import { IActionProps } from './index';

export interface IworkoutStageReducer {
  loading: boolean;
  workoutStage: any;
  workoutStages: any[];
  totalCount: any;
}

export const workoutStageReducer = (
  state: IworkoutStageReducer = initialState,
  action: IActionProps
) => {
  switch (action.type) {
    case Constants.FETCH_WORKOUT_STAGES:
      return {
        ...state,
        loading: true
      };
    case Constants.FETCH_WORKOUT_STAGES_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.FETCH_WORKOUT_STAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        totalCount: action.response.length,
        workoutStages: action.response
      };
    default:
      return state;
  }
};
