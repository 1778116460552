import Constants from '../actions/logoStages/constants';

const initialState = {
  loading: false,
  logoStages: []
};

import { IActionProps } from './index';

export interface IlogoStagesReducer {
  loading: boolean;
  logoStages: any[];
}

export const logoStagesReducer = (
  state: IlogoStagesReducer = initialState,
  action: IActionProps
) => {
  switch (action.type) {
    case Constants.LIST_LOGO_STAGES:
      return {
        ...state,
        loading: true
      };
    case Constants.LIST_LOGO_STAGES_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.LIST_LOGO_STAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        logoStages: action.response
      };
    default:
      return state;
  }
};
