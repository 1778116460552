
import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { deleteUser, fetchUsers } from '../../actions/user';
import loader from '../../assets/img/loader.gif';
import LoaderComponent from '../../components/Loader/index';
import Table, { ITableState } from '../../components/Table';
import Column from '../../components/Table/Column';
import { IState } from '../../reducers';
import './users.css';

interface IUserState {
  isEdit: boolean,
  pageNo: any,
  limit: any,
  searchValue: string,
  userType: string,
  orderField: string,
  orderBy: string,
  showDeleteModal: boolean,
  deleteFileId: string
}

interface IUserProps {
  fetchUsers: any,
  totalCount: any,
  users: any,
  loading: boolean,
  deleteUser: any
}

class Users extends React.PureComponent<IUserProps & RouteComponentProps, IUserState> {

  public state: IUserState;

  public columns: Column[];

  constructor(props: IUserProps & RouteComponentProps) {
    super(props);
    this.columns = [
      (new Column()).withKey('email').withLabel('EMAIL').withCellFormatter((cell: any, row: any) => (
        <div>
          <p className="ellipsis" title={row.email}>{row.email}</p>
        </div>
      )),
      (new Column()).withKey('first_name').withLabel('FIRST NAME'),
      (new Column()).withKey('last_name').withLabel('LAST NAME'),
      (new Column()).withKey('created_at').withLabel('DATE ADDED').withCellFormatter((cell: any, row: any) => (
        <div>
          {this.formatDate(row.created_at)}
        </div>
      )),
      (new Column()).withKey('is_active').withLabel('STATUS').withCellFormatter((cell: any, row: any) => (
        <div>
          <p className={row.is_active ? 'active' : 'invited'}>
          {row.is_active ? 'Active' : 'Inactive'}</p>
        </div>
      )),
      (new Column()).withKey('action').withLabel('ACTIONS').withCellFormatter((cell: any, row: any) => (
        <div className="center">
          <i
            className="glyphicon glyphicon-eye-open view-icon"
            onClick={() => this.editUser(row.id)}
            title="View"
          />
          <i className={row.is_active ? 'glyphicon glyphicon-trash delete-icon' : 'display-none'} title="Delete" onClick={() => this.toggleDeleteModal(true, row.id)}/>
        </div>
      ))
    ],
    this.state = {
      deleteFileId: '',
      isEdit: false,
      limit: 10,
      orderBy: 'desc',
      orderField: 'created_at',
      pageNo: 1,
      searchValue: '',
      showDeleteModal: false,
      userType: 'ADMIN'
    };
  }

  public formatDate = (createdAt: string) => {
    const date: any = new Date(createdAt);
    const formattedDate = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const formattedMonth = date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth();
    return `${formattedDate}-${formattedMonth}-${date.getFullYear()}`;
  }

  public componentDidMount() {
    const { limit, orderBy, orderField, pageNo, searchValue, userType } = this.state;
    this.props.fetchUsers({ limit, orderBy, orderField, pageNo, searchValue, userType });
  }

  public createUser = () => {
    this.props.history.push('/users/create');
  }

  public editUser = (id: any) => {
    this.props.history.push(`/users/${id}/view`);
  }

  public getUsers = () => {
    this.props.fetchUsers();
  }

  public handlePagination = (paginate: any) => {
    const { pageNo, limit } = this.state;
    const { totalCount } = this.props;
    const maxPage = Math.ceil(totalCount / limit);
    let newPageNo = 1;
    if (paginate === 'previous') {
      newPageNo = pageNo !== 1 ? (pageNo - 1) : 1;
    } else if (paginate === 'next') {
      newPageNo = pageNo < maxPage ? (pageNo + 1) : maxPage;
    } else if (typeof paginate === 'number') {
      newPageNo = paginate;
    }
    this.setState({
      pageNo: newPageNo
    });
  }

  public getPageNumber = () => {
    const { limit, pageNo } = this.state;
    const { totalCount } = this.props;
    let pageNos = [];
    if (totalCount > 0) {
      const maxPage = Math.ceil(totalCount / limit);
      if (maxPage === 1) {
        pageNos = [1];
      } else if (maxPage === 2) {
        pageNos = [1, 2];
      } else if ((pageNo < maxPage) && (pageNo > 1)) {
        pageNos = [pageNo - 1, pageNo, pageNo + 1];
      } else if (maxPage === pageNo) {
        pageNos = [pageNo - 2, pageNo - 1, pageNo];
      }
    }
    return pageNos;
  }

  public toggleDeleteModal = (showDeleteModal: boolean, deleteFileId: string) => {
    this.setState({
      deleteFileId,
      showDeleteModal
    });
  }

  public handleOnDelete = (fileId: string) => {
    if (fileId) {
      const { limit, orderBy, orderField, pageNo, searchValue, userType } = this.state;
      this.setState({
        showDeleteModal: false
      });
      this.props.deleteUser({
        'id': fileId,
        'is_active': false,
        'list': { limit, orderBy, orderField, pageNo, searchValue, userType }
      });
    }
  }

  public render() {
    const { limit, pageNo, showDeleteModal, deleteFileId } = this.state;
    const { users, totalCount, loading } = this.props;
    const maxPage = Math.ceil(totalCount / limit);
    const pageNos = this.getPageNumber();
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4><b>MANAGE USERS</b></h4>
                <div className="create-btn">
                  <Button bsStyle="success" onClick={() => this.createUser()}>
                  <i className="glyphicon glyphicon-plus add-icon p-r-5" />
                    Add User
                  </Button>
                </div>
              </div>
              { loading ?
                <LoaderComponent
                  loading={loading}
                  imageSrc={loader}
                  imageStyle={{marginTop: '10%', height: '100px'}}
                />
                :
                <Table
                  keyField="id"
                  data={users &&  users.length > 0 ? users : []}
                  columns={this.columns}
                  loading={loading}
                  length={limit}
                  currentPage={pageNo}
                  total={totalCount}
                  onUpdate={(nextState: ITableState) => {
                    const { page, sizePerPage } = nextState;
                    this.setState({
                      limit: sizePerPage,
                      pageNo: page
                    });
                  }}
                />
              }
              {!loading && totalCount > 0 &&
                <div className="row">
                  <div className="frames-pagination workout-pagination">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        <li
                          className={pageNo === 1 ? 'page-item disabled' : 'page-item'}
                          onClick={() => this.handlePagination('previous')}
                        >
                          <a className="page-link" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                          </a>
                        </li>
                        {pageNos && pageNos.map((page: any, index: any) => (
                          <li
                            key={index}
                            onClick={() => this.handlePagination(page)}
                            className={pageNo === page ? 'page-item active' : 'page-item'}
                          >
                            <a className="page-link">{page}</a>
                          </li>
                        ))}
                        <li
                          className={pageNo === maxPage ? 'page-item disabled' : 'page-item'}
                          onClick={() => this.handlePagination('next')}
                        >
                          <a className="page-link" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      <Modal show={showDeleteModal} onHide={() => this.toggleDeleteModal(false, deleteFileId)}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to delete this user?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="danger m-0"
              onClick={() => this.toggleDeleteModal(false, deleteFileId)}
            >
              Close
            </Button>
            <Button
              bsStyle="success"
              onClick={() => this.handleOnDelete(deleteFileId)}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  loading: state.user.loading,
  totalCount: state.user.totalCount,
  userObj: state.user.userObj,
  users: state.user.users
});

const mapDispatchToProps = ({
  deleteUser,
  fetchUsers
});

interface IStateProps {
  users: any[];
  userObj: any;
  loading: boolean;
  totalCount: any;
}

interface IDispatchProps {
  fetchUsers: any;
  deleteUser: any;
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(Users);
