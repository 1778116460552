import { call, put } from 'redux-saga/effects';
import { getMobileApplicationsFail, getMobileApplicationsSuccess } from '../actions/global';
import { getMobileApplications } from '../services/global';

function* fetchMobileApplications(action: any) {
    try {
      const response = yield call(getMobileApplications);
      yield put(getMobileApplicationsSuccess(response.data));
    } catch (error) {
      yield put(getMobileApplicationsFail(error));
    }
}

export { fetchMobileApplications };
