
import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { bulkDelete, deleteText, fetchTextList } from '../../actions/textLibrary';
import loader from '../../assets/img/loader.gif';
import LoaderComponent from '../../components/Loader/index';
import Table, { ITableState } from '../../components/Table';
import Column from '../../components/Table/Column';
import { IState } from '../../reducers';
import './textLibrary.css';

interface ITextLibraryState {
  isEdit: boolean,
  pageNo: any,
  limit: any,
  showDeleteModal: boolean,
  showDeleteButton: boolean,
  deleteFileId: string,
  deleteIds: string[],
  deleteObject: any,
  showConfirmModal: boolean
}

interface ITextLibraryProps {
  fetchTextList: any,
  deleteText: any,
  totalCount: any,
  textList: any,
  loading: boolean,
  bulkDelete: any,
}

class TextLibrary extends React.PureComponent<ITextLibraryProps & RouteComponentProps, ITextLibraryState> {

  public state: ITextLibraryState;

  public columns: Column[];

  constructor(props: ITextLibraryProps & RouteComponentProps) {
    super(props);
    this.columns = [
      (new Column()).withKey('file_name').withLabel('FILE NAME'),
      (new Column()).withKey('size').withLabel('SIZE').withCellFormatter((cell: any, row: any) => (
        <p>
          {row.size} kB
        </p>
      )),
      (new Column()).withKey('created_at').withLabel('DATE UPLOADED').withCellFormatter((cell: any, row: any) => (
        <p>
          {this.formatDate(row.created_at)}
        </p>
      )),
      (new Column()).withKey('updated_at').withLabel('DATE UPDATED').withCellFormatter((cell: any, row: any) => (
        <p>
          {this.formatDate(row.updated_at)}
        </p>
      )),
      (new Column()).withKey('action').withLabel('ACTIONS').withCellFormatter((cell: any, row: any) => (
        <div className={this.state.showDeleteButton ? 'display-none' : ''}>
          <i
          className="glyphicon glyphicon-eye-open view-icon"
          onClick={() => this.editUser(row.id, 'view')}
          />
          <i
            className="glyphicon glyphicon-pencil edit-icon"
            onClick={() => this.editUser(row.id, 'edit')}
            title="Edit"
          />
          <i className="glyphicon glyphicon-trash delete-icon" title="Delete" onClick={() => this.toggleDeleteModal(true, row.id)}/>
        </div>
      ))
    ],
    this.state = {
      deleteFileId: '',
      deleteIds: [],
      deleteObject: [],
      isEdit: false,
      limit: 10,
      pageNo: 1,
      showConfirmModal: false,
      showDeleteButton: false,
      showDeleteModal: false
    };
  }

  public formatDate = (createdAt: string) => {
    const date: any = new Date(createdAt);
    const formattedDate = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const formattedMonth = date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth();
    return `${formattedDate}-${formattedMonth}-${date.getFullYear()}`;
  }

  public componentDidMount() {
    const { limit, pageNo} = this.state;
    this.props.fetchTextList({
      'limit': limit,
      'orderBy': 'desc',
      'orderField': 'created_at',
      'pageNo': pageNo,
      'searchValue': ''
    });
  }

  public createJson = () => {
    this.props.history.push('/textLibrary/create');
  }

  public editUser = (id: any, name: string) => {
    if (name === 'edit') {
      this.props.history.push(`/textLibrary/${id}/edit`);
    } else {
      this.props.history.push(`/textLibrary/${id}/view`);
    }
  }

  public handlePagination = (paginate: any) => {
    const { pageNo, limit } = this.state;
    const { totalCount } = this.props;
    const maxPage = Math.ceil(totalCount / limit);
    let newPageNo = 1;
    if (paginate === 'previous') {
      newPageNo = pageNo !== 1 ? (pageNo - 1) : 1;
    } else if (paginate === 'next') {
      newPageNo = pageNo < maxPage ? (pageNo + 1) : maxPage;
    } else if (typeof paginate === 'number') {
      newPageNo = paginate;
    }
    this.setState({
      pageNo: newPageNo
    }, () => {
      this.props.fetchTextList({
        'limit': limit,
        'orderBy': 'desc',
        'orderField': 'created_at',
        'pageNo': pageNo,
        'searchValue': ''
      });
    });
  }

  public getPageNumber = () => {
    const { limit, pageNo } = this.state;
    const { totalCount } = this.props;
    let pageNos = [];
    if (totalCount > 0) {
      const maxPage = Math.ceil(totalCount / limit);
      if (maxPage === 1) {
        pageNos = [1];
      } else if (maxPage === 2) {
        pageNos = [1, 2];
      } else if ((pageNo < maxPage) && (pageNo > 1)) {
        pageNos = [pageNo - 1, pageNo, pageNo + 1];
      } else if (maxPage === pageNo) {
        pageNos = [pageNo - 2, pageNo - 1, pageNo];
      }
    }
    return pageNos;
  }

  public toggleDeleteModal = (showDeleteModal: boolean, deleteFileId: string) => {
    this.setState({
      deleteFileId,
      showDeleteModal
    });
  }

  public handleOnDelete = (fileId: string) => {
    if (fileId) {
      const { pageNo, limit } = this.state;
      this.setState({
        showDeleteModal: false
      });
      this.props.deleteText({
        'fileId': fileId,
        'list': {
          'limit': limit,
          'pageNo': pageNo
        }
      });
    }
  }

  public bulkDelete = () => {
    const { deleteIds } = this.state;
    if (deleteIds.length > 0) {
      this.props.bulkDelete(deleteIds);
      this.setState({showDeleteButton: false, deleteIds: []});
    }
  }

  public handleOnSelect = (row: any, isSelect: any) => {
    const array = this.state.deleteIds;
    if (isSelect) {
      this.setState({ showDeleteButton: true, deleteIds: [...this.state.deleteIds, row.id] });
    } else if (!isSelect) {
      const index =  array.map((id: string) => id).indexOf(row.id);
      array.splice(index, 1);
      this.setState({ showDeleteButton: array.length === 0 ? false : true, deleteIds: array });
    }
  }

  public handleOnSelectAll = (isSelect: any, rows: any) => {
    const array: any = [];
    if (isSelect) {
      rows.map((row: any) => {
        array.push(row.id);
      });
      this.setState({ showDeleteButton: true, deleteIds: array });
    } else if (!isSelect) {
      this.setState({ showDeleteButton: false, deleteIds: array });
    }
  }

  public render() {
    const { limit, pageNo, showDeleteModal, deleteFileId, showDeleteButton} = this.state;
    const { deleteIds } = this.state;
    const { textList, totalCount, loading } = this.props;
    const maxPage = Math.ceil(totalCount / limit);
    const pageNos = this.getPageNumber();
    return (
      <React.Fragment>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4><b>TEXT LIBRARY</b></h4>
                    <div className="create-btn">
                      {showDeleteButton ?
                        <Button bsStyle="danger m-0" onClick={() => this.bulkDelete()}>
                          Delete
                        </Button> :
                        <Button bsStyle="success" onClick={() => this.createJson()}>
                          <i className="glyphicon glyphicon-plus add-icon p-r-5"/> Add Json
                        </Button>
                      }
                    </div>
                  </div>
                  { loading ?
                    <LoaderComponent
                      loading={loading}
                      imageSrc={loader}
                      imageStyle={{marginTop: '10%', height: '100px'}}
                    />
                    :
                    <Table
                      keyField="id"
                      data={textList &&  textList.length > 0 ? textList : []}
                      columns={this.columns}
                      loading={loading}
                      length={limit}
                      currentPage={pageNo}
                      total={totalCount}
                      onUpdate={(nextState: ITableState) => {
                        const { page, sizePerPage } = nextState;
                        this.setState({
                          limit: sizePerPage,
                          pageNo: page
                        });
                      }}
                      selectable={true}
                      selectRow={{
                        onSelect: this.handleOnSelect,
                        onSelectAll: this.handleOnSelectAll,
                        selected: deleteIds
                      }}
                    />
                  }
                </div>
                {!loading && totalCount > 0 &&
                  <div className="row">
                    <div className="frames-pagination">
                      <nav aria-label="Page navigation example">
                        <ul className="pagination">
                          <li
                            className={pageNo === 1 ? 'page-item disabled' : 'page-item'}
                            onClick={() => this.handlePagination('previous')}
                          >
                            <a className="page-link" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                              <span className="sr-only">Previous</span>
                            </a>
                          </li>
                          {pageNos && pageNos.map((page: any, index: any) => (
                            <li
                              key={index}
                              onClick={() => this.handlePagination(page)}
                              className={pageNo === page ? 'page-item active' : 'page-item'}
                            >
                              <a className="page-link">{page}</a>
                            </li>
                          ))}
                          <li
                            className={pageNo === maxPage ? 'page-item disabled' : 'page-item'}
                            onClick={() => this.handlePagination('next')}
                          >
                            <a className="page-link" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                              <span className="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                }
              </div>
              <Modal show={showDeleteModal} onHide={() => this.toggleDeleteModal(false, deleteFileId)}>
                <Modal.Header closeButton={true}>
                  <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Are you sure you want to delete this file?
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    bsStyle="danger m-0"
                    onClick={() => this.toggleDeleteModal(false, deleteFileId)}
                  >
                    Close
                  </Button>
                  <Button
                    bsStyle="success"
                    onClick={() => this.handleOnDelete(deleteFileId)}
                  >
                    Confirm
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  loading: state.text.loading,
  textList: state.text.textList,
  totalCount: state.text.totalCount
});

const mapDispatchToProps = ({
  bulkDelete,
  deleteText,
  fetchTextList
});

interface IStateProps {
  textList: any[];
  loading: boolean;
  totalCount: any;
}

interface IDispatchProps {
  bulkDelete: any,
  fetchTextList: any;
  deleteText: any;
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(TextLibrary);
