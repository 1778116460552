import axios from 'axios';
import { updateLoggedInStatus } from '../actions/global';
import { AppProperties } from '../constants/application.properties';
import ERRORS from '../constants/errorConstants';
import ApiError from '../errors/ApiError';
import storage from '../utils/storage';

/**
 * Saves user session in storage and sets as default
 * @param valid: boolean : userSession
 */
export const updateUserSession = (valid: boolean) => {
  storage.setItem(AppProperties.USER_SESSION, valid);
};

export const setAccessToken = (accessToken: string = storage.getItem(AppProperties.ACCESS_TOKEN_KEY)) => {
  storage.setItem(AppProperties.ACCESS_TOKEN_KEY, accessToken);
};

export const getAccessToken = () => {
  return storage.getItem(AppProperties.ACCESS_TOKEN_KEY);
};

export const persistAuthToken = (token: string) => {
  storage.setItem(AppProperties.ACCESS_TOKEN_KEY, token);
  setAccessToken(token);
};

export const removeAuthToken = () => {
  storage.deleteItem(AppProperties.ACCESS_TOKEN_KEY);
  storage.clear();
};

export const setUser = (user: any) => {
  storage.setItem(AppProperties.USER_ID, user.id);
  storage.setItem(AppProperties.APPLICATION_ID, user.userMobileApplications[0].mobile_application_id);
  storage.setItem(AppProperties.INDEX, 0);
};

/**
 * Setup defaults and request response interceptors for axios on load
 * @param store : Redux App Store
 */
export const setupInterceptors = (store: any) => {
  const userSession = storage.getItem(AppProperties.USER_SESSION);
  updateUserSession(userSession);
  setAccessToken();
  store.dispatch(updateLoggedInStatus(userSession !== 'true' ? { loggedIn: false } : { loggedIn: true }));
  axios.defaults.baseURL = AppProperties.BASE_URL;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.validateStatus = () => true;
  axios.interceptors.request.use(
    (config) => {
      const token = storage.getItem(AppProperties.ACCESS_TOKEN_KEY);
      if (token) {
        config.headers.Authorization = token;
        config.headers['x-application-id'] = storage.getItem('application_id');
        config.headers['x-user-id'] = storage.getItem(AppProperties.USER_ID);
      }
      if (!storage.getItem('application_id')) {
        storage.setItem('application_id', 'Prenatal');
      }
      config.headers.application = storage.getItem('application_id');
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use((response: any) => {
    const { status } = response;
    /** Processes response body
     *  use store.dispatch() to dispatch any redux actions
     *  considered for logout status to be 201
     */
    if (status > 205 && status !== 201) {
      switch (status) {
        case 500:
          throw new ApiError(ERRORS.SERVER_ERROR);
        case 403:
          updateUserSession(false);
          store.dispatch(updateLoggedInStatus({ loggedIn: false }));
          throw new ApiError(ERRORS.SERVER_ERROR);
        default:
          throw new ApiError(response.data.error);
      }
    } else {
      return response;
    }
  }, error => {
    Promise.reject(error);
    throw new ApiError(ERRORS.NETWORK_ERROR);
  });
};
