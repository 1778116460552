import Constants from '../actions/logoLibrary/constants';

const initialState = {
  deleteResponse: null,
  duplicateFiles: [],
  imageUpload: null,
  imageUrl: null,
  loading: false,
  logos: [],
  showReplaceModal: false,
  totalCount: 0
};

import { IActionProps } from './index';

export interface IlogoReducer {
  deleteResponse: any;
  duplicateFiles: string[];
  imageUpload: any;
  imageUrl: any;
  loading: boolean;
  totalCount: any;
  logos: any[];
  showReplaceModal: boolean;
}

export const logoReducer = (
  state: IlogoReducer = initialState,
  action: IActionProps
) => {
  switch (action.type) {
    case Constants.DELETE_LOGO:
      return {
        ...state,
        loading: true
      };
    case Constants.DELETE_LOGO_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.DELETE_LOGO_SUCCESS:
      return {
        ...state,
        deleteResponse: action.response,
        loading: false
      };
    case Constants.UPLOAD_LOGO:
      return {
        ...state,
        loading: true
      };
    case Constants.UPLOAD_LOGO_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        duplicateFiles: action.response.duplicateFiles,
        loading: false,
        showReplaceModal: action.response.status
      };
    case Constants.LIST_LOGO:
      return {
        ...state,
        loading: true
      };
    case Constants.LIST_LOGO_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.LIST_LOGO_SUCCESS:
      return {
        ...state,
        imageUrl: action.response.images.s3Url,
        loading: false,
        logos: action.response.images.images,
        totalCount: action.response.images.totalCount
      };
    case Constants.TOGGLE_CONFIRM_MODAL:
      return {
        ...state,
        showReplaceModal: false
      };
    default:
      return state;
  }
};
