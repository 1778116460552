import Constants from './constants';

export const updateLoggedInStatus = (userStatus: any) => {
  return { type: Constants.SET_LOGGED_IN_STATUS, userStatus };
};

export const getMobileApplications = () => ({
  type: Constants.GET_MOBILE_APPLICATIONS
});

export const getMobileApplicationsSuccess = (response: any) => ({
  response,
  type: Constants.GET_MOBILE_APPLICATIONS_SUCCESS
});

export const getMobileApplicationsFail = (error: any) => ({
  error,
  type: Constants.GET_MOBILE_APPLICATIONS_FAIL
});

export const mobileApplicationChange =  (isChanged: any) => ({
  isChanged,
  type: Constants.MOBILE_APPLICATION_CHANGE
});
