
import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import storage from 'src/utils/storage';
import { bulkWorkoutsDelete, deleteWorkout, fetchWorkouts, updateWorkoutStatus } from '../../actions/workouts';
import { fetchWorkoutStages } from '../../actions/workoutStages';
import { mobileApplicationChange } from '../../actions/global';
import loader from '../../assets/img/loader.gif';
import LoaderComponent from '../../components/Loader/index';
import Table, { ITableState } from '../../components/Table';
import Column from '../../components/Table/Column';
import { AppProperties } from '../../constants/application.properties';
import { IState } from '../../reducers';
import './workouts.css';

interface IWorkoutState {
  activeWorkoutStage: any,
  deleteFileId: string,
  openModal: boolean,
  isEdit: boolean,
  schema: any,
  pageNo: any,
  limit: any,
  workoutStageId: string,
  filterType: string,
  searchValue: string,
  title: string,
  active: boolean,
  selectedRow: any,
  showDeleteModal: boolean,
  deleteIds: string[],
  showDeleteButton: boolean
}

interface IWorkoutProps {
  fetchWorkouts: any,
  totalCount: any,
  workouts: any,
  fetchWorkoutStages: any,
  workoutStages: any,
  loading: boolean,
  deleteWorkout: any,
  selectRow: any,
  selectable: boolean,
  updateWorkoutStatus: any,
  bulkWorkoutsDelete: any,
  workoutStageLoading: any,
  isMobileApplicationChanged: any,
  mobileApplicationChange: any
}

class Workouts extends React.PureComponent<IWorkoutProps & RouteComponentProps, IWorkoutState> {

  public static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if ((nextProps.workoutStages && nextProps.workoutStages.length !== 0)) {
      // tslint:disable-next-line
      if (prevState.title == 0) {
        return {
          activeWorkoutStage: nextProps.workoutStages[storage.getItem(AppProperties.INDEX)].id,
          selectedRow: parseInt(storage.getItem(AppProperties.INDEX), 10),
          title: nextProps.workoutStages[storage.getItem(AppProperties.INDEX)].name,
          workoutStageId: nextProps.workoutStages[storage.getItem(AppProperties.INDEX)].id
        };
      }
    }
    return null;
  }

  public state: IWorkoutState;

  public workoutColumns: Column[];

  constructor(props: IWorkoutProps & RouteComponentProps) {
    super(props);
    this.workoutColumns = [
      (new Column()).withKey('name').withLabel('WORKOUTS'),
      (new Column()).withKey('workouts').withLabel('STATUS').withCellFormatter((cell: any, row: any) => (
        <div>
          <p className={AppProperties.STATUS[row.status]}>
          {(AppProperties.STATUS[row.status]).charAt(0).toUpperCase() +
            (AppProperties.STATUS[row.status]).slice(1)}</p>
        </div>
      )),
      (new Column()).withKey('action').withLabel('ACTIONS').withCellFormatter((cell: any, row: any) => (
        <div className="center">
          <i
            className="glyphicon glyphicon-pencil edit-icon"
            onClick={() => this.editWorkout(row.id)}
            title="Edit"
          />
          <i className="glyphicon glyphicon-trash delete-icon" title="Delete" onClick={() => this.toggleDeleteModal(true, row.id)}/>
        </div>
      )),
      (new Column()).withKey('publish').withLabel('UN/PUBLISH').withCellFormatter((cell: any, row: any) => (
        <div>
          {row && row.status === 2 ?
          <Button bsStyle="danger m-0 btn-width"
          onClick={() => this.updateStatus(0, row.id)}
          >Unpublish</Button> :
          <Button bsStyle="success btn-width"
          onClick={() => this.updateStatus(2, row.id)}
          >Publish</Button>}
        </div>
      ))
    ],
    this.state = {
      active: false,
      activeWorkoutStage: null,
      deleteFileId: '',
      deleteIds: [],
      filterType: 'all',
      isEdit: false,
      limit: 10,
      openModal: false,
      pageNo: 1,
      schema: [],
      searchValue: '',
      selectedRow: 0,
      showDeleteButton: false,
      showDeleteModal: false,
      title: '',
      workoutStageId: ''
    };
    this.onRowClick = this.onRowClick.bind(this);
  }

  public componentDidMount() {
    this.props.fetchWorkoutStages();
  }

  public componentDidUpdate() {
    if (this.props.isMobileApplicationChanged) {
      this.setState({
        pageNo: 1
      });
      this.props.mobileApplicationChange(false);
    }
  }

  public updateStatus = (status: number, id: string) => {
    this.setState({
      pageNo: 1
    }, () => {
      this.props.updateWorkoutStatus(id, this.props.workouts, status);
    });
  }

  public createWorkout = () => {
    this.props.history.push(`/workouts/${this.state.activeWorkoutStage}/create`);
  }

  public editWorkout = (id: any) => {
    this.props.history.push(`/workouts/${id}/edit`);
  }

  public fetchWorkoutStages = () => {
    this.props.fetchWorkoutStages();
  }

  public onRowClick = (e: any, index: any) => {
    this.props.fetchWorkouts(1, 10, 'all', '', e.currentTarget.id);
    const workoutStageIndex = this.props.workoutStages.map((workoutStage: any) => workoutStage.id).indexOf(e.currentTarget.id);
    storage.setItem(AppProperties.INDEX, workoutStageIndex);
    this.setState({
      activeWorkoutStage: e.currentTarget.id,
      pageNo: 1,
      searchValue: '',
      selectedRow: index,
      title: e.currentTarget.title,
      workoutStageId: e.currentTarget.id
    });
  }

  public fetchWorkouts = () => {
    const { pageNo, limit, filterType, searchValue, workoutStageId } = this.state;
    this.props.fetchWorkouts(pageNo, limit, filterType, searchValue, workoutStageId);
  }

  public onDelete = (id: string) => {
    this.setState({
      showDeleteModal: false
    });
    const { workouts, workoutStages, totalCount } = this.props;
    this.props.deleteWorkout(id, workouts, workoutStages, totalCount);
  }

  public getPageNumber = () => {
    const { limit, pageNo } = this.state;
    const { totalCount } = this.props;
    let pageNos = [1, 2, 3];
    if (totalCount > 0) {
      const maxPage = Math.ceil(totalCount / limit);
      if (maxPage === 1) {
        pageNos = [1];
      } else if (maxPage === 2) {
        pageNos = [1, 2];
      } else if ((pageNo < maxPage) && (pageNo > 1)) {
        pageNos = [pageNo - 1, pageNo, pageNo + 1];
      } else if (maxPage === pageNo) {
        pageNos = [pageNo - 2, pageNo - 1, pageNo];
      }
    }
    return pageNos;
  }

  public handlePagination = (paginate: any) => {
    const { pageNo, limit } = this.state;
    const { totalCount } = this.props;
    const maxPage = Math.ceil(totalCount / limit);
    let newPageNo = 1;
    if (paginate === 'previous') {
      newPageNo = pageNo !== 1 ? (pageNo - 1) : 1;
    } else if (paginate === 'next') {
      newPageNo = pageNo < maxPage ? (pageNo + 1) : maxPage;
    } else if (typeof paginate === 'number') {
      newPageNo = paginate;
    }
    this.setState({
      pageNo: newPageNo
    }, () => {
      this.fetchWorkouts();
    });
  }

  public toggleDeleteModal = (showDeleteModal: boolean, deleteFileId: string) => {
    this.setState({
      deleteFileId,
      showDeleteModal
    });
  }

  public handleOnSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchStr = event.target.value;
    this.setState({
      pageNo: 1,
      searchValue: searchStr
    }, () => {
      if (searchStr.length > 2 || searchStr.length === 0) {
        const { pageNo, limit, filterType, searchValue, activeWorkoutStage } = this.state;
        this.props.fetchWorkouts(pageNo, limit, filterType, searchValue, activeWorkoutStage);
      }
    });
  }

  public bulkDelete = () => {
    const { deleteIds, activeWorkoutStage } = this.state;
    if (deleteIds.length > 0) {
      this.props.bulkWorkoutsDelete({deleteIds, activeWorkoutStage});
      this.setState({showDeleteButton: false});
    }
  }

  public handleOnSelect = (row: any, isSelect: any) => {
    const array = this.state.deleteIds;
    if (isSelect) {
      this.setState({ showDeleteButton: true, deleteIds: [...this.state.deleteIds, row.id] });
    } else if (!isSelect) {
      const index =  array.map((id: string) => id).indexOf(row.id);
      array.splice(index, 1);
      this.setState({ showDeleteButton: array.length === 0 ? false : true, deleteIds: array });
    }
  }

  public handleOnSelectAll = (isSelect: any, rows: any) => {
    const array: any = [];
    if (isSelect) {
      rows.map((row: any) => {
        array.push(row.id);
      });
      this.setState({ showDeleteButton: true, deleteIds: array });
    } else if (!isSelect) {
      this.setState({ showDeleteButton: false, deleteIds: array });
    }
  }

  public render() {
    const { limit, pageNo, title, selectedRow, showDeleteModal, deleteFileId, searchValue, showDeleteButton } = this.state;
    const { workouts, workoutStages, totalCount, loading, workoutStageLoading } = this.props;
    const maxPage = Math.ceil(totalCount / limit);
    const pageNos = this.getPageNumber();
    return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <div className="panel panel-default stages-table">
                <div className="panel-heading">
                  <h4 className="font-medium"><b>WORKOUTS</b></h4>
                </div>
                <div className="col-md-12 col-sm-12 workout-table remv-padd">
                  <div className="col-xs-12 remv-padd table-content">
                    <div className="col-xs-12 table-header remv-padd">
                      <div className="col-xs-6 remv-padd">
                        <h5>STAGE NAME</h5>
                      </div>
                      <div className="col-xs-6 remv-padd">
                        <h5 className="text-center remv-padd">NO. OF WORKOUTS</h5>
                      </div>
                    </div>
                    { workoutStageLoading ?
                      <LoaderComponent
                        loading={workoutStageLoading}
                        imageSrc={loader}
                        imageStyle={{marginTop: '10%', height: '100px'}}
                      />
                      :
                      workoutStages && workoutStages.length > 0 &&
                      workoutStages.map((data: any, stageIndex: any) => (
                        <div
                          className={`col-xs-12 table-row remv-padd ${selectedRow === stageIndex ? 'active' : '' }`}
                          title={data.name}
                          id={data.id}
                          onClick={(e) => this.onRowClick(e, stageIndex)}
                          key={data.id}
                        >
                          <div className="col-xs-6 remv-padd">
                            <p title={data.name} id={data.id}>{data.name}</p>
                          </div>
                          <div className="col-xs-6 remv-padd text-center">
                            <p className="cnt-value" id={data.id}>{data.workout.length}</p>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="panel panel-default workout-stages-table">
                <div className="panel-heading frames-heading">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <h4><b>{title} workouts</b></h4>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                      <div className="upload-btn pull-right">
                        {showDeleteButton ?
                          <Button bsStyle="danger m-0" onClick={() => this.bulkDelete()}>
                            Delete
                          </Button> :
                          <Button bsStyle="success" onClick={() => this.createWorkout()}>
                            <i className="glyphicon glyphicon-plus add-icon p-r-5" />
                            Add workout
                          </Button>
                        }
                      </div>
                      <div className="search-box pull-right">
                        <span className="icon-search">
                          <i className="glyphicon glyphicon-search" />
                        </span>
                        <input
                          placeholder="Search workout"
                          type="text"
                          value={searchValue}
                          onChange={(e) => this.handleOnSearch(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                { workoutStageLoading || loading ?
                <LoaderComponent
                  loading={workoutStageLoading || loading}
                  imageSrc={loader}
                  imageStyle={{marginTop: '10%', height: '100px'}}
                />
                :
                <Table
                  keyField="id"
                  data={workouts &&  workouts.length > 0 ? workouts : []}
                  columns={this.workoutColumns}
                  loading={loading}
                  length={limit}
                  currentPage={pageNo}
                  total={totalCount}
                  onUpdate={(nextState: ITableState) => {
                    const { page, sizePerPage } = nextState;
                    this.setState({
                      limit: sizePerPage,
                      pageNo: page
                    }, this.fetchWorkouts);
                  }}
                  // TODO: If multi-delete needed enable this.
                  // selectable={true}
                  // selectRow={{
                  //   onSelect: this.handleOnSelect,
                  //   onSelectAll: this.handleOnSelectAll,
                  //   selected: deleteIds
                  // }}
                />
                }
                { !(workoutStageLoading || loading) && totalCount > 0 &&
                    <div className="row">
                      <div className="frames-pagination">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination">
                            <li
                              className={pageNo === 1 ? 'page-item disabled' : 'page-item'}
                              onClick={() => this.handlePagination('previous')}
                            >
                              <a className="page-link" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                              </a>
                            </li>
                            {pageNos && pageNos.map((page: any, index: any) => (
                              <li
                                key={index}
                                onClick={() => this.handlePagination(page)}
                                className={pageNo === page ? 'page-item active' : 'page-item'}
                              >
                                <a className="page-link">{page}</a>
                              </li>
                            ))}
                            <li
                              className={pageNo === maxPage ? 'page-item disabled' : 'page-item'}
                              onClick={() => this.handlePagination('next')}
                            >
                              <a className="page-link" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  }
              </div>
            </div>
          </div>
          <Modal show={showDeleteModal} onHide={() => this.toggleDeleteModal(false, deleteFileId)}>
            <Modal.Header closeButton={true}>
              <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Are you sure you want to delete this workout?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                bsStyle="danger m-0"
                onClick={() => this.toggleDeleteModal(false, deleteFileId)}
              >
                Close
              </Button>
              <Button
                bsStyle="success"
                onClick={() => this.onDelete(deleteFileId)}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  loading: state.workout.loading,
  totalCount: state.workout.totalCount,
  workoutStageLoading: state.workoutStages.loading,
  workoutStages: state.workoutStages.workoutStages,
  workouts: state.workout.workouts,
  isMobileApplicationChanged: state.global.applications.isMobileApplicationChanged
});

const mapDispatchToProps = ({
  bulkWorkoutsDelete,
  deleteWorkout,
  fetchWorkoutStages,
  fetchWorkouts,
  updateWorkoutStatus,
  mobileApplicationChange
});

interface IStateProps {
  workouts: any[];
  loading: boolean;
  totalCount: any;
  workoutStages: any;
}

interface IDispatchProps {
  fetchWorkouts: any;
  fetchWorkoutStages: any;
  deleteWorkout: any;
  updateWorkoutStatus: any;
  bulkWorkoutsDelete: any;
  mobileApplicationChange: any;
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLocalize
)(Workouts);
