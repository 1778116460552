import Constants from './constants';

export const deleteLogo = (data: any) => ({
  data,
  type: Constants.DELETE_LOGO
});

export const deleteLogoSuccess = (response: any) => ({
  response,
  type: Constants.DELETE_LOGO_SUCCESS
});

export const deleteLogoFail = (error: any) => ({
  error,
  type: Constants.DELETE_LOGO_FAIL
});

export const uploadLogo = (data: any) => ({
  data,
  type: Constants.UPLOAD_LOGO
});

export const uploadLogoSuccess = (response: any) => ({
  response,
  type: Constants.UPLOAD_LOGO_SUCCESS
});

export const uploadLogoFail = (error: any) => ({
  error,
  type: Constants.UPLOAD_LOGO_FAIL
});

export const listLogo = (data: any) => ({
  data,
  type: Constants.LIST_LOGO
});

export const listLogoSuccess = (response: any) => ({
  response,
  type: Constants.LIST_LOGO_SUCCESS
});

export const listLogoFail = (error: any) => ({
  error,
  type: Constants.LIST_LOGO_FAIL
});

export const toggleConfirmModal = (status: boolean) => ({
  status,
  type: Constants.TOGGLE_CONFIRM_MODAL
});

export const bulkLogoLibraryDelete = (data: any) => ({
  data,
  type: Constants.BULK_LOGO_LIBRARY_DELETE
});

export const bulkLogoLibraryDeleteSuccess = (response: any) => ({
  response,
  type: Constants.BULK_LOGO_LIBRARY_DELETE_SUCCESS
});

export const bulkLogoLibraryDeleteFail = (error: any) => ({
  error,
  type: Constants.BULK_LOGO_LIBRARY_DELETE_FAIL
});
