import * as React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { clearUser, getUserDetail } from '../../actions/user';
import logo from '../../assets/img/boy.svg';
import loader from '../../assets/img/loader.gif';
import LoaderComponent from '../../components/Loader/index';
import { AppProperties } from '../../constants/application.properties';
import { IState } from '../../reducers';
import storage from '../../utils/storage';
import './myAccount.css';

interface IMyAccountState {
    proPic: any;
}

interface IMyAccountProps {
    loading: boolean;
    getUserDetail: any;
    user: any;
    clearUser: any;
}

class MyAccount extends React.PureComponent<IMyAccountProps & RouteComponentProps, IMyAccountState> {

  public userId = storage.getItem(AppProperties.USER_ID);

  constructor(props: IMyAccountProps & RouteComponentProps) {
    super(props);
    this.state = {
        proPic: ''
    };
  }

  public componentDidMount() {
    this.props.getUserDetail(this.userId);
  }

  public editProfile = (id: string) => {
    this.props.history.push(`/myAccount/${id}/editProfile`);
  }

  public componentWillUnmount() {
      this.props.clearUser();
  }

  public render() {
    const { user, loading } = this.props;
    // const { proPic } = this.state;

    return (
        <div className="container-fluid">
        { user &&
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="panel panel-default my-acc-hdr">
                        <div className="panel-heading workout-header-sec">
                            <h4><b>MY ACCOUNT</b></h4>
                            <div className="workout-btn-actions text-right remv-padd">
                                <button
                                    onClick={() => this.editProfile(this.userId)}
                                    className="btn btn-success"
                                >
                                    Edit Profile
                                </button>
                            </div>
                        </div>
                        { loading ?
                            <LoaderComponent
                            loading={loading}
                            imageSrc={loader}
                            imageStyle={{marginTop: '10%', height: '100px'}}
                            />
                            :
                            <React.Fragment>
                                <div className="panel-body">
                                <div className="col-md-3 col-sm-4 col-xs-12 change-pic-sec">
                                    <img src={user.profile_image ? user.profile_image : logo} className="profile-pic" />
                                </div>
                                <div className="col-md-9 col-sm-8 col-xs-12 prof-desc-sec">
                                    <div className="col-md-6 prof-cnt">
                                        <h5>First Name:</h5>
                                        <p>{ user.first_name }</p>
                                    </div>
                                    <div className="col-md-6 prof-cnt">
                                        <h5>Last Name:</h5>
                                        <p>{ user.last_name }</p>
                                    </div>
                                    <div className="col-md-6 prof-cnt">
                                        <h5>Email:</h5>
                                        <p>{ user.email }</p>
                                    </div>
                                    <div className="col-md-6 prof-cnt">
                                        <h5>Phone:</h5>
                                        <p>{ user.mobile_no }</p>
                                    </div>
                                    <div className="col-md-6 prof-cnt">
                                        <h5>Address:</h5>
                                        <p>{ user.address ? user.address : 'NA' }</p>
                                    </div>
                                </div>
                              </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        }
        </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  loading: state.user.loading,
  user: state.user.user
});

const mapDispatchToProps = ({
    clearUser,
    getUserDetail
});

interface IStateProps {
  loading: boolean;
  user: any
}
interface IDispatchProps {
    getUserDetail: any;
    clearUser: any;
}

export default compose(
  withRouter,
  connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLocalize
)(MyAccount);
