import axios from 'axios';

export const view = (pageNo: any, limit: any, filterType: string, searchValue: string, workoutStageId: string) =>
  axios({
    data: {
      filterType,
      limit,
      pageNo,
      searchValue,
      workoutStageId
    },
    method: 'post',
    url: '/api/Workouts/list'
  });

export const create = (name: string, sequenceNo: any, status: any, sequenceOrder: string, jsonContent: object, workoutStageId: string, workoutFor: string) =>
  axios({
    data: {
      name,
      workoutStageId, // tslint:disable-next-line
      sequenceOrder,
      sequenceNo,
      status,
      workoutFor,
      jsonContent
    },
    method: 'post',
    url: '/api/Workouts'
  });

  export const remove = (id: string) =>
  axios({
    method: 'delete',
    url: `/api/Workouts/${id}`
  });

  export const get = (id: string) =>
  axios({
    method: 'get',
    url: `/api/Workouts/${id}`
  });

  export const edit = (id: string, name: string, sequenceNo: any, sequenceOrder: any, status: any, jsonContent: object, workoutStageId: string, workoutFor: string) =>
  axios({
    data: {
      id,
      jsonContent,
      name,
      sequenceNo,
      sequenceOrder,
      status,
      workoutStageId,
      workoutFor
    },
    method: 'post',
    url: '/api/Workouts/edit'
  });

  export const update = (id: string, status: number, workoutStageId: string) =>
  axios({
    data: {
      id,
      status,
      workoutStageId
    },
    method: 'post',
    url: '/api/Workouts/edit'
  });

  export const bulkDelete = (data: any) =>
  axios({
    data: data.deleteIds,
    method: 'delete',
    url: '/api/Workouts/bulk'
  });

  export const search = (pageNo: any, limit: any, searchValue: string) =>
  axios({
    data: {
      limit,
      pageNo,
      searchValue
    },
    method: 'post',
    url: '/api/Workouts/search'
  });
