import keyMirror from 'key-mirror';
export default keyMirror({
  BULK_SOUND_LIBRARY_DELETE: null,
  BULK_SOUND_LIBRARY_DELETE_FAIL: null,
  BULK_SOUND_LIBRARY_DELETE_SUCCESS: null,
  DELETE_SOUND: null,
  DELETE_SOUND_FAIL: null,
  DELETE_SOUND_SUCCESS: null,
  LIST_SOUND: null,
  LIST_SOUND_FAIL: null,
  LIST_SOUND_SUCCESS: null,
  TOGGLE_CONFIRM_MODAL: null,
  UPLOAD_SOUND: null,
  UPLOAD_SOUND_FAIL: null,
  UPLOAD_SOUND_SUCCESS: null
});
