import { RouterState } from 'connected-react-router';
import { localizeReducer } from 'react-localize-redux';
import { combineReducers } from 'redux';
import { globalReducer, IGlobalReducer } from './global';
import { IlogoReducer, logoReducer } from './logoLibrary';
import { IlogoStagesReducer, logoStagesReducer } from './logoStages';
import { IsoundReducer, soundReducer } from './soundLibrary';
import { ItextReducer, textReducer } from './textLibrary';
import { IthemeReducer, themeReducer } from './theme';
import { IuserReducer, userReducer } from './user';
import { IworkoutFrameReducer, workoutFrameReducer } from './workoutFrames';
import { IworkoutReducer, workoutReducer } from './workouts';
import { IworkoutStageReducer, workoutStageReducer } from './workoutStages';

const rootReducer = combineReducers({
  global: globalReducer,
  localize: localizeReducer,
  logo: logoReducer,
  logoStages: logoStagesReducer,
  sound: soundReducer,
  text: textReducer,
  theme: themeReducer,
  user: userReducer,
  workout: workoutReducer,
  workoutFrames: workoutFrameReducer,
  workoutStages: workoutStageReducer
});

export interface IActionProps {
  type: string;
  [key: string]: any;
}

export interface IState {
  languageSelection: any;
  router: RouterState;
  theme: IthemeReducer;
  user: IuserReducer;
  global: IGlobalReducer;
  workout: IworkoutReducer;
  workoutStages: IworkoutStageReducer;
  workoutFrames: IworkoutFrameReducer;
  logo: IlogoReducer;
  sound: IsoundReducer;
  text: ItextReducer;
  logoStages: IlogoStagesReducer;
}

export default rootReducer;
