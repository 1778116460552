import Constants from '../actions/textLibrary/constants';

const initialState = {
  deleteResponse: null,
  imageUpload: null,
  loading: false,
  s3Url: null,
  showReplaceModal: false,
  textList: [],
  textObj: null,
  totalCount: 0
};

import { IActionProps } from './index';

export interface ItextReducer {
  deleteResponse: any;
  imageUpload: any;
  s3Url: any;
  loading: boolean;
  totalCount: any;
  textList: any[];
  textObj: any;
  showReplaceModal: boolean
}

export const textReducer = (
  state: ItextReducer = initialState,
  action: IActionProps
) => {
  switch (action.type) {
    case Constants.DELETE_TEXT:
      return {
        ...state,
        loading: true
      };
    case Constants.DELETE_TEXT_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.DELETE_TEXT_SUCCESS:
      return {
        ...state,
        deleteResponse: action.response,
        loading: false
      };
    case Constants.UPLOAD_TEXT:
      return {
        ...state,
        loading: true
      };
    case Constants.UPLOAD_TEXT_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.UPLOAD_TEXT_SUCCESS:
      return {
        ...state,
        loading: false,
        showReplaceModal: action.response.status
      };
    case Constants.FETCH_TEXT:
      return {
        ...state,
        loading: true
      };
    case Constants.FETCH_TEXT_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.FETCH_TEXT_SUCCESS:
      return {
        ...state,
        loading: false,
        s3Url: action.response.text_libraries.s3Url,
        textList: action.response.text_libraries.json,
        totalCount: action.response.text_libraries.totalCount
      };
    case Constants.GET_TEXT:
      return {
        ...state,
        loading: true
      };
    case Constants.GET_TEXT_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.GET_TEXT_SUCCESS:
      return {
        ...state,
        loading: false,
        textObj: action.response
      };
    case Constants.CLEAR_TEXT:
      return {
        ...state,
        loading: true,
        textObj: {}
      };
    case Constants.BULK_DELETE:
      return {
        ...state,
        loading: true
      };
    case Constants.BULK_DELETE_FAIL:
      return {
        ...state,
        loading: false
      };
    case Constants.BULK_DELETE_SUCCESS:
      return {
        ...state,
        deleteResponse: action.response,
        loading: false
      };
    default:
      return state;
  }
};
